import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  DeleteButton,
  RefreshButton,
  ListButton,
  required,
} from 'react-admin';

import { ActionsTypes } from '../../tools/types';

const redirect = (_basePath, _id, data) => `/shops/${data.shopId}/show/vouchers`;

function BlacklistEdit(props) {
  return (
<Edit {...props} actions={<BlacklistEditActions />}>
    <SimpleForm variant="standard" redirect={redirect}>
      <TextInput disabled source="id" label="Identifiant" />
      <TextInput source="comment" label="Comment" validate={required()} />
      <TextInput source="idVoucher" label="ID Voucher" />
      <TextInput source="codeVoucher" label="Code Voucher" />
      <TextInput disabled source="shopId" label="Boutique associée" />
    </SimpleForm>
</Edit>
  );
}

function BlacklistEditActions({ data, resource }) {
  if (!data) return null;

  const listPath = `/shops/${data.shopId}/show/blacklists`;

  return (
    <TopToolbar>
      <ListButton to={listPath} />
      <DeleteButton resource={resource} record={data} basePath={listPath} />
      <RefreshButton />
    </TopToolbar>
  );
}

BlacklistEditActions.propTypes = ActionsTypes.Edit;

export default BlacklistEdit;
