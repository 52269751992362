import React, { useState } from 'react';
import {
  List,
  Datagrid,
  DateField,
  TopToolbar,
  ExportButton,
  TextField,
  FunctionField,
  ShowButton,
} from 'react-admin';
import BillListIcon from '@material-ui/icons/Receipt';
import {
  Button, Dialog, DialogContent, DialogTitle,
} from '@material-ui/core';

import { OtherTypes } from '../../tools/types';
import { IdField, StatusIcon } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import ImportBillForm from './ImportBillForm';

import BillFilter from './BillFilter';

function BillList(props) {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const showImportBillModal = () => {
    setIsImportModalOpen(true);
  };

  const closeImportBillModal = () => {
    setIsImportModalOpen(false);
  };

  return (
    <>
      <List {...props} actions={<BillActions showImportBillModal={showImportBillModal} />} perPage={20} pagination={<SimplePagination />} filters={<BillFilter />}>
        <Datagrid>
          <IdField label="id" sortable={false} />
          <TextField source="reference" sortable={false} label="Référence" />

          <TextField source="network.name" sortable={false} label="Network" />

          <FunctionField
            label="Montant"
            render={(record) => (
              <TextField
                source="value"
                record={{
                  ...record,
                  value: `${record.value.toFixed(2)} ${record.currency}`,
                }}
              />
            )}
          />

          <DateField source="created" locales="fr-FR" label="Crée le" sortable={false} />

          <StatusIcon label="" source="paid" sortable={false} />
          <DateField source="paymentDate" locales="fr-FR" label="Payé le" sortable={false} />
          <ShowButton />
        </Datagrid>
      </List>
      <Dialog
        fullWidth
        open={isImportModalOpen}
        onClose={closeImportBillModal}
        aria-label="Importer une facture"
      >
        <DialogTitle>
          Importer une facture
        </DialogTitle>
        <DialogContent>
          <ImportBillForm closeParentModal={closeImportBillModal} />
        </DialogContent>
      </Dialog>
    </>
  );
}

function BillActions({
  basePath, resource, exporter, filterValues, currentSort, showImportBillModal,
}) {
  return (
<TopToolbar>
    <Button onClick={showImportBillModal} color="secondary">Importer une facture</Button>
    <Button href={`#${basePath}/reconciliate`} color="secondary">
      Reconciliation
    </Button>
    <ExportButton resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} />
</TopToolbar>
  );
}

BillActions.propTypes = OtherTypes.ExportButton;

export { BillList, BillListIcon };
