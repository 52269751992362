import React from 'react';
import Typography from '@material-ui/core/Typography';

import { PropTypes, OtherTypes } from '../../../tools/types';

function SectionTitle({ label }) {
  return <Typography className="section-title" style={{ marginTop: '35px' }} variant="subtitle1">{label}</Typography>;
}

SectionTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function LayoutTitle({ record, source }) {
  return <span>{record[source]}</span>;
}

LayoutTitle.propTypes = { ...OtherTypes.TitleForRecord, source: PropTypes.string };

export { SectionTitle, LayoutTitle };
