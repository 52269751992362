import React, { useState, useEffect } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useNotify,
  useRedirect,
} from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import config from '../../config';
import requester from '../../tools/requester';
import { PropTypes } from '../../tools/types';

const shopOptionRenderer = (a) => a && `${a.domain.replace('*.', '')} (${a.url}) - ${a.country.iso2a}`;
const shopInputFunction = (input, suggestion, getOptionText) => {
  if (input.trim().length > 2) {
    return input.toLowerCase().trim() === getOptionText(suggestion).toLowerCase().trim();
  }
  return false;
};

function DuplicateFormDialog(props) {
  const [shopId, setShopId] = useState(null);
  const notify = useNotify();
  const redirect = useRedirect();

  // reset value on dialog closing
  useEffect(() => {
    setShopId(null);
  }, [props.isDialogOpened]);

  const onSubmit = async () => {
    if (!shopId) return;

    try {
      const res = await requester(`${config.services.backend.endpoint}/programs/duplicate`, {
        method: 'POST',
        options: {
          data: {
            programId: props.programId,
            shopId,
          },
        },
      });

      props.onDialogClosed();
      notify('Programme dupliqué', 'success');
      redirect(`/programs/${res.data.id}/show`);
    } catch (error) {
      notify(`${error.message}`, 'error');
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.isDialogOpened}
      onClose={props.onDialogClosed}
      aria-label="Dupliquer le programme"
    >
      <DialogTitle>Dupliquer le programme</DialogTitle>
      <DialogContent>
        <SimpleForm toolbar={false}>
          <ReferenceInput
            label="Boutique"
            perPage={10}
            source="shop.id"
            reference="shops"
            fullWidth
            options={{ fullWidth: true }}
            onChange={(id) => setShopId(id)}
          >
            <AutocompleteInput
              optionText={shopOptionRenderer}
              inputValueMatcher={shopInputFunction}
              helperText="Choisir la boutique du nouveau programme"
            />
          </ReferenceInput>
        </SimpleForm>
        <Box display="flex" justifyContent="flex-end" py={1}>
          <Button color="error" type="button" onClick={props.onDialogClosed}>Annuler</Button>
          <Button color="primary" type="submit" disabled={!shopId} onClick={onSubmit}>Dupliquer</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

DuplicateFormDialog.propTypes = {
  programId: PropTypes.string.isRequired,
  isDialogOpened: PropTypes.bool.isRequired,
  onDialogClosed: PropTypes.func.isRequired,
};
export default DuplicateFormDialog;
