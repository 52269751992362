import React, { useState, useMemo } from 'react';

import {
  AutocompleteInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import moment from 'moment';
import { PropTypes } from '../../tools/types';
import config from '../../config';
import requester from '../../tools/requester';

function CreateInquiryDialog(props) {
  const url = config.services.backend.endpoint;
  const notify = useNotify();
  const redirect = useRedirect();

  const [model, setModel] = useState({});
  const [shopsWithClicks, setShopsWithClicks] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clicks = useMemo(() => {
    if (!model?.shop || !shopsWithClicks) return null;
    return shopsWithClicks.find((s) => s.id === model.shop).clicks.map((c) => ({
      id: c.id,
      name: moment(c.date).format('LLL'),
      date: c.date,
    }));
  }, [model]);

  const isSubmitButtonDisabled = false;

  const updateShopsWithClick = async (userId) => {
    if (!userId) {
      setShopsWithClicks(null);
      return;
    }

    try {
      const { data } = await requester(`${url}/inquiry/shops`, {
        method: 'get',
        options: {
          params: {
            userId,
          },
        },
      });

      if (data.length === 0) {
        setShopsWithClicks(null);
        return;
      }

      setShopsWithClicks(data.map((shop) => ({
        id: shop.id,
        name: `${shop.name} (${shop.domain})`,
        clicks: shop.clicks,
      })));
    } catch (error) {
      setShopsWithClicks(null);
    }
  };

  const updateModel = (key, value) => {
    if (key === 'userId') {
      return setModel({ userId: value });
    }

    if (key === 'shop') {
      return setModel({
        userId: model.userId,
        shop: value,
      });
    }

    return setModel({ ...model, [key]: value });
  };

  const onSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const response = await requester(`${url}/inquiries`, {
        method: 'POST',
        options: {
          data: {
            idClick: model.idClick,
            idOrder: model.idOrder,
            orderValue: model.orderValue,
            dateOrder: clicks.find((c) => c.id === model.idClick).date,
          },
        },
      });

      notify('Réclamation créée', { type: 'success' });
      props.onDialogClosed();
      redirect(`/inquiries/${response.data.id}/show`);
    } catch (error) {
      notify(`${error.message}`, { type: 'error' });
    }

    setIsSubmitting(false);
  };

  return (
    <Dialog
      fullWidth
      open={props.isDialogOpened}
      onClose={props.onDialogClosed}
      aria-label="Créer une réclamation"
    >
      <DialogTitle>
        Créer une réclamation
      </DialogTitle>
      <DialogContent>
        <SimpleForm toolbar={false}>
          <TextInput
            label="User ID"
            source="userId"
            fullWidth
            onChange={(e) => updateModel('userId', e.target.value)}
            // onBlur to only call the updateShopsWithClick once
            onBlur={(e) => updateShopsWithClick(e.target.value)}
          />
          <AutocompleteInput
            label="Shop"
            source="shop"
            disabled={!shopsWithClicks}
            choices={shopsWithClicks || []}
            fullWidth
            onChange={(e) => updateModel('shop', e)}
          />
          <SelectInput
            label="Date de commande"
            source="idClick"
            disabled={!clicks}
            choices={clicks || []}
            fullWidth
            helperText="Choisir la date qui se rapproche le plus de la date de commande fournit par l'utilisateur"
            onChange={(e) => updateModel('idClick', e.target.value)}
          />
          <TextInput
            label="Numéro de commande"
            source="idOrder"
            fullWidth
            onChange={(e) => updateModel('idOrder', e.target.value)}
          />
          <NumberInput
            label="Montant de la commande"
            source="orderValue"
            locales="fr-FR"
            fullWidth
            onChange={(e) => updateModel('orderValue', e.target.value)}
          />
        </SimpleForm>
        <Box display="flex" justifyContent="flex-end" py={1}>
          <Button color="default" type="button" onClick={props.onDialogClosed}>
            Annuler
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmit}
          >
            Créer la réclamation
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

CreateInquiryDialog.propTypes = {
  isDialogOpened: PropTypes.bool.isRequired,
  onDialogClosed: PropTypes.func.isRequired,
};

export default CreateInquiryDialog;
