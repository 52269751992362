import React, { PureComponent } from 'react';

import {
  Filter,
  SelectInput,
  TextInput,
  DateInput,
} from 'react-admin';
// Utils
import moment from 'moment';
import requester from '../../tools/requester';
import config from '../../config';

export default class TransactionListFilters extends PureComponent {
  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
    };
  }

  /**
   * Component mount hook
   */
  componentDidMount() {
    this.fetchCountries();
  }

  fetchCountries = async () => requester(`${config.services.backend.endpoint}/countries`, {}).then((response) => {
    const formattedData = response.data.map((r) => ({ id: r.id, name: r.iso2a }));
    this.setState({ countries: formattedData, error: null });
  }).catch((err) => {
    this.setState({
      error: err.response && err.response.status === 500 ? err.response.data.toString() : err.message,
    });
  });

  render = () => (
    <Filter variant="standard" {...this.props}>
      <TextInput label="Shop" source="shop" alwaysOn />
      <SelectInput
        source="country"
        choices={this.state.countries}
        alwaysOn
      />
      <DateInput
        isRequired={false}
        parse={(v) => (v ? (moment(v)).toISOString() : v)}
        source="dateStart"
        label="Du"
        alwaysOn
      />
      <DateInput
        isRequired={false}
        parse={(v) => (v ? moment(v).endOf('day').toISOString() : v)}
        source="dateEnd"
        label="Au"
        alwaysOn
      />
    </Filter>
  );
}
