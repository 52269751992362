import * as Papa from 'papaparse';

const onlyKeepLastUpdatedTransactions = (acc, transaction) => {
  if (acc[transaction['EPN Transaction ID']]) {
    const currentTransaction = acc[transaction['EPN Transaction ID']];
    if (currentTransaction['Update Date'] < transaction['Update Date']) {
      acc[transaction['EPN Transaction ID']] = transaction;
    }

    return acc;
  }
  acc[transaction['EPN Transaction ID']] = transaction;

  return acc;
};

export const mergeEbayFiles = (billTransactions, periodTransactions) => {
  const billTransactionsDatesAndValues = billTransactions.map(({ ActionDate, Earnings, ApprovalStatus }) => ({
    date: ActionDate,
    value: Earnings,
    ApprovalStatus,
  })).filter(({ date }) => !!date).filter(({ ApprovalStatus }) => ApprovalStatus === 'Approved');

  const approvedTransactions = periodTransactions.filter(({ Status }) => Status === 'Approved');
  const lastUpdatedTransactions = Object.values(approvedTransactions.reduce(onlyKeepLastUpdatedTransactions, {}));

  const transactionIdsByDateAndValues = lastUpdatedTransactions.reduce((acc, { 'Event Date': date, Earnings: value, 'EPN Transaction ID': id }) => {
    if (!date) {
      return acc;
    }
    const key = `${date}__${value}`;
    if (!acc.has(key)) {
      acc.set(key, []);
    }
    acc.get(key).push(id);

    return acc;
  }, new Map());

  return billTransactionsDatesAndValues.map(({ date, value }) => {
    const key = `${date}__${value}`;
    const networkTransactionIds = transactionIdsByDateAndValues.get(key);
    const networkTransactionId = networkTransactionIds?.shift();

    return {
      date,
      value,
      networkTransactionId: networkTransactionId ?? 'not found',
    };
  }).filter(({ networkTransactionId, value }) => networkTransactionId !== 'not found' || parseFloat(value) !== 0);
};

const parseCsv = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    resolve(Papa.parse(data, { header: true }).data);
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsText(file[0]);
});
export const transformEbay = async ({ billFile, transactionsFile }) => {
  const mergedEbayFile = mergeEbayFiles(await parseCsv(billFile), await parseCsv(transactionsFile));

  return new Blob([Papa.unparse(mergedEbayFile)], { type: 'text/csv' });
};
