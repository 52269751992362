import React, {
  useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';
import {
  CardContent,
  Typography,
  Tooltip,
} from '@material-ui/core';

import './Calendar.css';
import {
  Filter,
  SelectInput,
} from 'react-admin';
import { BannerModal } from './BannerModal';
import { PropTypes } from '../../../tools/types';
import {
  bannerContextActions, BannerContext, useBannerContext,
} from './BannerContext';
import { bannerTypeIsAlreadyUsed } from './validate';
import { BANNER_TYPES } from './bannerTypes';
import { COUNTRIES, COUNTRY_CODES } from './countries';
import { DayNames } from './DayNames';
import { getMonthYears } from './getMonthYears';
import { CardContainer } from './CardContainer';
import { getCalendarValues } from './getCalendarValues';

function DayCardComponent({ dayNumber, banners, reservedDaysByBannerType }) {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [availableShops, setAvailableShops] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const { fetchShops, bannerPermissions, countryFilter } = useBannerContext();

  const openCreateBannerModal = async () => {
    const shops = await fetchShops({ countryIso2a: countryFilter });
    setAvailableShops(shops.data);
    setCreateModalIsOpen(true);
  };
  const createBanner = async () => {
    setSelectedBanner(null);
    await openCreateBannerModal();
  };
  const openUpdateBannerModal = () => {
    setUpdateModalIsOpen(true);
  };
  const editBanner = (banner) => () => {
    setSelectedBanner(banner);
    openUpdateBannerModal();
  };

  const closeCreateBannerModal = () => setCreateModalIsOpen(false);

  const closeUpdateBannerModal = () => {
    setUpdateModalIsOpen(false);
    setSelectedBanner(null);
  };

  const isDayActive = dayNumber !== null;

  if (!isDayActive) {
    return <CardContainer isActive={false} />;
  }

  const displayAddBannerButton = bannerPermissions.write && banners.length < Object.keys(BANNER_TYPES[countryFilter]).length;

  return (
    <>
      <CardContainer isActive>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {dayNumber?.toString() ?? ''}
          </Typography>
          {dayNumber && (
            <div style={{ overflowY: 'auto', maxHeight: '100px' }}>
              {displayAddBannerButton && (
                <Typography
                  variant="body2"
                  component="span"
                  style={{ cursor: 'pointer' }}
                  key="add-banner"
                  onClick={createBanner}
                >
                  <div className="banner_label empty">+</div>
                </Typography>
              )}
              {banners.map((banner) => (
                <Tooltip title={banner.shop.domain} key={banner.id}>
                  <Typography
                    variant="body2"
                    component="span"
                    style={{ cursor: 'pointer' }}
                    onClick={editBanner(banner)}
                  >
                    <div className="banner_label">
                      {`${banner.bannerType} : ${banner.shop.domain.replace(
                        '*.',
                        '',
                      )}`}
                    </div>
                  </Typography>
                </Tooltip>
              ))}
            </div>
          )}
        </CardContent>
      </CardContainer>
      {createModalIsOpen && (
        <BannerModal
          isOpen={createModalIsOpen}
          closeModal={closeCreateBannerModal}
          availableShops={availableShops}
          setAvailableShops={setAvailableShops}
          bannerTypeIsAlreadyUsed={bannerTypeIsAlreadyUsed(reservedDaysByBannerType)}
        />
      )}
      {updateModalIsOpen && (
        <BannerModal
          isOpen={updateModalIsOpen}
          closeModal={closeUpdateBannerModal}
          availableShops={availableShops}
          setAvailableShops={setAvailableShops}
          selectedBanner={selectedBanner}
          bannerTypeIsAlreadyUsed={bannerTypeIsAlreadyUsed(reservedDaysByBannerType)}
        />
      )}
    </>
  );
}

DayCardComponent.propTypes = {
  dayNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  banners: PropTypes.arrayOf(PropTypes.object),
  reservedDaysByBannerType: PropTypes.instanceOf(Map),
};

const Days = () => {
  const { monthYear, bannerDataToDisplay } = useBannerContext();
  const {
    numberOfCells, getNumberOfMonth,
  } = getCalendarValues(monthYear);

  const reservedDaysByBannerType = bannerDataToDisplay.reduce((acc, bannerData) => {
    bannerData.banners.forEach((banner) => {
      if (!acc.has(banner.bannerType)) {
        acc.set(banner.bannerType, []);
      }
      acc.get(banner.bannerType).push({ day: bannerData.day, id: banner.id });
    });

    return acc;
  }, new Map());

  return Array.from({ length: numberOfCells }, (_, index) => index + 1).map(
    (i) => {
      const dayNumberOfMonth = getNumberOfMonth(i);
      const bannersByDay = bannerDataToDisplay[bannerDataToDisplay.findIndex((d) => moment(d.day).date() === dayNumberOfMonth)]
        ?.banners ?? [];
      return (
        <DayCardComponent
          dayNumber={dayNumberOfMonth}
          banners={bannersByDay}
          key={i}
          reservedDaysByBannerType={reservedDaysByBannerType}
        />
      );
    },
  );
};

const DISPLAY_ALL_CHOICE = { id: 'TOUS', name: 'TOUS' };

const byBannerType = (bannerTypeFilter) => (banner) => bannerTypeFilter === DISPLAY_ALL_CHOICE.id || banner.bannerType === bannerTypeFilter;
const byShop = (shopFilter) => (banner) => shopFilter === DISPLAY_ALL_CHOICE.id || banner.shop.id === shopFilter;
const useBannerData = (monthYear, fetchData, { bannerTypeFilter, countryFilter, shopFilter }) => {
  const [bannerDataForMonth, setBannerDataForMonth] = useState([]);
  const countryId = COUNTRIES[countryFilter].id;

  const loadBannerDataForMonth = useCallback(async () => {
    const rawData = await fetchData(monthYear, countryId);
    setBannerDataForMonth(rawData);
  }, [monthYear, countryId]);

  useEffect(() => {
    loadBannerDataForMonth();
  }, [loadBannerDataForMonth]);

  const bannerDataToDisplay = bannerDataForMonth.reduce(
    (acc, rawBanner) => {
      const filteredRawBanner = ({
        ...rawBanner,
        banners: rawBanner.banners.filter(byBannerType(bannerTypeFilter)).filter(byShop(shopFilter)),
      });

      acc.push(filteredRawBanner);

      return acc;
    },
    [],
  );

  const shopsUsedInBanners = [...bannerDataForMonth.reduce((acc, bannerData) => {
    bannerData.banners.forEach((banner) => {
      acc.add(`${banner.shop.id}__${banner.shop.domain}`);
    });

    return acc;
  }, new Set())].map((shop) => shop.split('__'));

  return { bannerDataToDisplay, loadBannerDataForMonth, shopsUsedInBanners };
};

export function ExclusiveBannerComponent(props) {
  const [monthYear, setMonthYear] = useState(moment().format('MM-YYYY'));
  const [countryFilter, setCountryFilter] = useState(COUNTRY_CODES.FR);
  const [bannerTypeFilter, setBannerTypeFilter] = useState(DISPLAY_ALL_CHOICE.id);
  const [shopFilter, setShopFilter] = useState(DISPLAY_ALL_CHOICE.id);
  const { bannerDataToDisplay, loadBannerDataForMonth, shopsUsedInBanners } = useBannerData(monthYear, bannerContextActions.fetchData, { bannerTypeFilter, countryFilter, shopFilter });

  return (
   <BannerContext.Provider
     value={
      {
        ...bannerContextActions,
        bannerPermissions: props.permissions?.mea['mea-exclusivebanners'] ?? { write: false, delete: false },
        monthYear,
        bannerDataToDisplay,
        loadBannerDataForMonth,
        countryFilter,
      }
    }
   >
      <div>
        <Filter {...props} setFilters={() => {}}>
          <SelectInput
            source="monthYear"
            choices={getMonthYears()}
            initialValue={monthYear}
            alwaysOn
            allowEmpty={false}
            onChange={(e) => setMonthYear(e.target.value)}
          />
          <SelectInput
            source="countryFilter"
            choices={Object.keys(COUNTRY_CODES).map((type) => ({ id: type, name: type }))}
            initialValue={countryFilter}
            alwaysOn
            allowEmpty={false}
            onChange={(e) => setCountryFilter(e.target.value)}
          />
          <SelectInput
            source="bannerTypeFilter"
            choices={[DISPLAY_ALL_CHOICE, ...BANNER_TYPES[countryFilter].map((type) => ({ id: type, name: type }))]}
            initialValue={bannerTypeFilter}
            alwaysOn
            allowEmpty={false}
            onChange={(e) => setBannerTypeFilter(e.target.value)}
          />
          <SelectInput
            source="shopFilter"
            choices={[DISPLAY_ALL_CHOICE, ...Array.from(shopsUsedInBanners).map(([id, domain]) => ({ id, name: domain }))]}
            initialValue={shopFilter}
            alwaysOn
            allowEmpty={false}
            onChange={(e) => setShopFilter(e.target.value)}
          />
        </Filter>
        <div className="calendar_parent">
          <DayNames />
          <Days />
        </div>
      </div>
   </BannerContext.Provider>
  );
}

ExclusiveBannerComponent.propTypes = {
  permissions: PropTypes.object,
};
