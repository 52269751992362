import React, { PureComponent } from 'react';

import {
  Filter,
  SelectInput,
  DateInput,
} from 'react-admin';
// Utils
import requester from '../../tools/requester';
import config from '../../config';

export default class BillFilter extends PureComponent {
  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      networks: [],
    };
  }

  /**
   * Component mount hook
   */
  componentDidMount() {
    this.fetchNetworks();
  }

  fetchNetworks = async () => requester(`${config.services.backend.endpoint}/networks`, {
    options: {
      params: { _end: 999, _start: 0 },
    },
    timeout: 29000,
  }).then((response) => {
    this.setState({ networks: response.data, error: null });
  }).catch((err) => {
    this.setState({
      error: err.response && err.response.status === 500 ? err.response.data.toString() : err.message,
    });
  });

  render = () => (
    <Filter variant="standard" {...this.props}>
      <SelectInput source="network" choices={this.state.networks} alwaysOn />
      <DateInput
        isRequired={false}
        parse={(v) => (new Date(v)).toISOString()}
        source="createdAt"
        label="Créé le"
        alwaysOn
      />
      <DateInput
        isRequired={false}
        parse={(v) => (new Date(v)).toISOString()}
        source="paidAt"
        label="Payée le"
        alwaysOn
      />
    </Filter>
  );
}
