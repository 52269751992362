import React from 'react';

import {
  List, TopToolbar, ExportButton, Datagrid, ShowButton, DateField, FunctionField, usePermissions, TextField,
} from 'react-admin';

import TransactionListIcon from '@material-ui/icons/CompareArrowsTwoTone';

// Custom stuff
import {
  IdField, ShowLink, StatusIcon, ErrorIcon,
} from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import TransactionListFilters from './TransactionListFilters';
import { OtherTypes } from '../../tools/types';

/**
 * TransactionList
 */
function TransactionList(props) {
  const { permissions } = usePermissions();

  return (
    <List
      actions={<TransactionListActions permissions={permissions ? permissions.transactions : {}} />}
      filters={<TransactionListFilters />}
      perPage={15}
      pagination={<SimplePagination />}
      {...props}
    >
      <Datagrid>
        <IdField label="id" sortable={false} />
        <ShowLink label="Shop" basePath="shops" source="shop.id" title="shop.domain" />
        <TextField source="shop.country.iso2a" label="Pays" />

        <DateField source="date" label="Date de transaction" showTime locales="fr-FR" />

        <FunctionField render={(record) => `${record.history.orderValue} ${record.currency}`} label="Valeur commande" />
        <FunctionField render={(record) => `${record.history.value} ${record.currency}`} label="Commission" />
        <FunctionField render={(record) => `${record.history.userValue} ${record.currency}`} label="Commission utilisateur" />

        <ShowLink label="Utilisateur" basePath="users" source="user.id" title="user.email" />

        <FunctionField render={(record) => `${record.click?.source || 'Non définie'}`} label="Click Source" />

        <StatusIcon label="Seen" source="history.seen" sortable={false} />
        <StatusIcon label="Approved" source="history.approved" sortable={false} />
        <StatusIcon label="PaidByNetwork" source="history.paidByNetwork" sortable={false} />
        <StatusIcon label="PaymentAsked" source="history.paymentAsked" sortable={false} />
        <StatusIcon label="PaidToUser" source="history.paidToUser" sortable={false} />
        <FunctionField label="Refusé" render={(record) => record.history.declineSource && <ErrorIcon />} sortable={false} />

        <ShowButton />
      </Datagrid>
    </List>
  );
}

function TransactionListActions({
  resource, exporter, filterValues, currentSort,
}) {
  return (
<TopToolbar>
    <ExportButton
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
</TopToolbar>
  );
}

TransactionListActions.propTypes = OtherTypes.ExportButton;

export { TransactionList, TransactionListIcon };
