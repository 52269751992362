import { Typography } from '@material-ui/core';
import React, { Component } from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ListButton,
  SaveButton,
  SelectInput,
  ShowButton,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
} from 'react-admin';

// Custom
import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle, SectionTitle } from '../Shared/Layout';
import CustomFlag from '../Shared/Layout/CustomFlag';
import DeleteButtonPopUp from './DeleteButtonPopUp';
import DeleteButtonWithConfirmation from './DeleteButtonWithConfirmation';

import { getCountries } from '../../tools/country';

import './edit.css';

function dateFormatter(v) {
  if (!(v instanceof Date) || Number.isNaN(v)) {
    return undefined;
  }

  return v.toLocaleDateString('fr-FR', { month: '2-digit', day: '2-digit', year: 'numeric' });
}
class UserEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: false,
      dstUserId: '',
      srcUserId: props.id,
    };
  }

  handleChangeSrcUserId = (event) => {
    this.setState({
      srcUserId: event.target.value,
    });
  }

  handleChangeDstUserId = (event) => {
    this.setState({
      dstUserId: event.target.value,
    });
  }

  render() {
    const {
      hasList, hasEdit, hasShow, hasCreate, ...props
    } = this.props;
    const permissions = props.permissions ? props.permissions.users : {};
    const { srcUserId, dstUserId } = this.state;

    return (
      <Edit {...props} undoable={false} actions={<UserActions permissions={permissions} />} title={<LayoutTitle source="email" />}>
        <TabbedForm fullWidth toolbar={<CustomToolbar permissions={permissions} />} redirect="show" {...props}>
          <FormTab variant="standard" label="Données personnelles">
            <TextInput disabled source="id" fullWidth />

            <SelectInput
              label="Pays"
              source="country.iso2a"
              translateChoice={false}
              choices={getCountries()}
              optionText={(choice) => (
                <CustomFlag isoCode={choice.id} />
              )}
            />

            <TextInput source="firstname" label="Prénom" />
            <TextInput source="lastname" label="Nom" />

            <TextInput source="email" label="Email" />
            <DateInput source="birthdate" label="Anniversaire" />

            <TextInput source="fraudComment" label="Commentaire fraude" fullWidth />

            <SectionTitle label="Coordonnées bancaires" />
            <TextInput source="bic" label="BIC" fullWidth />
            <TextInput source="iban" label="IBAN" fullWidth />

          </FormTab>

          <FormTab variant="standard" label="⚠️ Fusionner le compte">
            <TextInput source="id" label="Id de l'utilisateur actuel" name="id" onChange={this.handleChangeSrcUserId} fullWidth />
            <TextInput source="dstUserId" label="Id de l'utilisateur de destination" name="user" onChange={this.handleChangeDstUserId} fullWidth />
            <DeleteButtonPopUp srcUserId={srcUserId} dstUserId={dstUserId} />
          </FormTab>

          <FormTab variant="standard" label="Bloquer la demande de paiement">
            <BooleanInput label="Demande bloquée" source="blockPayment.hasBlockedPayment" />

            <FormDataConsumer source="blockPayment.blockedPaymentCreatedAt">
              {({ formData: { blockPayment } }) => (
                blockPayment && blockPayment.hasBlockedPayment && (
                  <DateInput
                    label="Date du blocage"
                    source="blockPayment.blockedPaymentCreatedAt"
                    validate={required()}
                    style={{ width: 256 }}
                  />
                )
              )}
            </FormDataConsumer>

            <FormDataConsumer source="blockPayment.blockedPaymentComment">
              {({ formData: { blockPayment } }) => (
                blockPayment && blockPayment.hasBlockedPayment && (
                  <TextInput
                    label="Commentaire"
                    source="blockPayment.blockedPaymentComment"
                    style={{ width: 256 }}
                  />
                )
              )}
            </FormDataConsumer>

            <FormDataConsumer source="requestedTransactionSum">
              {({ formData: { requestedTransactionSum } }) => (
                <div className="edit">
                  <Typography as="p" variant="body1">
                    Somme de la demande de paiement en cours :&nbsp;
                    <span>
                      {requestedTransactionSum}
                      {' '}
                      euros
                    </span>
                  </Typography>
                </div>
              )}
            </FormDataConsumer>

            <FormDataConsumer source="lastPaymentDate">
              {({ formData: { lastPaymentDate, requestedTransactionSum } }) => (
                <div
                  // dirty fix pour rendre à nouveau dispo l'écran edit d'un user en attendant de fix la récupération du montant
                  className={lastPaymentDate === null && requestedTransactionSum === 0 ? 'hidden' : 'edit'}
                >
                  <p>Date de la demande de paiement en cours:</p>
                  {dateFormatter(new Date(lastPaymentDate))}
                </div>
              )}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
}

function UserActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
</TopToolbar>
  );
}

function CustomToolbar({ permissions, ...props }) {
  return (
<Toolbar {...props}>
    <SaveButton {...props} />
    {permissions.delete && (
      <DeleteButtonWithConfirmation {...props} undoable />
    )}
</Toolbar>
  );
}

UserActions.propTypes = ActionsTypes.Edit;

CustomToolbar.propTypes = {
  permissions: PropTypes.object,
};

UserEdit.propTypes = {
  id: PropTypes.string,
  permissions: PropTypes.object,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasCreate: PropTypes.bool,
};

export default UserEdit;
