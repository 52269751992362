import React from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Checkbox, Accordion, AccordionSummary, Typography, AccordionDetails,
} from '@material-ui/core';
import { FixedSizeList } from 'react-window';

// Utils
import * as reconciliationService from './reconciliationService';

import TransactionDetails from './TransactionDetails';
import { PropTypes } from '../../tools/types';

class DateDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      loading: false,
      transactions: [],
      itemSize: 0,
      itemCount: 0,
      height: 0,
      width: 0,
      totalSelected: 0,
      nbSelected: 0,
      previousSelectionIndex: null,
      selectedTransactions: new Set(),
    };
    this.group = React.createRef();
  }

  componentDidMount() {
    this._reload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload === this.props.reload) {
      return;
    }

    this._reload();
  }

  _reload = () => {
    const { date } = this.props;
    if (date.transactions && date.transactions.length > 0) {
      const partialState = this._getStateToUpdate(date.transactions);

      const state = {
        ...partialState,
        ...this.computeSelectionTotal(date.transactions, date.transactions),
      };
      this.setState(state);
    }
  }

  _getStateToUpdate = (transactions) => {
    const { height: itemSize, width } = this.group.current ? this.group.current.querySelector('.panel-summary').getBoundingClientRect() : {};
    return {
      transactions,
      itemCount: transactions.length,
      itemSize,
      width,
      height: (transactions.length < 16 ? transactions.length * itemSize : itemSize * 16),
    };
  }

  /**
 * Toggles the loading state
 */
  toggleLoading = () => {
    this.setState(({ loading }) => ({
      loading: !loading,
    }));
  };

  /**
   * Toggles the expand state
   */
  toggleExpandedDate = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }), () => {
      if (this.state.expanded) {
        this.loadTransaction();
      }
    });
  }

  /**
   * Event fired when date checkbox change
   * @param event
   */
  onCheckedAllTransactionsChange = async (event) => {
    const { checked } = event.target;
    await this.loadTransaction();
    const { transactions } = this.state;

    const transactionsToCompute = transactions.map((t) => ({
      ...t,
      selected: checked,
    }));
    this.setState({
      ...this.computeSelectionTotal(transactions, transactionsToCompute),
      selectedTransactions: new Set(
        transactionsToCompute
          .filter((t) => t.selected)
          .map((t) => t.id),
      ),
    });
    this.props.onTransactionsChanged(transactionsToCompute);
  }

  computeSelectionTotal = (allTransactions, transactionToCompute) => {
    const { selectedTransactions } = this.state;

    transactionToCompute.forEach((t) => {
      if (t.selected) {
        selectedTransactions.add(t.id);
      } else {
        selectedTransactions.delete(t.id);
      }
    });

    const totalSelected = allTransactions
      .filter((t) => selectedTransactions.has(t.id))
      .reduce((previous, t) => previous + parseFloat(t.value), 0);

    return {
      selectedTransactions,
      transactions: allTransactions.map((t) => ({
        ...t,
        selected: selectedTransactions.has(t.id),
      })),
      totalSelected: totalSelected.toFixed(2),
    };
  }

  onSelectionChange = (transaction, index, isMultiSelection) => {
    const { previousSelectionIndex, transactions } = this.state;
    let transactionsSelected;

    if (isMultiSelection && previousSelectionIndex !== null) {
      let startIdx;
      let endIdx;

      if (previousSelectionIndex > index) {
        startIdx = index;
        endIdx = previousSelectionIndex;
      } else {
        startIdx = previousSelectionIndex;
        endIdx = index;
      }

      transactionsSelected = transactions
        .slice(startIdx, endIdx + 1)
        .map((t) => ({
          ...t,
          selected: transactions[previousSelectionIndex].selected,
        }));
    } else {
      transactionsSelected = [transaction];
    }

    this.setState({
      previousSelectionIndex: index,
      ...this.computeSelectionTotal(transactions, transactionsSelected),
    });
    this.props.onTransactionsChanged(transactionsSelected);
  }

  loadTransaction = async () => {
    if (this.state.transactions.length === 0 && !this.state.loading) {
      this.toggleLoading();
      try {
        const {
          shopId, networkId, date, currency, selectedDateType, dateStart, dateEnd,
        } = this.props;
        const data = await reconciliationService.getTransactionsData(networkId, shopId, date.yearMonth, currency, selectedDateType, dateStart, dateEnd);
        const transactions = data.map((t) => ({ ...t, selected: false }));
        const state = this._getStateToUpdate(transactions);
        this.setState(state);
        this.props.onTransactionsLoaded({ ...date, transactions });
      } catch (error) {
        this.setState({ expanded: false });
      } finally {
        this.toggleLoading();
      }
    }
  }

  displaySubTotal = () => {
    const {
      date,
      currency,
      disableCheckbox,
    } = this.props;
    const { totalSelected } = this.state;
    const part = disableCheckbox ? '' : `${totalSelected} / `;
    return `${part}${date.total.toFixed(2)} ${currency}`;
  }

  render() {
    const {
      date,
      disableCheckbox,
    } = this.props;
    const {
      expanded,
      loading,
      selectedTransactions,
    } = this.state;
    return (
      <AccordionDetails className="container">
        <div style={{ width: 'calc(100% - 22px)' }} ref={this.group}>
          <Accordion className="group subgroup" onChange={this.toggleExpandedDate}>
            <AccordionSummary className="panel-summary" expandIcon={<ExpandMore />}>
              <div className="panel-name">
                <Typography className="bold">{date.yearMonth}</Typography>
              </div>
              <div className="panel-checkbox">
                <Typography className="bold">
                  {this.displaySubTotal()}
                </Typography>
                {!disableCheckbox && (
                  <Checkbox
                    className="parent-checkbox top selected-date"
                    checked={selectedTransactions.size > 0}
                    indeterminate={selectedTransactions.size > 0 && selectedTransactions.size !== this.state.transactions.length}
                    onChange={this.onCheckedAllTransactionsChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                )}
              </div>
            </AccordionSummary>

            {expanded && loading && (
              <Typography>Récupération des transactions...</Typography>
            )}

            {expanded && !loading && (
              <FixedSizeList
                height={this.state.height}
                width={this.state.width}
                itemSize={this.state.itemSize}
                itemCount={this.state.itemCount}
              >
                {({ index, style }) => {
                  const transaction = this.state.transactions[index];

                  return (
                    <div style={style}>
                      <TransactionDetails
                        checked={this.state.selectedTransactions.has(transaction.id)}
                        transaction={transaction}
                        groupId={date.id}
                        toggleTransactionSelectionEvent={(t, isMultiSelection) => {
                          this.onSelectionChange(t, index, isMultiSelection);
                        }}
                        key={transaction.id}
                        disableCheckbox={disableCheckbox}
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </Accordion>
        </div>
      </AccordionDetails>
    );
  }
}

DateDetails.propTypes = {
  date: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
  networkId: PropTypes.string,
  selectedDateType: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  onTransactionsChanged: PropTypes.func,
  onTransactionsLoaded: PropTypes.func,
  reload: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
};

export default DateDetails;
