import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Badge from '@material-ui/core/Badge';

import requester from '../../tools/requester';
import config from '../../config';
import { PropTypes } from '../../tools/types';

class NetworkPrograms extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      programs: [],
    };
    this.endpoint = config.services.backend.endpoint;
  }

  componentDidMount() {
    requester(`${this.endpoint}/dashboard/networks-programs`, { method: 'get' }).then(({ data }) => this.setState(() => ({ programs: data })));
  }

  goToShopPrograms({ shopId, unknownPrograms }) {
    if (shopId && unknownPrograms > 0) {
      this.props.history.push(`/shops/${shopId}/show/programs?isShopNetwork=true`);
    }
  }

  render() {
    const { programs } = this.state;
    const filteredPrograms = programs.filter((program) => program.unknownPrograms > 0);
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {filteredPrograms.map((row) => (
              <TableRow key={`${row.domain} - ${row.country}`} onClick={() => this.goToShopPrograms(row)} hover style={row.shopId ? { cursor: 'pointer' } : {}}>
                <TableCell component="th" scope="row">{`${row.domain} - ${row.country}`}</TableCell>
                <TableCell>
                  <Badge badgeContent={row.actualPrograms} color="error"><span /></Badge>
                </TableCell>
              </TableRow>
            ))}

            {filteredPrograms.length === 0 && (
              <TableRow onClick={() => window.open('https://www.youtube.com/watch?v=T5WdpSPeQUE', '_blank')} hover style={{ cursor: 'pointer' }}>
                <TableCell>
                  Tout va bien Madame la marquise
                {' '}
                  <span role="img" aria-label="queen">👸🏻</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

NetworkPrograms.propTypes = {
  history: PropTypes.object,
};

export default NetworkPrograms;
