import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import './Calendar.css';

export const DayNames = () => ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map(
  (day) => (
      <div className="child-week" key={day}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              {day}
            </Typography>
          </CardContent>
        </Card>
      </div>
  ),
);
