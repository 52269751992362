import React from 'react';
import { Card } from '@material-ui/core';
import { PropTypes } from '../../../tools/types';

export function CardContainer({
  isActive, children, onClick = () => {},
}) {
  return (
<Card
  className="child"
  style={{ backgroundColor: !isActive ? 'lightgrey' : '' }}
  onClick={onClick}
>
    {children}
</Card>
  );
}

CardContainer.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
