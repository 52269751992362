import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { PropTypes } from '../../tools/types';

function DialogPopup({
  children,
  dialogIsOpened,
  handleCloseClick,
  title,
  actions,
}) {
  return (
<Dialog fullWidth open={dialogIsOpened} onClose={handleCloseClick} aria-label="Are you sure ?">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions>
      {actions}
    </DialogActions>
</Dialog>
  );
}

DialogPopup.propTypes = {
  children: PropTypes.array,
  dialogIsOpened: PropTypes.bool,
  handleCloseClick: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.element,
};

export default DialogPopup;
