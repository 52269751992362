import React from 'react';
import { withRouter } from 'react-router';

import {
  Card,
  Button,
  CardContent,
  Typography,
  TextField, Checkbox,
} from '@material-ui/core';

import config from '../../config';
import requester from '../../tools/requester';
import { PropTypes } from '../../tools/types';
import ClearCacheButton from './ClearCacheButton';

class TotalDetails extends React.PureComponent {
  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      createLoading: false,
      createError: false,
      bill: '',
      billInputError: null,
    };

    this.apiEndpoint = config.services.backend.endpoint;
  }

  /**
  * Process to the bill creation
  */
  onCreateBill = async () => {
    const {
      networkId, currency, history, selectedTotal, selectedTransactionIds,
    } = this.props;

    this.setState({ createLoading: true });

    try {
      const response = await requester(`${this.apiEndpoint}/bills`, {
        method: 'POST',
        options: {
          data: {
            total: selectedTotal,
            billDate: new Date().toISOString().split('T')[0],
            shops: [],
            dates: [],
            currency,
            networkId,
            transactions: selectedTransactionIds,
          },
        },
      });

      const { data: created } = response;

      // Redirect to created bill
      history.push(`/bills/${created.id}/show`);
    } catch (err) {
      const message = err.response && err.response.status === 500 ? err.response.data.toString() : err.message;
      this.setState({ createError: message, createLoading: false });
    }
  };

  /**
   * Input change event handler
   * @param event - Input change event
   */
  handleChange = (event) => this.setState({ [event.target.name]: event.target.value, [`${event.target.name}InputError`]: null });

  /**
   * Bill association form submission event handler
   * @param event
   */
  handleSubmit = (event) => {
    event.preventDefault();

    const { bill, billInputError } = this.state;
    const { history, selectedTransactionIds } = this.props;

    if (billInputError) return false;

    return requester(`${this.apiEndpoint}/bills/${bill}/associate`, {
      method: 'PUT',
      options: {
        data: selectedTransactionIds,
      },
    }).then(({ data }) => {
      history.push(`/bills/${data.id}/show`);
    }).catch((err) => {
      let error = err.message;
      if (err.response && err.response.data && [403, 500].includes(err.response.status)) {
        if (typeof err.response.data === 'object') {
          error = err.response.data.errors.details[0].message;
        } else {
          error = err.response.data;
        }
      }

      this.setState({ billInputError: error });
    });
  }

  /**
   * Component render method
   *
   * @returns {*}
   */
  render() {
    const {
      currency, selectedTotal, nbTransactions, nbSelected, toggleAllTransactions, networkId, match, handleBillCacheClear,
    } = this.props;
    const {
      createLoading, createError, bill, billInputError,
    } = this.state;
    const checked = nbSelected > 0;
    const indeterminate = nbSelected > 0 && nbSelected !== nbTransactions;
    return (
      <Card className="card-fixed">
        <CardContent>
          {networkId && (
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              style={{ marginLeft: '149px', width: '18px', height: '18px' }}
              disabled={!!billInputError}
              onChange={toggleAllTransactions}
            />
          )}

          <Typography color="textSecondary">Total Transactions</Typography>
          <Typography color="textSecondary">{`${nbSelected || 0} transactions`}</Typography>
          <Typography variant="h5" gutterBottom>
            {`${Math.abs(selectedTotal).toFixed(2)} ${currency}`}
          </Typography>
          {createError && (
            <Typography color="error" variant="subtitle1">{`Error: ${createError}`}</Typography>
          )}
          <Button
            disabled={createLoading || !selectedTotal}
            variant="contained"
            color="secondary"
            size="small"
            onClick={this.onCreateBill}
          >
            {createLoading ? 'Création en cours...' : 'Créer une facture'}
          </Button>
        </CardContent>
        {networkId && (
          <CardContent>
            <form method="POST" action={`/bills/${bill}/associate`} onSubmit={this.handleSubmit}>
              <div>
                <TextField
                  label="Affecter à une facture"
                  name="bill"
                  value={bill}
                  margin="normal"
                  onChange={this.handleChange}
                  error={this.state.billInputError !== null}
                  helperText={this.state.billInputError}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={nbSelected === 0 || !!billInputError}
              >
                Affecter
              </Button>
            </form>
            <ClearCacheButton
              match={match}
              clearCacheAvailable={!!networkId}
              handleBillCacheClear={handleBillCacheClear}
            />
          </CardContent>
        )}
      </Card>
    );
  }
}

TotalDetails.propTypes = {
  currency: PropTypes.string.isRequired,
  networkId: PropTypes.string,
  history: PropTypes.object,
  selectedTotal: PropTypes.number.isRequired,
  nbSelected: PropTypes.number.isRequired,
  nbTransactions: PropTypes.number.isRequired,
  toggleAllTransactions: PropTypes.func.isRequired,
  selectedTransactionIds: PropTypes.array,
  match: PropTypes.object,
  handleBillCacheClear: PropTypes.func.isRequired,
};

export default withRouter(TotalDetails);
