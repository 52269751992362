import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

const validatePattern = (value) => {
  try {
    // eslint-disable-next-line no-new
    new RegExp(value);
  } catch (e) {
    return 'RegExp invalide';
  }

  return undefined;
};

function BonusPatternCreate(props) {
  return (
<Create {...props}>
    <SimpleForm variant="standard">
      <TextInput source="pattern" label="Pattern" name="pattern" validate={[required(), validatePattern]} />
    </SimpleForm>
</Create>
  );
}

export default BonusPatternCreate;
