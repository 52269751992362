import React from 'react';
import InquiryImportDomainListIcon from '@material-ui/icons/AssignmentInd';
import {
  Datagrid,
  List,
  TextField,
  DateField,
  usePermissions,
  EditButton,
} from 'react-admin';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import { StatusIcon, IdField } from '../Shared/Layout';

export function InquiryImportDomainList(props) {
  const { permissions } = usePermissions();

  return (
    <List {...props} perPage={20} pagination={<SimplePagination />}>
      <Datagrid>
        <IdField source="domain" label="Domaine" sortable={false} />
        <StatusIcon source="isEnabled" sortable={false} label="Activé" />
        <DateField
          source="validityDate"
          sortable={false}
          label="Date de début de validité"
        />
        <TextField source="comment" sortable={false} label="Commentaire" />
        {permissions?.['inquiry-import-domains']?.write ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
}

export { InquiryImportDomainListIcon };
