import babyBottle from './baby-bottle.png';
import bankNote from './bank-note.png';
import basketBalloon from './basket-balloon.png';
import book from './book.png';
import car from './car.png';
import dress from './dress.png';
import gift from './emoji-gift.png';
import forkKnife from './fork-knife.png';
import hamburger from './hamburger.png';
import headphone from './headphone.png';
import house from './house.png';
import island from './island.png';
import lipstick from './lipstick.png';
import paperclip from './paperclip.png';
import shopping from './shopping-bag.png';

export default {
  babyBottle,
  bankNote,
  basketBalloon,
  book,
  car,
  dress,
  gift,
  forkKnife,
  hamburger,
  headphone,
  house,
  island,
  lipstick,
  paperclip,
  shopping,
};
