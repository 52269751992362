import React from 'react';
import {
  Create, SimpleForm, TextInput, required,
} from 'react-admin';

import { getCookie } from '../../tools/cookies';
import { PropTypes } from '../../tools/types';

const redirect = (basePath, id, data) => `/shops/${data.shopId}/show/blacklists`;

function BlacklistCreate(props) {
  const shopId = (new URLSearchParams(props.location.search)).get('shopId');

  const initialValues = {
    shopId,
    idUser: getCookie('uid'),
  };

  return (
    <Create {...props}>
      <SimpleForm variant="standard" initialValues={initialValues} redirect={redirect}>
        <TextInput source="comment" label="Comment" validate={required()} />
        <TextInput source="idVoucher" label="ID Voucher" />
        <TextInput source="codeVoucher" label="Code Voucher" />
      </SimpleForm>
    </Create>
  );
}

BlacklistCreate.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BlacklistCreate;
