import React, { PureComponent } from 'react';
import {
  Tab,
  TabbedShowLayout,
} from 'react-admin';

import MEAListIcon from '@material-ui/icons/Assignment';

import { PropTypes } from '../../tools/types';
import SliderHomepageComponent from './Pack2/SliderHomepage';
import NotifCompetitorComponent from './Pack3/NotifCompetitor';
import MEAExtensionComponent from './Extension/MEAExtension';
import MEAFraisFixeOnlyComponent from './FraisFixeOnly/MEAFraisFixeOnly';
import { ExclusiveBannerComponent } from './Banner/Banner';

class MEAMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <TabbedShowLayout>
        <Tab label="Slider HomePage" path="sliderHomepage">
          <SliderHomepageComponent {...this.props} />
        </Tab>
        <Tab label="Notif concurrent" path="notif">
          <NotifCompetitorComponent {...this.props} />
        </Tab>
        <Tab label="MEA Extension" path="extension">
          <MEAExtensionComponent {...this.props} />
        </Tab>
        <Tab label="MEA Frais Fixes Only" path="fraisfixeonly">
          <MEAFraisFixeOnlyComponent {...this.props} />
        </Tab>
        <Tab label="Exclusive banner" path="exclusiveBanner">
          <ExclusiveBannerComponent {...this.props} />
        </Tab>
      </TabbedShowLayout>
    );
  }
}

MEAMenu.propTypes = PropTypes.object;

export { MEAMenu, MEAListIcon };
