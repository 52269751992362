import React, { Component } from 'react';

import {
  Typography, Checkbox, FormControlLabel, Button,
} from '@material-ui/core';

import config from '../../config';
import { PropTypes } from '../../tools/types';
import requester from '../../tools/requester';
import DialogPopup from './DialogPopup';

class DeleteButtonPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isErrored: false,
      isSuccess: false,
      dialogIsOpened: false,
      dialogSaveButtonName: 'Je valide la fusion',
      dialogSaveButtonDisabled: true,
      messageError: 'Une erreur obscure apparraît !',
    };

    this.apiEndpoint = config.services.backend.endpoint;
  }

  async componentDidUpdate(_, prevState) {
    if (prevState.dialogIsOpened !== this.state.dialogIsOpened && this.state.dialogIsOpened === true) {
      const {
        srcUserId,
        dstUserId,
      } = this.props;

      let data;

      if (srcUserId === dstUserId) {
        return this.setState({
          messageError: 'Les deux userId doivent être différents',
          isErrored: true,
          dialogIsOpened: true,
          dialogSaveButtonDisabled: false,
          dialogSaveButtonName: 'Fermer',
        });
      }

      try {
        data = await Promise.all([
          requester(`${this.apiEndpoint}/users/${srcUserId}`, { method: 'GET' }),
          requester(`${this.apiEndpoint}/users/${dstUserId}`, { method: 'GET' }),
        ]);
      } catch (e) {
        return this.setState({
          dialogIsOpened: true,
          dialogSaveButtonName: 'Fermer',
          isErrored: true,
          dialogSaveButtonDisabled: false,
          messageError: 'L\'idUser renseigné n\'existe pas dans la base (ou il y a une autre erreur)',
        });
      }

      return this.setState({
        srcUserId,
        dstUserId,
        srcUserEmail: data[0].data.email,
        dstUserEmail: data[1].data.email,
        isErrored: false,
        dialogSaveButtonDisabled: true,
      });
    }

    return true;
  }

  handleOpenClick = () => {
    this.setState({ dialogIsOpened: true });
  };

  handleCloseClick = () => {
    this.setState({ dialogIsOpened: false });
  };

  mergeUser = async () => {
    if (this.state.isLoading) return false;

    if (!this.state.isErrored) {
      if (this.state.isSuccess) {
        window.location.href = '/#/users';
        return false;
      }

      this.setState({ isLoading: true });

      let answer;

      try {
        answer = await requester(`${this.apiEndpoint}/users/merge`, {
          method: 'POST',
          options: {
            data: {
              srcUserId: this.state.srcUserId,
              dstUserId: this.state.dstUserId,
            },
          },
        });
      } catch (e) {
        return this.setState({
          dialogIsOpened: true,
          dialogSaveButtonName: 'Fermer',
          isErrored: true,
          dialogSaveButtonDisabled: false,
          messageError: `Erreur lors de la fusion des utilisateurs. Transmettez l'erreur suivante à l'équipe technique: ${e?.response?.data}`,
          isLoading: false,
        });
      }

      if (answer.data.id) {
        return this.setState({
          isSuccess: true,
          dialogIsOpened: true,
          isLoading: false,
          dialogSaveButtonName: 'Revenir aux utilisateurs',
        });
      }
    }

    return this.setState({ dialogIsOpened: false });
  }

  render() {
    const { dstUserId } = this.props;
    const {
      dialogIsOpened,
      isErrored,
      dialogSaveButtonName,
      dialogSaveButtonDisabled,
      messageError,
      isSuccess,
    } = this.state;

    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          disabled={dstUserId === null || dstUserId.trim().length === 0}
          onClick={this.handleOpenClick}
        >
          Fusionner cet utilisateur
        </Button>
        <DialogPopup
          title="Confirmation de fusion"
          dialogIsOpened={dialogIsOpened}
          handleCloseClick={this.handleCloseClick}
          actions={(
            <Button
              variant="contained"
              color="primary"
              disabled={dialogSaveButtonDisabled}
              onClick={this.mergeUser}
            >
              {dialogSaveButtonName}
            </Button>
          )}
        >
          {isErrored && (
            <>
              <Typography variant="h4">
                <span role="img" aria-label="warning">️️❌ </span>
                Erreur
              </Typography>
              <Typography variant="body1">
                {messageError}
              </Typography>
            </>
          )}
          {!isErrored && !isSuccess && (
            <>
              <Typography variant="h6" gutterBottom>
                <span role="img" aria-label="warning">️️⚠️ </span>
                Attention, la fusion est irréversible !!
              </Typography>
              <Typography variant="body1">
                Vous allez merger l&apos;utilisateur (
                {this.state.srcUserId}
                )
                <span style={{ color: 'red' }}>{` ${this.state.srcUserEmail} `}</span>
                <br />
                avec ...
                <br />
                l&apos;utilisateur (
                {this.state.dstUserId}
                )
                <span style={{ color: 'green' }}>{` ${this.state.dstUserEmail} `}</span>
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(_, checked) => this.setState({ dialogSaveButtonDisabled: !checked })}
                  />
                )}
                label="J'ai pris connaissance de ce que je fais"
              />
            </>
          )}
          {isSuccess && (
            <>
              <Typography variant="h4">
                <span role="img" aria-label="warning">️✅ </span>
                Yeah ça a marché
                <span role="img" aria-label="warning"> 😎 </span>
              </Typography>
              <Typography variant="body1">{'La fusion du compte s\'est faite comme il faut.'}</Typography>
            </>
          )}
        </DialogPopup>
      </>
    );
  }
}

DeleteButtonPopUp.propTypes = {
  srcUserId: PropTypes.string,
  dstUserId: PropTypes.string,
};

export default DeleteButtonPopUp;
