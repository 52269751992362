import React from 'react';
import {
  List,
  Filter,
  TopToolbar,
  ExportButton,
  Datagrid,
  // BooleanField,
  DateField,
  TextField,
  TextInput,
  FunctionField,
  ShowButton,
  CreateButton,
  usePermissions,
  Pagination,
} from 'react-admin';

import ShopListIcon from '@material-ui/icons/Business';

import { IdField } from '../Shared/Layout';
import { OtherTypes } from '../../tools/types';

const amountField = (record) => record.cashback && `${record.cashback.amount} ${record.cashback.type === 'PERCENTAGE' ? '%' : record.cashback.currency}`;
const renderCountry = (record) => (record.country ? record.country.iso2a.toUpperCase() : 'Pays non défini');

/**
 * shopList
 */
function ShopList(props) {
  const { permissions } = usePermissions();

  return (
  <List
    {...props}
    title="Boutiques"
    filters={<ShopListFilters />}
    actions={<ShopListActions permissions={permissions ? permissions.shops : {}} />}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
  >
    <Datagrid>
      <IdField label="id" sortable={false} />

      <TextField source="domain" sortable={false} />
      <TextField source="url" sortable={false} />
      <FunctionField label="Pays" render={renderCountry} sortable={false} />

      {/* TODO: add this column when BO-backend send this information */}
      {/* <BooleanField label="Espace par défaut" source="space.default" sortable={false} /> */}

      <FunctionField label="Cashback" render={amountField} sortable={false} />

      <TextField source="alertEnabled" sortable={false} />

      <TextField source="voucherAccess" sortable={false} />
      <TextField source="voucherBehavior" sortable={false} />

      <DateField source="created" showTime locales="fr-FR" sortable={false} />
      <DateField source="updated" showTime locales="fr-FR" sortable={false} />

      <ShowButton />
    </Datagrid>
  </List>
  );
}

function ShopListFilters(props) {
  return (
<Filter variant="standard" {...props}>
    <TextInput label="Domaine" source="q" alwaysOn />
</Filter>
  );
}

function ShopListActions({
  resource, exporter, filterValues, currentSort, permissions,
}) {
  return (
<TopToolbar>
    {permissions.write && <CreateButton to="/shops/create" />}
    <ExportButton
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
</TopToolbar>
  );
}

ShopListActions.propTypes = OtherTypes.ExportButton;

export { ShopList, ShopListIcon };
