import axios from 'axios';
import config from '../config';
import { setCookie } from './cookies';

function get() {
  if (window.localStorage) {
    return window.localStorage.getItem('token');
  }

  return null;
}

function set(token) {
  if (window.localStorage) {
    return window.localStorage.setItem('token', token);
  }

  return null;
}

function clear() {
  if (window.localStorage) {
    return window.localStorage.removeItem('token');
  }

  return null;
}

// *
// Check access token
async function checkAccessToken() {
  const token = localStorage.getItem('access_token');

  if (!token) {
    return false;
  }

  try {
    const response = await axios({
      url: `${config.services.backend.endpoint}/auth`,
      method: 'GET',
      headers: {
        'Access-Token': token,
      },
    });

    if (response.status === 200) {
      setCookie('uid', response.data.id);
      set(response.data.jwtToken);
    }

    return response.status === 200 ? !!response.data : false;
  } catch (err) {
    return false;
  }
}

export default {
  clear,
  get,
  set,
  checkAccessToken,
};
