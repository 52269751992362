import React from 'react';
import {
  Edit,
  TextField,
  NumberInput,
  TextInput,
  DateTimeInput,
  DateField,
  BooleanField,
  SimpleForm,
  Toolbar,
  SaveButton,
  TopToolbar,
  ListButton,
  DeleteButton,
  usePermissions,
} from 'react-admin';
import { ActionsTypes } from '../../tools/types';

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton />
    {/* eslint-disable-next-line react/prop-types */}
    { props.permissions.delete && <DeleteButton {...props} />}
</Toolbar>
  );
}

function InquiryImportDomainActions({ basePath }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
</TopToolbar>
  );
}

InquiryImportDomainActions.propTypes = ActionsTypes.Edit;

function InquiryEdit(props) {
  const { permissions } = usePermissions();
  const inquiryPermissions = permissions ? permissions.inquiries : {};

  return (
    <Edit undoable={false} actions={<InquiryImportDomainActions />} {...props}>
      <SimpleForm variant="standard" label="Résumé" toolbar={<CustomToolbar permissions={inquiryPermissions} />}>
        <TextField source="id" label="Id de la réclamation" />
        <DateField source="dateCreated" label="Date de création" showTime locales="fr-FR" />
        <TextField source="status" />
        <TextInput source="idOrder" label="Numéro de commande" />
        <DateTimeInput source="dateOrder" label="Date de commande" />
        <NumberInput source="orderValue" label="Valeur commande" />
        <NumberInput source="cashbackValue" label="Commission" />
        <NumberInput source="cashbackUserValue" label="Commission utilisateur" />
        <BooleanField source="uploaded" label="Envoyée à Strackr" />
        <TextField source="type" />
        <TextField source="error" />
      </SimpleForm>
    </Edit>
  );
}
export default InquiryEdit;
