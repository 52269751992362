import React, { Fragment } from 'react';
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  CreateButton,
  TopToolbar,
} from 'react-admin';
import { PropTypes } from '../../tools/types';
import './index.css';

/**
 * VoucherList
 */
function VoucherList(props) {
  const { shopId, ...ownProps } = props;

  return (
    <>
      <TopToolbar>
        <CreateButton
          to={{
            pathname: '/vouchers/create',
            search: `?shopId=${shopId}`,
          }}
        />
      </TopToolbar>
      <p className="index">Si un code n&apos;est pas trouvable, pensez à regarder s&apos;il n&apos;est pas blacklisté (onglet blacklists)</p>
      <ReferenceManyField {...ownProps} addLabel={false} reference="vouchers" target="shopId">
        <Datagrid>
          <TextField source="id" sortable={false} label="ID" />
          <TextField source="source.name" sortable={false} label="Site source" />
          <TextField source="code" sortable={false} label="Code" />
          <BooleanField source="exclusive" sortable={false} label="Exclusif" />
          <BooleanField source="isRecommended" sortable={false} label="Recommandé" />
          <DateField source="validFrom" sortable={false} label="Valable du" />
          <DateField source="validTo" sortable={false} label="Valable jusqu'au" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

VoucherList.propTypes = {
  data: PropTypes.object,
  shopId: PropTypes.string,
};

export default VoucherList;
