import React, { Component } from 'react';
import {
  TopToolbar,
  ListButton,
  DeleteButton,
  Edit,
  TextInput,
  SimpleForm,
  required,
} from 'react-admin';

// Custom
import { LayoutTitle } from '../Shared/Layout';
import { ActionsTypes, PropTypes } from '../../tools/types';

class BonusPatternEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pattern: '',
    };
  }

  validatePattern = (value) => {
    try {
      // eslint-disable-next-line no-new
      new RegExp(value);
    } catch (e) {
      return 'RegExp invalide';
    }

    return undefined;
  }

  handleChangePattern = (event) => {
    this.setState({
      pattern: event.target.value,
    });
  }

  render() {
    const { permissions } = this.props;

    return (
      <Edit undoable={false} actions={<BonusPatternActions permissions={permissions ? permissions['bonus-patterns'] : {}} />} title={<LayoutTitle source="pattern" />} {...this.props}>
        <SimpleForm variant="standard" label="Bonus pattern">
          <TextInput disabled source="id" />
          <TextInput source="pattern" label="Pattern" name="pattern" onChange={this.handleChangePattern} validate={[required(), this.validatePattern]} />
        </SimpleForm>
      </Edit>
    );
  }
}

function BonusPatternActions({
  basePath, data, resource, permissions,
}) {
  return (
<TopToolbar>
      {permissions && permissions.delete
        && <DeleteButton basePath={basePath} record={data} resource={resource} />}
      <ListButton basePath={basePath} />
</TopToolbar>
  );
}

BonusPatternActions.propTypes = ActionsTypes.Edit;

BonusPatternEdit.propTypes = {
  id: PropTypes.string,
  permissions: PropTypes.object,
};

export default BonusPatternEdit;
