import React, { PureComponent } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  SimpleForm,
  DateInput,
  NumberInput,
  Button,
  TextInput,
} from 'react-admin';

import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import imgs from './img/index';
import { PropTypes } from '../../../tools/types';
import requester from '../../../tools/requester';
import config from '../../../config';
import categories from './categories';

class DayCardComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      showDialog: false,
      showDialogError: false,
      dialogSaveButtonDisabled: false,
      options: [],
      loading: false,
      open: false,
    };
  }

  setOpen = (open) => {
    this.setState({ open });
  }

  optionRenderer = (a) => a && `${a.domain.replace('*.', '')} (${a.url}) - ${a.country.iso2a}`;

  handleClick = async (reservation, category) => {
    if (!this.props.permissions.mea['mea-notifs'].write) {
      return;
    }

    const { country } = this.props;
    const shops = await requester(`${config.services.backend.endpoint}/shops?_end=10&_start=0&country=${country}&category=${category}`, { method: 'GET' });
    const isUpdate = reservation !== undefined;
    this.setState({
      showDialog: true,
      selectedReservation: reservation,
      options: shops.data,
      dialogSaveButtonDisabled: !reservation,
      isUpdate,
      category,
    });
  };

  changeShop = (event, newShop) => {
    this.setState({ dialogShop: newShop }, this.validateValues);
  }

  handleCloseClick = () => {
    this.closeDialog();
  };

  handleCloseDialogErrorClick = () => {
    this.setState({ showDialogError: false });
  }

  handleSaveClick = async () => {
    const {
      isUpdate,
      category,
    } = this.state;

    const {
      startDate,
      endDate,
      fixedCosts,
      dialogShop,
    } = this.state.selectedReservation;

    const data = {
      startDate,
      endDate,
      fixedCosts,
      idShop: dialogShop.id,
    };

    try {
      if (isUpdate) {
        await requester(`${config.services.backend.endpoint}/mea-notifs/${this.state.selectedReservation.id}`, { method: 'PUT', options: { data } });
      } else {
        await requester(`${config.services.backend.endpoint}/mea-notifs`, { method: 'POST', options: { data } });
      }
    } catch (error) {
      const errors = error.response.data;
      const errorMessages = {
        full: this.buildErrorMessage(errors, 'FULL', `- l'espace ${category} est déjà booké`),
        sameShop: this.buildErrorMessage(errors, 'SAME_SHOP', '- ce shop a déjà une reservation'),
      };

      if (!errorMessages.full && !errorMessages.sameShop) {
        errorMessages.message = error.response.data;
      }

      this.setState({ showDialogError: true, errorMessages });
    }
    this.closeDialog();
  }

  buildErrorMessage = (error, errorPropName, text) => {
    let errorMessage;
    if (error[errorPropName]) {
      errorMessage = text;
      errorMessage += error[errorPropName].length === 1 ? ' sur la journée du  ' : ' sur les journées du ';
      errorMessage += error[errorPropName].map((d) => moment(d).format('DD/MM/YYYY')).join(', ');
      const lastIndex = errorMessage.lastIndexOf(',');
      errorMessage = errorMessage.slice(0, lastIndex) + errorMessage.slice(lastIndex).replace(',', ' et');
    }
    return errorMessage;
  }

  closeDialog = () => {
    this.setState({
      selectedReservation: {},
      dialogShop: null,
      showDialog: false,
    });
    this.props.reload();
  }

  handleRemoveClick = async () => {
    await requester(`${config.services.backend.endpoint}/mea-notifs/${this.state.selectedReservation.id}`, { method: 'DELETE' });
    this.closeDialog();
  }

  validateValues = (values) => {
    const errors = {};

    const {
      startDate: startDateTmp,
      endDate: endDateTmp,
      fixedCosts: fixedCostsTmp,
    } = this.state.selectedReservation || {};
    const startDate = values?.startDate || startDateTmp;
    const endDate = values?.endDate || endDateTmp;
    const fixedCosts = values?.fixedCosts || fixedCostsTmp;

    let { dialogShop } = this.state;
    if (!dialogShop && this.state.selectedReservation) {
      dialogShop = this.state.selectedReservation.shop;
    }

    errors.endDate = this.validateDate(startDate, endDate);
    this.setState((prevState) => ({
      selectedReservation: {
        ...prevState.selectedReservation,
        dialogShop,
        startDate,
        endDate,
        fixedCosts,
      },
      dialogSaveButtonDisabled: !startDate || !endDate || !dialogShop,
    }));
    return errors;
  }

  validateDate = (startDate, endDate) => {
    let result = '';
    const date1 = moment(startDate);
    const date2 = moment(endDate);
    if (startDate && endDate && date1 > date2) {
      result = 'La date de fin doit être supérieure à la date de début';
    }
    return result;
  }

  searchShops = async (event, newInputValue) => {
    if (newInputValue.length > 2) {
      const { country } = this.props;
      const { category } = this.state;
      const shops = await requester(`${config.services.backend.endpoint}/shops?_end=10&_start=0&country=${country}&q=${newInputValue}&category=${category}`, { method: 'GET' });
      this.setState({ options: shops.data });
    }
  }

  /**
   * Component rendering
   *
   * @returns {*}
   */
  render() {
    const {
      nbDayOfMonth,
      stateDay,
      permissions,
      country,
    } = this.props;
    const {
      showDialog,
      dialogSaveButtonDisabled,
      options,
      open,
      loading,
      isUpdate,
      showDialogError,
      errorMessages,
      category,
    } = this.state;

    const deletePermission = permissions.mea['mea-notifs'].delete;

    const startDate = this.state.selectedReservation?.startDate || this.props.startDate;

    const {
      endDate,
      fixedCosts,
      shop: dialogShop,
    } = this.state.selectedReservation || {};

    const categoriesToDisplay = categories.find((c) => c.country === country).categories;

    const slices = [];
    if (nbDayOfMonth) {
      for (let i = 0; i < categoriesToDisplay.length; i++) {
        const categoryToDisplay = categoriesToDisplay[i];
        let reservation;
        try {
          reservation = stateDay.bookings.find((r) => r.shop.mainCategory === categoryToDisplay.key);
        } catch (error) {
          // volontaire
        }

        const contentSlice = reservation ? (
          <Tooltip title={reservation.shop.title || reservation.shop.domain}>
            <Typography variant="body2" component="span" style={{ cursor: 'pointer' }}>
              <div className="shop">
                {reservation.shop.title || reservation.shop.domain}
              </div>
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body2" component="span" style={{ cursor: 'pointer' }}>
            <div>...</div>
          </Typography>
        );

        const slice = (
          <div
            className={`shop_slider ${reservation ? '' : 'empty'}`}
            key={i}
            onClick={() => this.handleClick(reservation, categoryToDisplay.key)}
            aria-hidden="true"
            style={{ cursor: 'pointer' }}
          >
            <Typography variant="body2" component="span" style={{ cursor: 'pointer' }}>
              <div style={{ padding: '0px 3px' }}>
                <Tooltip title={categoryToDisplay.label}>
                  <img className="category_img" src={`${imgs[categoryToDisplay.img]}`} alt={categoryToDisplay.label} />
                </Tooltip>
              </div>
            </Typography>
            {contentSlice}
          </div>
        );

        slices.push(slice);
      }
    }
    return (
      <>
        <Card className="child" style={{ backgroundColor: nbDayOfMonth === '' ? 'lightgrey' : '' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              {nbDayOfMonth.toString()}
            </Typography>
            {slices}
          </CardContent>
        </Card>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create reservation"
        >
          <DialogTitle>Créer une réservation</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
              validate={this.validateValues}
            >
              <TextInput disabled source="category" label="Categorie" initialValue={category} fullWidth />
              <Autocomplete
                defaultValue={dialogShop}
                id="autocomplete-shop"
                style={{ width: 'auto', marginBottom: '20px' }}
                open={open}
                onOpen={() => { this.setOpen(true); }}
                onClose={() => { this.setOpen(false); }}
                getOptionLabel={this.optionRenderer}
                options={options}
                loading={loading}
                onInputChange={this.searchShops}
                onChange={this.changeShop}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Shops"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <DateInput source="startDate" label="Valable du" initialValue={startDate} fullWidth />
              <DateInput source="endDate" label="Valable au" initialValue={endDate} fullWidth />
              <NumberInput source="fixedCosts" label="Frais fixes" initialValue={fixedCosts} fullWidth />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            {isUpdate
              && deletePermission
              && (
                <Button label="Remove" color="default" onClick={this.handleRemoveClick} style={{ color: 'red', marginRight: 'auto' }}>
                  <ActionDelete />
                </Button>
              )}

            <Button
              label={isUpdate ? 'Update' : 'Save'}
              color="primary"
              disabled={dialogSaveButtonDisabled}
              onClick={this.handleSaveClick}
            />
            <Button label="ra.action.cancel" color="secondary" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
        {errorMessages && (
          <Dialog
            fullWidth
            open={showDialogError}
            onClose={this.handleCloseDialogErrorClick}
            aria-label="Error reservation"
          >
            <DialogTitle>Erreur lors de la création de la réservation </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Attention :
              </DialogContentText>
              <DialogContentText>
                {errorMessages.full}
              </DialogContentText>
              <DialogContentText>
                {errorMessages.sameShop}
              </DialogContentText>
              <DialogContentText>
                {errorMessages.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button label="Ok" color="primary" onClick={this.handleCloseDialogErrorClick} />
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

DayCardComponent.propTypes = {
  nbDayOfMonth: PropTypes.string,
  stateDay: PropTypes.object,
  country: PropTypes.string,
  countryId: PropTypes.string,
  startDate: PropTypes.object,
  reload: PropTypes.func,
  permissions: PropTypes.object,
  category: PropTypes.string,
};

export default DayCardComponent;
