import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-admin';

import Checked from '@material-ui/icons/CheckBoxOutlined';
import UnChecked from '@material-ui/icons/CheckBoxOutlineBlank';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Tooltip } from '@material-ui/core';
import { PropTypes } from '../../../tools/types';

function IdField({ basePath, record }) {
  return <Link to={`${basePath}/${record.id}/show`}>{record.id}</Link>;
}

IdField.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
};

function ShowLink({
  basePath, record, source, label, title, ...props
}) {
  const path = get(record, source);
  const titleValue = get(record, title);
  return <Link to={`/${basePath}/${path}/show`} {...props}>{titleValue || label}</Link>;
}

ShowLink.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
};

function StatusIcon({ record, source }) {
  const value = get(record, source);
  const props = { style: { fontSize: '20px' } };
  return value ? <Checked {...props} /> : <UnChecked {...props} />;
}

StatusIcon.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

function ErrorIcon() {
  const props = { style: { fontSize: '20px' } };
  return <ErrorOutline {...props} />;
}

function TooltipField({
  title, placement, children, record,
}) {
  return (
<Tooltip title={title} placement={placement}>
    { React.cloneElement(children, { record }) }
</Tooltip>
  );
}

TooltipField.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  record: PropTypes.object,
};

export {
  IdField, ShowLink, StatusIcon, ErrorIcon, TooltipField,
};
