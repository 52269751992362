import React from 'react';
import {
  ReferenceManyField, Datagrid, TextField, EditButton, CreateButton, TopToolbar,
} from 'react-admin';
import { PropTypes } from '../../tools/types';

/**
 * BlacklistList
 */
function BlacklistList(props) {
  const { shopId, permissions, ...ownProps } = props;

  return (
    <>
      <TopToolbar>
        {permissions && permissions.blacklists.write && (
          <CreateButton
            to={{
              pathname: '/blacklists/create',
              search: `?shopId=${shopId}`,
            }}
          />
        )}
      </TopToolbar>
      <ReferenceManyField {...ownProps} addLabel={false} reference="blacklists" target="shopId">
        <Datagrid>
          <TextField source="id" sortable={false} label="ID" />
          <TextField source="comment" sortable={false} label="Comment" />
          <TextField source="idVoucher" sortable={false} label="ID Voucher" />
          <TextField source="codeVoucher" sortable={false} label="Code Voucher" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

BlacklistList.propTypes = {
  data: PropTypes.object,
  shopId: PropTypes.string,
  permissions: PropTypes.object,
};

export default BlacklistList;
