import React from 'react';
import {
  Create, SimpleForm, TextInput, NumberInput, SelectInput, required,
} from 'react-admin';
import { ActionsTypes } from '../../tools/types';

const redirect = (basePath, id, data) => `/programs/${data.programId}/show/share-policy`;

function SharePolicyCreate(props) {
  const params = new URLSearchParams(props.location.search);
  const programId = params.get('programId');
  const initialValues = { programId };

  return (
    <Create title="Ajouter un taux de reversement" {...props}>
      <SimpleForm variant="standard" initialValues={initialValues} redirect={redirect}>
        <TextInput disabled source="programId" label="Identifiant du programme" />
        <TextInput source="reason" label="Commentaire" validate={required()} />
        <NumberInput source="value" label="Valeur" validate={required()} />
        <SelectInput
          source="type"
          label="Type de valeur"
          validate={required()}
          choices={[
            { id: 'FLAT', name: 'Absolue' },
            { id: 'PERCENTAGE', name: 'Pourcentage' },
          ]}
        />
        <NumberInput source="maxValue" label="Valeur maximale" validate={required()} />
        <SelectInput
          source="maxType"
          label="Type de valeur maximale"
          validate={required()}
          choices={[
            { id: 'FLAT', name: 'Absolue' },
            { id: 'PERCENTAGE', name: 'Pourcentage' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
}

SharePolicyCreate.propTypes = ActionsTypes.Show;

export default SharePolicyCreate;
