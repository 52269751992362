/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Edit, Toolbar, SimpleForm, TopToolbar, ListButton, ShowButton, TextField, DateField,
} from 'react-admin';
import { Button } from '@material-ui/core';

import requester from '../../tools/requester';
import config from '../../config';
import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';

import './Payment.css';

const FormWrapper = ({ children, ...props }) => children(props);

/**
 * PaymentEdit
 */
class PaymentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowUpdate: true,
      updateLoading: false,
    };

    this.endpoint = config.services.backend.endpoint;
  }

  /**
   * Update bll transactions
   */
  onPaymentValidation = async () => {
    const { id } = this.props;

    this.setState({ updateLoading: true }, async () => {
      try {
        await requester(`${this.endpoint}/payments/${id}`, { method: 'put' });
        this.setState({ allowUpdate: false, updateLoading: false });
      } catch (err) {
        this.setState({ error: err.message, updateLoading: false });
      }
    });
  }

  render() {
    const { allowUpdate, updateLoading } = this.state;
    return (
      <Edit actions={<PaymentActions />} title={<LayoutTitle source="id" />} {...this.props}>
        <FormWrapper>
          { (props) => (
            <SimpleForm
              variant="standard"
              toolbar={<PaymentToolbar record={props.record} allowUpdate={allowUpdate} loading={updateLoading} action={this.onPaymentValidation} />}
              redirect="show"
              {...props}
            >
              <TextField source="id" />
              <DateField source="created" showTime locales="fr-FR" label="Date de création" />
              <TextField source="createdBy" label="Paiement créé par" />
              <DateField source="validatedAt" showTime locales="fr-FR" label="Date de validation" />
              <TextField id="oihdezhfez" source="validatedBy" label="Paiement validé par" />
              <TextField source="method" label="Méthode de création" />
              <TextField source="finishedAt" label="Date de fin de la validation (processus terminé)" />
              <TextField source="errorMessage" label="Message d'erreur (si une erreur est apparue lors du payment)" />
            </SimpleForm>
          )}
        </FormWrapper>
      </Edit>
    );
  }
}

PaymentEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

function PaymentToolbar({
  allowUpdate, action, record,
}) {
  return (
<Toolbar>
    {allowUpdate && (
      <Button disabled={record.validatedAt} onClick={action} color="secondary" variant="contained">
        { record.validatedAt ? (record.finishedAt ? 'Paiement déjà validé' : 'Paiement en cours de validation') : 'Valider le paiement' }
      </Button>
    )}
</Toolbar>
  );
}

PaymentToolbar.propTypes = {
  allowUpdate: PropTypes.bool,
  action: PropTypes.func.isRequired,
  record: PropTypes.object,
};

function PaymentActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
</TopToolbar>
  );
}

PaymentActions.propTypes = ActionsTypes.Edit;

export default PaymentEdit;
