const setCookie = (name, value, expires) => {
  const expiredAt = expires ? `expires=${expires};` : null;
  document.cookie = `${name}=${value};${expiredAt}path=/`;
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  const { length } = ca;
  let i = 0;

  for (; i < length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export {
  setCookie, getCookie,
};
