import React, { PureComponent } from 'react';

import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import moment from 'moment';

import requester from '../../../tools/requester';
import config from '../../../config';

import MEAFilter from '../Common/MEAFilter';
import DayCardComponent from './DayCard';

import './MEA_pack3.css';

class NotifCompetitorComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      showDialog: false,
      showDialogError: false,
      dialogSaveButtonDisabled: false,
      isPremium: false,
      options: [],
      loading: false,
      open: false,
    };
  }

  fetchData = async (date, country) => {
    const countryQueryString = country ? `&country=${country}` : '';
    const result = await requester(`${config.services.backend.endpoint}/mea-notifs?date=${date}${countryQueryString}`, {});
    return result.data;
  }

  reloadData = async (params) => {
    const country = params?.country || this.state.country;
    const monthYear = params?.monthYear || this.state.monthYear;

    const data = await this.fetchData(monthYear, country);
    this.setState({ data, ...params });
  }

  createDayCardComponents = () => {
    const components = [];
    const {
      monthYear,
      data,
      countryISO2A,
      country,
    } = this.state;
    if (monthYear) {
      const selectedMonthYear = moment(monthYear, 'MM-YYYY');
      let firstDay = selectedMonthYear.startOf('month').day() - 1;

      if (firstDay === -1) {
        // dimanche (0 - 1)
        firstDay = 6;
      }

      const nbDaysInMonth = selectedMonthYear.daysInMonth();
      let isFirstDay = false;
      let firstDayAlreadyPassed = false;
      let lastDayIsPassed = false;
      let nbDayOfMonth = '';
      const nbLoop = (firstDay > 5 && nbDayOfMonth === 31) || (firstDay > 6 && nbDayOfMonth === 30) ? 35 : 42;
      for (let i = 0; i < nbLoop; i++) {
        isFirstDay = firstDay === i;
        if (isFirstDay) {
          nbDayOfMonth = 1;
        }

        // eslint-disable-next-line no-loop-func
        const stateDay = data?.find((s) => nbDayOfMonth !== '' && moment(s.day).date() === nbDayOfMonth);
        const startDate = (nbDayOfMonth !== '' && !lastDayIsPassed) ? moment(selectedMonthYear).set('date', nbDayOfMonth) : null;
        components.push(<DayCardComponent
          {...this.props}
          nbDayOfMonth={nbDayOfMonth.toString()}
          stateDay={stateDay}
          startDate={startDate}
          key={i}
          country={countryISO2A}
          countryId={country}
          reload={this.reloadData}
        />);
        firstDayAlreadyPassed = !!(firstDayAlreadyPassed || isFirstDay);
        lastDayIsPassed = lastDayIsPassed || nbDayOfMonth === nbDaysInMonth;

        if (lastDayIsPassed) {
          nbDayOfMonth = '';
        } else if (firstDayAlreadyPassed) {
          nbDayOfMonth++;
        }
      }
    }
    return components;
  };

  createDayNameCardComponents = () => ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day) => (
    <div className="child-week" key={day}>
      <Card>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {day}
          </Typography>
        </CardContent>
      </Card>
    </div>
  ));

  render() {
    return (
      <>
        <MEAFilter {...this.props} onChange={this.reloadData} />
        <div className="pack3_parent" {...this.props}>
          {this.createDayNameCardComponents()}
          {this.createDayCardComponents()}
        </div>
      </>
    );
  }
}

export default NotifCompetitorComponent;
