import requester from '../../tools/requester';
import config from '../../config';

export default async function getCashbackHistoryData(idCashbackOffer) {
  const request = await requester(
    `${config.services.backend.endpoint}/programs/cashbacks/${idCashbackOffer}/history`,
    {},
  );
  return request.data;
}
