import React from 'react';
import {
  Show,
  ListButton,
  TopToolbar,
  EditButton,
  TabbedShowLayout, Tab, TextField, DateField, BooleanField,
  NumberField,
  FunctionField,
} from 'react-admin';

import { PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';
import config from '../../config';

const transformatedStatus = (id) => {
  const conf = config.claims.status;
  if (!id || !conf) { return; }
  // eslint-disable-next-line consistent-return
  return (conf.find((p) => id === p.id)).name;
};

const transformatedType = (id) => {
  const conf = config.claims.type;
  if (!id || !conf) { return; }
  // eslint-disable-next-line consistent-return
  return (conf.find((p) => id === p.id)).name;
};

function ClaimShow(props) {
  return (
<Show actions={<ClaimActions />} title={<LayoutTitle source="name" />} {...props}>
    <TabbedShowLayout>
      <Tab label="Résumé">
        <TextField source="id" label="id de la réclamation" />
        <TextField source="ticketNumber" label="Numéro de réclamation" />
        <TextField source="shop.domain" label="Domaine" />
        <FunctionField label="Statut de la réclamation" render={(record) => transformatedStatus(record.status)} />
        <FunctionField label="Type de la réclamation" render={(record) => transformatedType(record.type)} />
        <TextField source="user.email" label="Email du compte de l'utilisateur" />

        <TextField source="claimEmail" label="Email de contact" />
        <TextField source="orderEmail" label="Email utilisé lors de la commande" />
        <DateField source="orderDate" showTime locales="fr-FR" label="Date de la commande" />
        <DateField source="createdAt" showTime locales="fr-FR" label="Créée le :" />
        <DateField source="updatedAt" showTime locales="fr-FR" label="Mise à jour le :" />
        <NumberField source="amount" locales="fr-FR" label="Comm user (à payer)" />
        <TextField source="content" label="Commentaires - Détails de la réclamation" />
        <BooleanField source="attachmentsProvided" label="Facture fournie" />
        <BooleanField source="validate" label="Réclamation validée" />
      </Tab>
    </TabbedShowLayout>
</Show>
  );
}

function ClaimActions({ basePath, data }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
</TopToolbar>
  );
}

ClaimActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
};

export default ClaimShow;
