import React, { Fragment, Component } from 'react';
import { Button, crudDelete, startUndoable } from 'react-admin';
import { connect } from 'react-redux';

import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';

// import DialogPopup from './DialogPopup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PropTypes } from '../../tools/types';

class DeleteButtonWithConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpened: false,
      messageError: 'Une erreur obscure apparraît !',
    };
  }

  handleOpenClick = () => {
    this.setState({ dialogIsOpened: true });
  }

  handleCloseClick = () => {
    this.setState({ dialogIsOpened: false });
  }

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ dialogIsOpened: false });

    const {
      resource, record, basePath, redirect, undoable, dispatchStartUndoable,
    } = this.props;

    if (undoable) {
      dispatchStartUndoable(
        crudDelete(resource, record.id, record, basePath, redirect),
      );
    }
  }

  render() {
    const { label = 'ra.action.delete' } = this.props;
    const { dialogIsOpened } = this.state;

    return (
      <>
        <Button
          label={label}
          onClick={this.handleOpenClick}
          key="button"
        >
          <ActionDelete />
        </Button>
        <Dialog fullWidth open={dialogIsOpened} onClose={this.handleCloseClick} aria-label="Êtes-vous sûr(e) ?">
          <DialogTitle>Êtes-vous sûr(e) de vouloir supprimer cette entité ?</DialogTitle>
          <DialogContent>
            <div>Cette action est irréversible.</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelete} label="Je valide la suppression" key="button">
              <ActionDelete />
            </Button>
            <Button label="Annuler" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

DeleteButtonWithConfirmation.propTypes = {
  label: PropTypes.string,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  undoable: PropTypes.bool,
  dispatchStartUndoable: PropTypes.func,
};

export default connect(undefined, { dispatchStartUndoable: startUndoable })(DeleteButtonWithConfirmation);
