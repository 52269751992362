import React, { useState } from 'react';
import {
  DateInput,
  SimpleForm,
  useNotify,
  useRefresh,
  useQuery,
  AutocompleteArrayInput,
} from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import moment from 'moment';

import config from '../../config';
import requester from '../../tools/requester';
import { PropTypes } from '../../tools/types';

const useDomains = () => {
  const [selectedDomains, setSelectedDomains] = useState([]);

  const { data } = useQuery({
    type: 'getList',
    resource: 'inquiry-import-domains',
    payload: {
      pagination: { page: 1, perPage: 200 }, // perPage value may be a problem if too many domains
      sort: { field: 'domain', order: 'ASC' },
    },
  });
  const allDomains = data?.map((record) => ({
    id: record.id,
    name: record.domain,
  })) ?? [];

  return { allDomains, setSelectedDomains, selectedDomains };
};

function CreateInquiryImportDialog(props) {
  const notify = useNotify();
  const refresh = useRefresh();

  const initialStartDate = moment().format('YYYY-MM-DD').toString();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(null);
  const { selectedDomains, setSelectedDomains, allDomains } = useDomains();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSubmitButtonDisabled = !startDate
    || moment(endDate).isBefore(startDate)
    || moment(endDate).diff(moment(startDate), 'days') > 7;

  const onSubmit = async () => {
    if (!startDate) return;

    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      await requester(`${config.services.backend.endpoint}/inquiries-import`, {
        method: 'POST',
        options: {
          data: {
            startDate,
            endDate,
            domains: selectedDomains,
          },
        },
      });

      refresh();
      notify('Job d\'import créé', { type: 'success' });
      props.onDialogClosed();
    } catch (error) {
      notify(`${error.message}`, { type: 'error' });
    }

    setIsSubmitting(false);
  };

  return (
    <Dialog
      fullWidth
      open={props.isDialogOpened}
      onClose={props.onDialogClosed}
      aria-label="Créer un job d'import de réclamation depuis BigQuery"
    >
      <DialogTitle>
        Créer un job d&apos;import de réclamation depuis BigQuery
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={false}
          initialValues={{
            startDate: initialStartDate,
          }}
        >
          <DateInput
            label="Date de début"
            helperText="Date de début d'intervalle utilisée par BigQuery pour trouver les réclamations potentielles"
            source="startDate"
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            required
          />
          <DateInput
            label="Date de fin"
            helperText="Date de fin d'intervalle utilisée par BigQuery pour trouver les réclamations potentielles. Si non renseigné, la date de début sera utilisée.
              L'intervalle ne peut pas dépasser 7 jours."
            source="endDate"
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
          />
          <AutocompleteArrayInput
            source="domain"
            choices={allDomains}
            onChange={setSelectedDomains}
            helperText="Domaines à importer. Si non renseigné, tous les domaines seront utilisés."
          />
        </SimpleForm>
        <Box display="flex" justifyContent="flex-end" py={1}>
          <Button color="default" type="button" onClick={props.onDialogClosed}>
            Annuler
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmit}
          >
            Créer le job
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

CreateInquiryImportDialog.propTypes = {
  isDialogOpened: PropTypes.bool.isRequired,
  onDialogClosed: PropTypes.func.isRequired,
};

export default CreateInquiryImportDialog;
