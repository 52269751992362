import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import {
  SelectInput,
  Filter,
  DateInput,
} from 'react-admin';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

// Utils
import requester from '../../tools/requester';
import config from '../../config';

// Style
import './Reconciliation.css';

// Components
import TotalDetails from './TotalDetails';
import ShopDetails from './ShopDetails';
import { PropTypes } from '../../tools/types';

class BillReconciliation extends PureComponent {
  /**
   * Component constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  /**
   * Component mount hook
   */
  componentDidMount() {
    this.fetchNetworks();
  }

  /**
   * Toggles the loading state
   */
  toggleLoading = () => {
    this.setState(({ loading }) => ({
      loading: !loading,
    }));
  };

  /**
   * Fetch the network list
   *
   * @returns {Promise<T | never>}
   */
  fetchNetworks = () => {
    this.toggleLoading();

    return requester(`${config.services.backend.endpoint}/networks`, {
      options: {
        params: { _end: 999, _start: 0 },
      },
      timeout: 29000,
    }).then((response) => {
      this.setState({ networks: response.data, error: null });
    }).catch((err) => {
      this.setState({
        error: err.response && err.response.status === 500 ? err.response.data.toString() : err.message,
      });
    }).finally(this.toggleLoading);
  };

  fetchShopsSummary = async () => {
    this.toggleLoading();
    const {
      selectedNetwork, selectedCurrency, selectedDateType, dateStart, dateEnd,
    } = this.state;
    try {
      let storedState;

      if (!storedState) {
        const request = await requester(`${config.services.backend.endpoint}/network-shops-summary`, {
          options: {
            params: {
              networkId: selectedNetwork,
              currency: selectedCurrency,
              dateType: selectedDateType,
              dateStart,
              dateEnd,
              fromCache: 0,
              filters: {
                networkId: selectedNetwork,
              },
            },
            timeout: 59000,
          },
        });

        const shops = request.data;
        const structuredTransactions = { total: 0, shops: [], nbTransactions: 0 };
        let shopTmp = {};
        shops.forEach((shop) => {
          const { idShop: id, domain } = shop;

          shopTmp = structuredTransactions.shops.find((s) => s.id === id);
          if (!shopTmp) {
            shopTmp = {
              id,
              total: 0.0,
              dates: [],
              domain,
            };
          }

          shop.summary.forEach((date) => {
            const groupId = `${id}[${date.yearMonth}]`;
            const value = parseFloat(date.total);

            structuredTransactions.total += value;
            structuredTransactions.nbTransactions += date.nbTransactions;
            shopTmp.total += value;

            let dateTmp = shopTmp.dates.find((d) => d.id === groupId);
            if (!dateTmp) {
              dateTmp = {
                id: groupId,
                yearMonth: date.yearMonth,
                total: 0.0,
                nbTransactions: date.nbTransactions,
              };
            }
            dateTmp.total += value;
            shopTmp.dates.push(dateTmp);
          });

          structuredTransactions.shops.push(shopTmp);
        });

        const currency = shops[0] ? shops[0].currency : this.initialState().currency;
        this.setState({
          currency,
          structuredTransactions,
          error: null,
          nbTotal: structuredTransactions.total,
          nbTransactions: structuredTransactions.nbTransactions,
        });
      }
    } catch (error) {
      this.setState({
        error: error.response && error.response.status === 500 ? error.response.data.toString() : error.message,
      });
      console.log('fetchShopSummary error', error); // eslint-disable-line no-console
    } finally {
      this.toggleLoading();
    }
  }

  /**
   * Toggles all checkboxes
   *
   * @param flag - Determines if we want to check/uncheck the checkboxes
   */
  toggleAllTransactions = (event) => {
    const { checked } = event.target;
    this.setState((prevState) => ({ toggleAll: checked, reload: !prevState.reload }));
  };

  /**
   * Handles network change
   *
   * @param event
   */
  handleNetworkChange = (event) => {
    event.preventDefault();

    this.setState(() => ({
      structuredTransactions: null,
      selectedNetwork: event.target.value,
      nbSelected: 0,
      nbTotalSelected: 0.0,
      toggleAll: false,
      selectedTransactions: [],
      nbTransactions: 0,
    }));
  };

  /**
 * Handles network change
 *
 * @param event
 */
  handleCurrencyChange = (event) => {
    event.preventDefault();

    this.setState(() => ({
      structuredTransactions: null,
      selectedCurrency: event.target.value,
      nbSelected: 0,
      nbTotalSelected: 0.0,
      toggleAll: false,
      selectedTransactions: [],
      nbTransactions: 0,
    }));
  };

  handleDateTypeChange = (event) => {
    event.preventDefault();

    this.setState(() => ({
      structuredTransactions: null,
      selectedDateType: event.target.value,
      nbSelected: 0,
      nbTotalSelected: 0.0,
      toggleAll: false,
      selectedTransactions: [],
      nbTransactions: 0,
    }));
  };

  handleDateStartChange = (event) => {
    event.preventDefault();

    this.setState(() => ({
      structuredTransactions: null,
      dateStart: event.target.value,
      nbSelected: 0,
      nbTotalSelected: 0.0,
      toggleAll: false,
      selectedTransactions: [],
      nbTransactions: 0,
    }));
  };

  handleDateEndChange = (event) => {
    event.preventDefault();
    const dateEnd = new Date(event.target.value);
    dateEnd.setDate(dateEnd.getDate() + 1);
    this.setState(() => ({
      structuredTransactions: null,
      dateEnd: dateEnd.toISOString().split('T')[0],
      nbSelected: 0,
      nbTotalSelected: 0.0,
      toggleAll: false,
      selectedTransactions: [],
      nbTransactions: 0,
    }));
  };

  onFetchShopsSummary = (event) => {
    event.preventDefault();
    if (this.state.selectedNetwork) {
      this.fetchShopsSummary();
    }
  };

  /**
   * Clears bill redis cache
   */
  handleBillCacheClear = () => {
    if (this.state.loading) {
      return false;
    }
    this.toggleLoading();

    return requester(`${config.services.backend.endpoint}/bills/cache`, {
      method: 'DELETE',
    }).then(() => {
      this.setState({
        selectedNetwork: null,
        structuredTransactions: null,
        nbTransactions: 0,
        nbTotalSelected: 0.0,
        nbSelected: 0,
      });
    }).catch((err) => {
      this.setState({
        error: err.response && err.response.status === 500 ? err.response.data.toString() : err.message,
      });
    }).finally(this.toggleLoading);
  };

  /**
   * Component initial state
   *
   * @returns {*}
   */
  initialState = () => ({
    error: null,
    loading: false,
    networks: [],
    selectedNetwork: null,
    selectedCurrency: 'EUR',
    currencies: ['EUR', 'USD', 'CAD', 'GBP'],
    structuredTransactions: null,
    currency: 'EUR',
    nbTotalSelected: 0.0,
    nbSelected: 0,
    toggleAll: false,
    selectedTransactions: [],
    reload: false,
    nbTransactions: 0,
    dateType: ['validationDate', 'networkBillingDate'],
    selectedDateType: 'networkBillingDate',
  });

  onToggleTransactionsSelection = (transactions, shopId, dates) => {
    this.setState((state) => {
      let {
        selectedTransactions,
      } = state;
      const { structuredTransactions } = state;
      if (transactions) {
        transactions.forEach((transaction) => {
          if (transaction.selected) {
            if (!selectedTransactions.find((t) => t.id === transaction.id)) {
              selectedTransactions.push(transaction);
            }
          } else {
            selectedTransactions = selectedTransactions.filter(({ id }) => id !== transaction.id);
          }
        });
      }

      structuredTransactions.shops = structuredTransactions.shops.map((s) => {
        if (s.id === shopId) {
          return { ...s, dates };
        }
        return s;
      });

      const nbTotalSelected = selectedTransactions.reduce((previous, { value }) => previous + parseFloat(value), 0);

      return {
        nbSelected: selectedTransactions.length,
        nbTotalSelected,
        selectedTransactions,
        structuredTransactions,
      };
    });
  }

  /**
   * Component rendering
   *
   * @returns {*}
   */
  render() {
    const { match } = this.props;
    const {
      networks, selectedNetwork, selectedCurrency, loading, currency, error, structuredTransactions, selectedDateType, dateStart, dateEnd,
    } = this.state;

    const { create: errors } = config.errors.bill;

    return (
      <div id="bill-reconciliation" className="row center-xs middle-xs">
        {/* MAIN */}
        <div className="transaction-list">
          {/* NETWORK FORM */}
          <Card className="card-form">
            <CardContent>
              <FormControl fullWidth disabled={loading}>
                <InputLabel htmlFor="network">Networks</InputLabel>
                <Select
                  onChange={this.handleNetworkChange}
                  value={selectedNetwork || ''}
                  inputProps={{
                    name: 'selectedNetwork',
                    id: 'network',
                  }}
                >
                  {networks && networks.map((network) => (
                    <MenuItem key={network.id} value={network.id}>{network.name}</MenuItem>
                  ))}
                </Select>
                <Filter {...this.props} setFilters={() => { }}>
                  <SelectInput
                    onChange={this.handleCurrencyChange}
                    source="selectedCurrency"
                    choices={this.state.currencies.map((curr) => ({ id: curr, name: curr }))}
                    initialValue="EUR"
                    alwaysOn
                    allowEmpty={false}
                  />
                </Filter>
                <Filter {...this.props} setFilters={() => { }}>
                  <SelectInput
                    onChange={this.handleDateTypeChange}
                    source="selectedDateType"
                    choices={this.state.dateType.map((dt) => ({ id: dt, name: dt }))}
                    initialValue="networkBillingDate"
                    alwaysOn
                    allowEmpty={false}
                  />
                  <DateInput
                    isRequired={false}
                    onChange={this.handleDateStartChange}
                    parse={(v) => (v ? (moment(v)).toISOString() : v)}
                    source="dateStart"
                    label="Du"
                    alwaysOn
                  />
                  <DateInput
                    isRequired={false}
                    onChange={this.handleDateEndChange}
                    parse={(v) => (v ? moment(v).endOf('day').toISOString() : v)}
                    source="dateEnd"
                    label="Au"
                    alwaysOn
                  />
                </Filter>
                <Button onClick={this.onFetchShopsSummary} disabled={!this.state.selectedNetwork} color="primary">Récupérer</Button>
              </FormControl>
            </CardContent>
          </Card>
          {/* On error */}
          {error && (
            <Card>
              <CardContent>
                <Typography color="error" variant="subheading">{`Error: ${error}`}</Typography>
              </CardContent>
            </Card>
          )}
          {/* TRANSACTIONS LIST */}
          {!error && (
            <>
              {selectedNetwork && this.state.nbTransactions === 0 && (
                <Card>
                  <CardContent>
                    {loading && (
                      <Typography>Récupération des transactions...</Typography>
                    )}
                    {(!loading) && (
                      <Typography>{errors.noTransactions}</Typography>
                    )}
                  </CardContent>
                </Card>
              )}

              {/* TRANSACTION GROUP LIST */}
              {!loading && !!structuredTransactions && structuredTransactions.shops && Object.values(structuredTransactions.shops).map((shop) => (
                <ShopDetails
                  networkId={selectedNetwork}
                  selectedCurrency={selectedCurrency}
                  selectedDateType={selectedDateType}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  shop={shop}
                  currency={currency}
                  key={shop.id}
                  toggleTransactionSelectionEvent={this.onToggleTransactionsSelection}
                  onToggleAllChanged={this.state.toggleAll}
                  reload={this.state.reload}
                  disableCheckbox={false}
                />
              ))}
            </>
          )}
        </div>
        {/* SUMARY CARD */}
        <div className="transaction-result">
          <TotalDetails
            match={match}
            networkId={selectedNetwork}
            selectedCurrency={selectedCurrency}
            selectedDateType={selectedDateType}
            dateStart={dateStart}
            dateEnd={dateEnd}
            handleBillCacheClear={this.handleBillCacheClear}
            toggleAllTransactions={this.toggleAllTransactions}
            nbTotal={this.state.nbTotal}
            selectedTotal={this.state.nbTotalSelected}
            nbTransactions={this.state.nbTransactions}
            nbSelected={this.state.nbSelected}
            selectedTransactionIds={this.state.selectedTransactions.map((t) => t.id)}
            currency={currency}
          />
        </div>
      </div>
    );
  }
}

BillReconciliation.propTypes = {
  match: PropTypes.object,
};

export default BillReconciliation;
