import React from 'react';
import {
  Edit, SimpleForm, TopToolbar, ListButton, SelectInput, DateField, Toolbar, SaveButton, TextInput, NumberInput, number, minValue,
} from 'react-admin';

import { PropTypes } from '../../tools/types';

function CashbackToolbar({ shopId, ...props }) {
  const redirect = shopId ? `/shops/${shopId}/show/cashbacks` : `/programs/${props.record.program.id}/show/cashbacks`;

  return (
    <Toolbar {...props}>
      <SaveButton {...props} redirect={redirect} />
    </Toolbar>
  );
}

CashbackToolbar.propTypes = {
  shopId: PropTypes.string,
  record: PropTypes.object,
};

function CashbackEdit(props) {
  const searchParams = new URLSearchParams(props.location.search);
  const shopId = searchParams.get('shop');

  return (
    <Edit title="Modifier une offre de cashback" {...props} actions={<CashbackEditActions shopId={shopId} />}>
      <SimpleForm variant="standard" toolbar={<CashbackToolbar shopId={shopId} />}>
        <TextInput disabled source="id" label="Identifiant" />
        <TextInput disabled source="program.name" label="Nom du programme" />
        <TextInput disabled source="programSubId" />
        <SelectInput
          source="status"
          choices={[
            { id: 'ACTIVE', name: 'Activé' },
            { id: 'INACTIVE', name: 'Désactivé' },
          ]}
        />
        <NumberInput source="priority" label="Priorité" validate={[number(), minValue(0)]} />
        <TextInput disabled source="type" label="Type" />
        <TextInput disabled source="amount" label="Montant" />
        <TextInput disabled source="currency" label="Devise" />
        <TextInput disabled source="threshold" />
        <DateField source="created" showTime locales="fr-FR" label="Date de création" />
        <DateField source="updated" showTime locales="fr-FR" label="Dernière modification" />
      </SimpleForm>
    </Edit>
  );
}

CashbackEdit.propTypes = {
  location: PropTypes.object,
};

function CashbackEditActions({ data, shopId }) {
  if (!data) return null;

  const redirect = shopId ? `/shops/${shopId}/show/cashbacks` : `/programs/${data.program.id}/show/cashbacks`;

  return (
    <TopToolbar>
      <ListButton to={redirect} />
    </TopToolbar>
  );
}

CashbackEditActions.propTypes = {
  shopId: PropTypes.string,
  data: PropTypes.object,
};

export default CashbackEdit;
