import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
  ListContextProvider, Datagrid, TextField, DateField, TopToolbar, EditButton,
} from 'react-admin';
import keyBy from 'lodash/keyBy';

import requester from '../../../tools/requester';
import config from '../../../config';
import MEAExtensionCreateEditDialog from './CreateEditDialog';

function MEAExtensionComponent() {
  const [isDisplayDialog, setDisplayDialog] = useState(false);
  const [meaExtensions, setmeaExtensions] = useState([]);
  const [meaExtensionToEdit, setMeaExtensionToEdit] = useState();

  async function loadData() {
    const result = await requester(`${config.services.backend.endpoint}/mea-extension`, { method: 'GET' });
    setmeaExtensions(result.data);
  }

  useEffect(loadData, []);

  const handleCreateButton = () => {
    setMeaExtensionToEdit(undefined);
    setDisplayDialog(true);
  };

  const closeDialogAction = async () => {
    await loadData();
    setDisplayDialog(false);
  };

  const openEditDialog = (event, meaExtension) => {
    event.preventDefault();
    setMeaExtensionToEdit(meaExtension);
    setDisplayDialog(true);
  };

  // eslint-disable-next-line react/prop-types
  function MEAExtensionEditButton({ record }) {
    return <EditButton record={record} onClick={(event) => openEditDialog(event, record)} />;
  }

  return (
    <>
      <TopToolbar>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateButton}
        >
          Create
        </Button>
      </TopToolbar>
      <ListContextProvider
        value={{
          data: keyBy(meaExtensions, 'id'),
          ids: meaExtensions.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          basePath: '/mea-extension',
          resource: 'mea-extension',
          selectedIds: [],
        }}
        pagination={false}
      >
        <Datagrid>
          <TextField source="shopFrom.domain" label="Depuis" />
          <TextField source="shopTo.domain" label="Vers" />
          <DateField source="startDate" label="Date de début" />
          <DateField source="endDate" label="Date de fin" />
          <TextField source="fixedCosts" label="Frais fixes" />
          <TextField source="comment" label="Commentaire" />
          <MEAExtensionEditButton />
        </Datagrid>
      </ListContextProvider>

      <MEAExtensionCreateEditDialog isDisplayDialog={isDisplayDialog} closeDialog={closeDialogAction} initialValues={meaExtensionToEdit} />
    </>
  );
}

export default MEAExtensionComponent;
