import moment from 'moment';

export const getCalendarValues = (monthYear) => {
  const selectedMonthYear = moment(monthYear, 'MM-YYYY');
  const nbDaysInMonth = selectedMonthYear.daysInMonth();

  const offset = selectedMonthYear.startOf('month').day() === 0
    ? 6
    : selectedMonthYear.startOf('month').day() - 1; // 0 - 6 monday - sunday
  const firstCell = offset + 1;
  const lastCell = nbDaysInMonth + offset;

  const numberOfCells = lastCell > 35 ? 42 : 35;

  const getNumberOfMonth = (i) => (i >= firstCell && i <= lastCell ? i - offset : null);

  return {
    numberOfCells, getNumberOfMonth,
  };
};
