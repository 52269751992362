import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,

  BooleanInput,
  DateInput,

  SelectInput,
  NumberInput,
  BooleanField,
  TopToolbar,
  DeleteButton,
  RefreshButton,
  ListButton,
  required,
} from 'react-admin';

import config from '../../config';
import { ActionsTypes } from '../../tools/types';

const redirect = (_basePath, _id, data) => `/shops/${data.shopId}/show/vouchers`;

function VoucherEdit(props) {
  const choices = config.vouchers.type;

  return (
    <Edit {...props} actions={<VoucherEditActions />}>
      <SimpleForm variant="standard" redirect={redirect}>
        <TextInput disabled source="id" label="Identifiant" />
        <TextInput disabled source="code" label="Code" />
        <TextInput source="title" label="Titre" />
        <TextInput multiline source="description" label="Conditions" />
        <TextInput multiline source="descScope" label="Scope du coupon" />
        <TextInput multiline source="descExclusions" label="Liste d'exclusion" />
        <BooleanInput source="descFirstBuy" label="Afficher la description pour le premier achat uniquement" fullWidth />
        <BooleanField source="memberOnly" label="Réservé aux membres" />
        <DateInput source="validFrom" label="Valide à partir du" />
        <DateInput source="validTo" label="Valide jusqu'au" validate={required()} />
        <TextInput disabled source="shopId" label="Boutique associée" />
        <SelectInput source="type" label="Type de coupon" choices={choices} />
        <BooleanInput source="exclusive" label="Le coupon est exclusif" />
        <BooleanInput source="isRecommended" label="Le coupon est recommandé" fullWidth />
        <NumberInput source="amount" label="Montant du coupon" />
        <SelectInput source="currency" label="Devise" choices={config.currencies} disabled />
        <NumberInput source="minimum" label="Minimum d'achat" />
        <TextInput disabled source="name" label="Nom du site source" />
      </SimpleForm>
    </Edit>
  );
}

function VoucherEditActions({ data, resource }) {
  if (!data) return null;

  const listPath = `/shops/${data.shopId}/show/vouchers`;

  return (
    <TopToolbar>
      <ListButton to={listPath} />
      <DeleteButton resource={resource} record={data} basePath={listPath} />
      <RefreshButton />
    </TopToolbar>
  );
}

VoucherEditActions.propTypes = ActionsTypes.Edit;

export default VoucherEdit;
