import jsonServerProvider from 'ra-data-json-server';
import config from './config';
import requester from './tools/requester';
import { convertFileToBase64 } from './helpers';

const httpClient = (url, customOptions = {}) => {
  const options = { ...customOptions };

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('access_token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return requester(url, { options, method: options.method });
};

const formatWithMissingIdProp = (result, sourceProp) => ({
  data: {
    ...result.data,
    id: result.data[sourceProp],
  },
});

const dataProvider = jsonServerProvider(config.services.backend.endpoint, httpClient);
const myDataProvider = {
  ...dataProvider,
  update: async (resource, params) => {
    const updatedData = {};

    // Handle file upload from file edition
    if (resource === 'bills' && params.data?.billFile?.rawFile instanceof File) {
      updatedData.billFile = await convertFileToBase64(
        params.data.billFile.rawFile,
      );
    }

    const result = await dataProvider.update(resource, {
      ...params,
      data: {
        ...params.data,
        ...updatedData,
      },
    });

    if (resource === 'inquiry-import-domains') {
      return formatWithMissingIdProp(result, 'domain');
    }

    return result;
  },
  getList: async (resource, params) => {
    const result = await dataProvider.getList(resource, params);

    if (resource === 'inquiry-import-domains') {
      return {
        ...result,
        data: result.data.map((item) => ({
          ...item,
          id: item.domain,
        })),
      };
    }

    return result;
  },
  getOne: async (resource, params) => {
    const result = await dataProvider.getOne(resource, params);

    if (resource === 'inquiry-import-domains') {
      return formatWithMissingIdProp(result, 'domain');
    }

    return result;
  },
};

export default myDataProvider;
