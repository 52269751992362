import { jwtDecode } from 'jwt-decode';

import config from '../config';
import requester from './requester';
import TokenManager from './tokenManager';

const login = ({ username: email, password }) => {
  const { endpoint } = config.services.backend;
  return requester(`${endpoint}/auth`, { method: 'post', options: { data: { email, password } } })
    .then(async ({ data }) => {
      const { accessToken } = data;
      localStorage.setItem('access_token', accessToken);
      await TokenManager.checkAccessToken();
      return data;
    }).catch((err) => {
      throw new Error(err.statusText);
    });
};

const checkError = (error) => {
  const { status } = error.response;
  if (status === 401 || status === 403) {
    localStorage.removeItem('access_token');
    return Promise.reject();
  }
  return Promise.resolve();
};

const getPermissions = () => {
  try {
    const jwtToken = TokenManager.get();
    const decodedToken = jwtDecode(jwtToken);
    return decodedToken.permissions
      ? Promise.resolve(decodedToken.permissions)
      : Promise.reject();
  } catch (err) {
    return Promise.reject();
  }
};

const checkAuth = () => (localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject());

const logout = () => {
  localStorage.removeItem('access_token');
  return Promise.resolve();
};

export default {
  login,
  checkError,
  checkAuth,
  getPermissions,
  logout,
};
