import React from 'react';
import {
  TabbedForm,
  FormTab,
  TextInput,
  useNotify,
  useRefresh,
  useRedirect,
  Create,
} from 'react-admin';

import { LayoutTitle } from '../Shared/Layout';
import { PropTypes } from '../../tools/types';

function CashbackAutodisplayPatternCreate(props) {
  const idShop = (new URLSearchParams(props.location.search)).get('idShop');
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = async ({ data }) => {
    notify('Element created');
    redirect(`/shops/${data.idShop}/show/cashback-autodisplay-patterns`, '/shops', data.idShop, {}, { loaded: true });
    refresh();
  };

  return (
  <Create
    onSuccess={onSuccess}
    mutationMode="pessimistic"
    title={<LayoutTitle source="name" />}
    {...props}
  >
    <TabbedForm redirect="show">
      <FormTab variant="standard" label="Résumé">
        <TextInput disabled source="idShop" label="Id du shop" initialValue={idShop} />
        <TextInput fullWidth source="pattern" label="Pattern" />
      </FormTab>
    </TabbedForm>
  </Create>
  );
}

CashbackAutodisplayPatternCreate.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CashbackAutodisplayPatternCreate;
