import React, { useEffect } from 'react';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Controller, useForm } from 'react-hook-form';
import { useNotify } from 'react-admin';
import requester from '../../tools/requester';
import config from '../../config';
import { PropTypes } from '../../tools/types';

function ExtensionBannerForm({ bannerToEdit, isOpen, onClose }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(bannerToEdit ?? {
      text: '',
      hoverText: '',
      link: '',
      name: '',
      type: 'comparator',
    });
  }, [bannerToEdit]);

  const notify = useNotify();

  const onSubmit = handleSubmit(async (data) => {
    const { creationDate, ...dataToSend } = data;
    try {
      if (!bannerToEdit) {
        await requester(`${config.services.backend.endpoint}/extension-banner/`, {
          method: 'POST',
          options: {
            data: dataToSend,
          },
        });
      } else {
        await requester(`${config.services.backend.endpoint}/extension-banner/${bannerToEdit.id}`, {
          method: 'PUT',
          options: {
            data: dataToSend,
          },
        });
      }

      onClose(true);
    } catch (error) {
      notify(`${error.message}`, 'error');
    }
  });

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <DialogTitle>
        {bannerToEdit ? 'Modifier une banière ' : 'Créer une banière'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    multiline
                    label="Nom"
                    error={!!errors.name}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="text"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    multiline
                    label="Texte"
                    error={!!errors.text}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="hoverText"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    multiline
                    label="Texte hover"
                    error={!!errors.hoverText}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="link"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    multiline
                    label="Lien"
                    error={!!errors.link}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    value="comparator"
                    error={!!errors.type}
                    {...field}
                  >
                    <MenuItem value="comparator">Comparateur</MenuItem>
                    <MenuItem value="cashback">Cashback</MenuItem>
                    <MenuItem value="voucher">Voucher</MenuItem>
                    <MenuItem value="marketing">Marketing</MenuItem>
                    <MenuItem value="community">Community</MenuItem>
                    <MenuItem value="otaComparator">Comparateur OTA</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
                disabled={!isValid}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

ExtensionBannerForm.propTypes = {
  bannerToEdit: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ExtensionBannerForm;
