import PropTypes from 'prop-types';
import React from 'react';
import Flag from 'react-world-flags';

import { supportedCountries } from '../../../tools/country';

import './customFlag.css';

function CustomFlag({ isoCode }) {
  return (
<div className="CustomFlag">
    <Flag className="country-flag" code={isoCode} height="32" width="48" />
    <span>{supportedCountries[isoCode]}</span>
</div>
  );
}

CustomFlag.propTypes = {
  isoCode: PropTypes.string.isRequired,
};

export default CustomFlag;
