import axios from 'axios';

import TokenManager from './tokenManager';
import { setCookie } from './cookies';

export default function httpRequest(url, { method = 'get', options = {} }) {
  let data;
  let headers = { Authorization: TokenManager.get() };

  if (options.headers) {
    headers = { ...options.headers, ...headers };
  }

  if (typeof options.body === 'string' && options.body && options.body.length > 0) {
    data = JSON.parse(options.body);
  }

  return axios({
    ...options,
    data: data || options.data,
    headers,
    method: method.toLowerCase(),
    url,
  })
    .then((res) => {
      if (res.data.jwtToken) {
        setCookie('uid', res.data.id);
        TokenManager.set(res.data.jwtToken);

        return httpRequest(url, { method, options });
      }

      return {
        data: res.data,
        headers: new Headers(res.headers),
        json: res.data,
      };
    })
    .catch((res) => {
      if (res.status === 401) {
        setCookie('uid', null);
        TokenManager.clear();
        window.location = '/';
      }
      throw res;
    });
}
