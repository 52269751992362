import React from 'react';
import {
  List, Datagrid, DateField, TextField, EditButton, TopToolbar,
  FunctionField,
  usePermissions,
} from 'react-admin';
import ClaimListIcon from '@material-ui/icons/SentimentDissatisfied';

// Custom stuff
import { IdField } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import config from '../../config';

const transformatedStatus = (id) => {
  const conf = config.claims.status;
  if (!id || !conf) { return; }
  // eslint-disable-next-line consistent-return
  return (conf.find((p) => id === p.id)).name;
};

function ClaimList(props) {
  const { permissions } = usePermissions();

  return (
  <List
    {...props}
    actions={<ClaimActions />}
    perPage={15}
    pagination={<SimplePagination />}
  >
    <Datagrid>
      <IdField label="id de la réclamation" />
      <TextField source="user.firstname" sortable={false} label="Prénom" />
      <TextField source="user.lastname" sortable={false} label="Nom" />
      <TextField source="shop.domain" sortable={false} label="Shop" />
      <FunctionField label="Statut" sortable={false} render={(record) => transformatedStatus(record.status)} />
      <TextField source="validatedBy.email" sortable={false} label="Validée par" />
      <DateField source="createdAt" label="Créé le" showTime locales="fr-FR" sortable={false} />
      <DateField source="updatedAt" label="Mis à jour le" showTime locales="fr-FR" sortable={false} />
      {permissions && permissions.claims && permissions.claims.write
        ? <EditButton />
        : null}
    </Datagrid>
  </List>
  );
}

function ClaimActions() {
  return <TopToolbar />;
}

export { ClaimList, ClaimListIcon };
