/* eslint-disable max-classes-per-file */
export class ClaimError extends Error {
  constructor({ message, transactionId, claimId }) {
    super(message);
    this.transactionId = transactionId;
    this.claimId = claimId;
  }
}

export class ClaimHandler {
    claimsEndpoint;

    requester;

    constructor({ claimsEndpoint, requester }) {
      this.claimsEndpoint = claimsEndpoint;
      this.requester = requester;
    }

    async createAndValidateMultipleClaims(transactions) {
      const creationAttempts = await this.createMultipleClaims(transactions);
      const createdClaims = creationAttempts
        .filter((attempt) => attempt.status === 'fulfilled')
        .map((attempt) => attempt.value.data);

      const validationAttempts = await this.validateMultipleClaims(createdClaims);

      return {
        validatedClaims: validationAttempts
          .filter((attempt) => attempt.status === 'fulfilled')
          .map((attempt) => attempt.value.data),
        failedClaimCreations: creationAttempts
          .filter((attempt) => attempt.status === 'rejected')
          .map((attempt) => ({
            transactionId: attempt.reason.transactionId,
            reason: attempt.reason.message,
          })),
        failedClaimValidations: validationAttempts
          .filter((attempt) => attempt.status === 'rejected')
          .map((attempt) => ({
            transactionId: attempt.reason.transactionId,
            claimId: attempt.reason.claimId,
            reason: attempt.reason.message,
          })),
      };
    }

    async createMultipleClaims(transactions) {
      return Promise.allSettled(
        transactions
          .map(
            (transaction) => this.requester(`${this.claimsEndpoint}`, { method: 'POST', options: { data: transaction } })
              .catch((error) => Promise.reject(new ClaimError({ message: error.message, transactionId: transaction.idTransaction }))),
          ),
      );
    }

    async validateMultipleClaims(claims) {
      return Promise.allSettled(
        claims.map(
          (claim) => this.requester(`${this.claimsEndpoint}/${claim.id}`, { method: 'PUT', options: { data: { ...claim, validate: true } } })
            .catch((error) => Promise.reject(new ClaimError({ transactionId: claim.transaction.id, claimId: claim.id, message: error.message }))),
        ),
      );
    }
}
