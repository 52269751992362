import React from 'react';
import { Checkbox, AccordionDetails, Typography } from '@material-ui/core';
import { PropTypes } from '../../tools/types';

class TransactionDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      transaction: null,
    };
  }

  componentDidMount() {
    this.setState({ transaction: this.props.transaction });
  }

  onTransactionToggleChanged = (e) => {
    this.props.toggleTransactionSelectionEvent({
      ...this.state.transaction,
      selected: e.target.checked,
    }, e?.nativeEvent?.shiftKey);
  }

  render() {
    const { transaction } = this.state;
    if (!transaction) return '';

    const { checked } = this.props;

    return (
      <AccordionDetails className="container last">
        {transaction && (
          <div className="form-group">
            <div className="left">
              <Typography className="bold">{transaction.dateString}</Typography>
              <Typography style={{ marginLeft: '30px' }}>
                {'Date: '}
                {transaction.transactionDateString}
              </Typography>
            </div>
            <div className="right">
              <Typography style={{ marginRight: '30px' }}>
                {'Validation: '}
                {transaction.validationDateString}
              </Typography>
              <Typography style={{ width: '50px', textAlign: 'right' }}>{transaction.value.toFixed(2)}</Typography>
              {!this.props.disableCheckbox && (
                <Checkbox
                  className="selected-transaction"
                  checked={checked}
                  onChange={(e) => this.onTransactionToggleChanged(e)}
                />
              )}
            </div>
          </div>
        )}
      </AccordionDetails>
    );
  }
}

TransactionDetails.propTypes = {
  transaction: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  toggleTransactionSelectionEvent: PropTypes.func.isRequired,
  disableCheckbox: PropTypes.bool,
};

export default TransactionDetails;
