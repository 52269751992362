import moment from 'moment';

export const getMonthYears = () => {
  const startYear = 2024;
  const endYear = moment().year() + 5;
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const monthYears = [];
  for (let i = startYear; i < endYear; i++) {
    monthYears.push(
      ...months.map((m) => ({ id: `${m}-${i}`, name: `${m}-${i}` })),
    );
  }

  return monthYears;
};
