import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Toolbar from '@material-ui/core/Toolbar';

import { useListContext } from 'react-admin';
import { OtherTypes } from '../../../tools/types';

function SimplePagination() {
  const {
    page, perPage, total, setPage,
  } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  const previousBtn = (
    <Button key="prev" onClick={() => setPage(page - 1)}>
      <ChevronLeft />
      Prev
    </Button>
  );
  const nextBtn = (
    <Button key="next" onClick={() => setPage(page + 1)}>
      Next
      <ChevronRight />
    </Button>
  );
  const toolbar = (
    <Toolbar>
      {page > 1 && previousBtn}
      {page !== nbPages && nextBtn}
    </Toolbar>
  );

  return nbPages > 1 && toolbar;
}

SimplePagination.propTypes = OtherTypes.Pagination;

export default SimplePagination;
