import PropTypes from 'prop-types';

import {
  EditActions, ListActions, ShowActions, Title, Pagination, ExportButton,
} from 'react-admin';

/* eslint-disable react/forbid-foreign-prop-types */
const ActionsTypes = {
  Edit: EditActions.propTypes,
  List: ListActions.propTypes,
  Show: ShowActions.propTypes,
};

const OtherTypes = {
  Pagination: Pagination.propTypes,
  TitleForRecord: Title.propTypes,
  ExportButton: ExportButton.propTypes,
};
/* eslint-enable react/forbid-foreign-prop-types */

const Transaction = {
  id: PropTypes.string,
  dateString: PropTypes.string,
  value: PropTypes.number,
};

const DateTransaction = {
  date: PropTypes.string,
  dateString: PropTypes.string,
  groupId: PropTypes.string,
  total: PropTypes.number,
  transactions: PropTypes.arrayOf(PropTypes.shape(Transaction)),
};

const InternalTypes = {
  DateTransaction,
  Transaction,
};

export {
  ActionsTypes,
  InternalTypes,
  PropTypes,
  OtherTypes,
};
