import React from 'react';
import {
  Card, Grid, Typography, CardContent,
} from '@material-ui/core';
import NetworkPrograms from './NetworkPrograms';
import { PropTypes } from '../../tools/types';

function Dashboard(props) {
  const { permissions } = props;

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          {permissions && permissions.dashboard['networks-programs'].read && (
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Programmes affectés aux shops &#8220;network&#8221;</Typography>
                <NetworkPrograms {...props} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

Dashboard.propTypes = {
  permissions: PropTypes.object,
};
export default Dashboard;
