const categories = [
  {
    country: 'FR',
    categories: [
      { label: 'Achats Entreprise', key: 'entreprise', img: 'paperclip' },
      { label: 'Alimentation et Vins', key: 'alimentation-vins', img: 'hamburger' },
      { label: 'Autos, Motos et Scooters', key: 'autos-motos', img: 'car' },
      { label: 'Banques, Assurances, et Crédits', key: 'banques-assurances', img: 'bankNote' },
      { label: 'Beauté et Bien-être', key: 'beaute-bienetre', img: 'lipstick' },
      { label: 'Cadeaux', key: 'cadeaux', img: 'gift' },
      { label: 'Enfant', key: 'enfant', img: 'babyBottle' },
      { label: 'High-Tech et Electroménager', key: 'high-tech-electromenager', img: 'headphone' },
      { label: 'Loisirs et Culture', key: 'loisirs-culture', img: 'book' },
      { label: 'Marketplaces & Concept Stores', key: 'marketplaces', img: 'shopping' },
      { label: 'Maison et Jardin', key: 'maison-jardin', img: 'house' },
      { label: 'Mode & Accessoires', key: 'mode-accessoires', img: 'dress' },
      { label: 'Restauration', key: 'restauration', img: 'forkKnife' },
      { label: 'Sport', key: 'sport', img: 'basketBalloon' },
      { label: 'Voyages et Locations', key: 'voyages-et-locations', img: 'island' },
    ],
  },
  {
    country: 'ES',
    categories: [
      { label: 'Profesionales', key: 'profesionales', img: 'paperclip' },
      { label: 'Gastronomía & Bebidas', key: 'gastronomia-bebidas', img: 'hamburger' },
      { label: 'Coches & Motos', key: 'coches-motos', img: 'car' },
      { label: 'Finanzas & Seguros', key: 'finanzas-seguros', img: 'bankNote' },
      { label: 'Belleza & Bienestar', key: 'belleza-bienestar', img: 'lipstick' },
      { label: 'Regalos', key: 'regalos', img: 'gift' },
      { label: 'Niños', key: 'ninos', img: 'babyBottle' },
      { label: 'Tecnología & Electrodomésticos', key: 'tecnologia-electrodomesticos', img: 'headphone' },
      { label: 'Ocios & Entretenimiento', key: 'ocios-entretenimiento', img: 'book' },
      { label: 'Marketplaces & ConceptStore', key: 'marketplaces', img: 'shopping' },
      { label: 'Casa & Jardín', key: 'casa-jardin', img: 'house' },
      { label: 'Moda & Accesorios', key: 'moda-accesorios', img: 'dress' },
      { label: 'Restaurantes', key: 'comida', img: 'forkKnife' },
      { label: 'Deportes', key: 'deportes', img: 'basketBalloon' },
      { label: 'Viajes & Vacaciones', key: 'viajes-vacaciones', img: 'island' },
    ],
  },
  {
    country: 'GB',
    categories: [
      { label: 'Corporate Purchasing', key: 'corporate-purchasing', img: 'paperclip' },
      { label: 'Food & beverages', key: 'food-and-beverages', img: 'hamburger' },
      { label: 'Cars, Motorcycles & Scooters', key: 'cars-motorcycles-and-scooters', img: 'car' },
      { label: 'Banking & Insurance', key: 'banking-and-insurance', img: 'bankNote' },
      { label: 'Beauty & well-being', key: 'beauty-and-well-being-', img: 'lipstick' },
      { label: 'Gifts', key: 'gifts', img: 'gift' },
      { label: 'Children', key: 'children', img: 'babyBottle' },
      { label: 'High Tech & Domestic Appliances', key: 'high-tech-and-domestic-appliances', img: 'headphone' },
      { label: 'Leisure & Entertainment', key: 'leisure--entertainment', img: 'book' },
      { label: 'Marketplaces & Concept Stores', key: 'marketplaces', img: 'shopping' },
      { label: 'House & Garden', key: 'home--garden', img: 'house' },
      { label: 'Fashion & accessories', key: 'fashion-and-accessories', img: 'dress' },
      { label: 'Catering', key: 'catering', img: 'forkKnife' },
      { label: 'Sports', key: 'sports', img: 'basketBalloon' },
      { label: 'Travel & Rentals', key: 'travel-and-rentals', img: 'island' },
    ],
  },
];

export default categories;
