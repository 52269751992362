const config = {
  features: {},
  services: {
    backend: {
      endpoint: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8025',
    },
    prismic: {
      shopSearchUrl: process.env.REACT_APP_PRISMIC_URL || 'https://wanteeed.prismic.io/documents/working~k=shop&l=fr-fr&w=',
    },
    boCashback: {
      url: process.env.REACT_APP_CASHBACK_BO_URL,
    },
  },
  errors: {
    bill: {
      create: {
        noTransactions: 'Aucune transactions trouvées pour ce network',
        valueDiff: 'Erreur serveur, différence de total détecté entre l\'affichage et le calcul',
      },
    },
    payment: {
      create: {
        noTransactions: 'Aucune transactions disponibles pour créer un paiement, vérifier les informations bancaires des utilisateurs concernés',
      },
    },
  },
  currencies: [
    { id: 'EUR', name: 'Euros' },
    { id: 'USD', name: 'Dollars' },
    { id: 'GBP', name: 'Livres' },
    { id: 'CAD', name: 'Dollars Canadien' },
  ],
  vouchers: {
    type: [
      {
        id: 'FLAT',
        name: 'FLAT',
      },
      {
        id: 'PERCENTAGE',
        name: 'PERCENTAGE',
      },
      {
        id: 'GIFT',
        name: 'GIFT',
      },
      {
        id: 'DELIVERY',
        name: 'DELIVERY',
      },
    ],
  },
  claims: {
    type: [
      { id: 'WAIT_NOT_VALID', name: 'Bonus en attente - non validé' },
      { id: 'WAIT_VALID', name: 'Bonus en attente - validé' },
      { id: 'VALIDATE_DECLINED', name: 'Validation de cashback refusé par le marchand' },
      // { id: 'REFUSED', name: 'Bonus refusé' },
      // { id: 'NOT_TRACKED', name: 'Bonus non tracké' },
    ],
    status: [
      { id: 'CREATED', name: 'Créée' },
      { id: 'RESOLVED', name: 'Résolue' },
      { id: 'IN PROGRESS', name: 'En cours' },
      { id: 'REFUSED', name: 'Refusée' },
    ],
  },
};

export default config;
