import React from 'react';
import {
  ReferenceManyField, Datagrid, TextField, FunctionField, DateField, EditButton,
} from 'react-admin';

import HistoryComponent from './historyComponent';

import { PropTypes } from '../../tools/types';

const renderAmount = (record) => `${record.amount} ${record.type === 'FLAT' ? record.currency : '%'}`;

function EditCashbackOfferButton({ id, basePath, record }) {
  let path = `/programs/cashbacks/${record.id}`;

  if (basePath === '/shops') {
    path += `?shop=${id}`;
  }

  return <EditButton to={path} />;
}

EditCashbackOfferButton.propTypes = {
  id: PropTypes.string,
  basePath: PropTypes.string,
  record: PropTypes.object,
};

/**
 * CashbackList
 */
class CashbackList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { children, ...ownProps } = this.props;
    return (
      <ReferenceManyField {...ownProps} addLabel={false} target="programId">
        <Datagrid
          expand={(
            <HistoryComponent props={ownProps} />
          )}
        >
          <TextField source="programSubId" sortable={false} label="ID" />
          <TextField source="status" sortable={false} label="Status" />
          <TextField source="priority" sortable={false} label="Priorité" />
          <FunctionField render={renderAmount} label="Montant" />
          <TextField source="threshold" sortable={false} label="Threshold" />
          <DateField source="created" sortable={false} label="Création" />
          <EditCashbackOfferButton {...this.props} />
        </Datagrid>
      </ReferenceManyField>
    );
  }
}

CashbackList.propTypes = {
  children: PropTypes.func,
};

export default CashbackList;
