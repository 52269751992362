export const COUNTRY_CODES = {
  FR: 'FR',
  GB: 'GB',
};

export const COUNTRIES = {
  [COUNTRY_CODES.FR]: {
    id: '74bc22e0-d5bb-494e-b910-ad5491c8570f',
    urlFragment: 'fr',
  },
  [COUNTRY_CODES.GB]: {
    id: 'f3448423-fc58-486e-86bc-9167d2d0bc46',
    urlFragment: 'en',
  },
};
