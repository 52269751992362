import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { TextField } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/Visibility';

const styles = (theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
});

class SimpleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button size="small" color="primary" onClick={this.handleOpen}>
          <ShowIcon style={{ fontSize: '14px' }} />
          {' Show'}
        </Button>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.paper}>
            <Typography variant="subtitle1" id="modal-title">Selectors</Typography>
            <JSONField {...this.props} fullWidth label="Selecteurs" />
            <Button color="default" onClick={this.handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

function JSONField({ record }) {
  const json = JSON.stringify(record.selectors, null, 2);
  return <TextField value={json} readOnly multiline fullWidth />;
}

JSONField.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

JSONField.propTypes = {
  record: PropTypes.object.isRequired,
};

const ConfigShowModal = withStyles(styles)(SimpleModal);

export default ConfigShowModal;
