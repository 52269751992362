import { WithPermissions } from 'react-admin';
import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { PropTypes } from '../../tools/types';

function ClearCacheButton(props) {
  const { match, clearCacheAvailable, handleBillCacheClear } = props;
  return (
    <WithPermissions
      authParams={{ key: match.path }}
      render={({ permissions }) => {
        if (permissions && permissions.bills.cache.delete) {
          return (
            <Button
              style={{ marginTop: '1rem' }}
              variant="contained"
              color="primary"
              disabled={!clearCacheAvailable}
              onClick={handleBillCacheClear}
            >
              Vider le cache
            </Button>
          );
        }
        return null;
      }}
    />
  );
}

ClearCacheButton.propTypes = {
  clearCacheAvailable: PropTypes.bool,
  handleBillCacheClear: PropTypes.func,
  match: PropTypes.object,
};

export default ClearCacheButton;
