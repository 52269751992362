import React from 'react';
import {
  Show,
  ListButton,
  TopToolbar,
  EditButton,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
  usePermissions,
} from 'react-admin';

import { PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';

function InquiryShow(props) {
  return (
<Show actions={<InquiryActions />} title={<LayoutTitle source="name" />} {...props}>
    <TabbedShowLayout>
      <Tab label="Résumé">
        <TextField source="id" label="Id de la réclamation" />
        <DateField source="dateCreated" label="Date de création" showTime locales="fr-FR" />
        <TextField source="status" />
        <TextField source="idOrder" label="Numéro de commande" />
        <DateField source="dateOrder" label="Date de commande" showTime locales="fr-FR" />
        <FunctionField render={(record) => `${record.orderValue} €`} label="Valeur commande" />
        <FunctionField render={(record) => `${record.cashbackValue} €`} label="Commission" />
        <FunctionField render={(record) => `${record.cashbackUserValue} €`} label="Commission utilisateur" />
        <BooleanField source="uploaded" label="Envoyée à Strackr" />
        <TextField source="type" />
        <TextField source="error" />
      </Tab>
    </TabbedShowLayout>
</Show>
  );
}

function InquiryActions({ basePath, data }) {
  const { permissions } = usePermissions();
  const hasEditbutton = permissions?.inquiries?.write && !data?.uploaded;

  return (
    <TopToolbar>
    <ListButton basePath={basePath} />
    {hasEditbutton
      ? <EditButton basePath={basePath} record={data} />
      : null}
    </TopToolbar>
  );
}

InquiryActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
};

export default InquiryShow;
