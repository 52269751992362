import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TopToolbar,
  ExportButton,
  TextInput,
  TextField,
  ShowButton,
  FunctionField,
} from 'react-admin';

import ProgramListIcon from '@material-ui/icons/AttachMoney';

// Custom stuff
import { OtherTypes } from '../../tools/types';
import { IdField, ShowLink } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';

const renderStatus = (record) => {
  const field = <TextField source="status" record={{ ...record }} addLabel={false} />;

  return !record.manualDeactivation ? field : <s>{field}</s>;
};

/**
 * ProgramList
 */
function ProgramList(props) {
  return (
<List
  actions={<ProgramActions />}
  filters={<ProgramListFilters />}
  perPage={20}
  pagination={<SimplePagination />}
  {...props}
>
    <Datagrid>
      <IdField label="id" sortable={false} />
      <TextField source="name" sortable={false} label="Nom" />

      <TextField source="networkInternalId" sortable={false} />
      <TextField source="network.name" label="Network" sortable={false} />

      <TextField source="type" sortable={false} />
      <TextField source="priority" label="Priorité" sortable={false} />

      <ShowLink basePath="shops" label="shop" title="shop.domain" source="shop.id" />

      <FunctionField label="Status" render={renderStatus} />

      <ShowButton />
    </Datagrid>
</List>
  );
}

function ProgramListFilters(props) {
  return (
<Filter variant="standard" {...props}>
    <TextInput label="Nom" source="q" alwaysOn />
</Filter>
  );
}

function ProgramActions({
  resource, exporter, filterValues, currentSort,
}) {
  return (
<TopToolbar>
    <ExportButton
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
</TopToolbar>
  );
}

ProgramActions.propTypes = OtherTypes.ExportButton;

export { ProgramList, ProgramListIcon };
