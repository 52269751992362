import React from 'react';
import { ExpandMore } from '@material-ui/icons';
import {
  Checkbox, Accordion, AccordionSummary, Typography,
} from '@material-ui/core';
import DateDetails from './DateDetails';

// Utils
import { PropTypes } from '../../tools/types';
import * as reconciliationService from './reconciliationService';

class ShopDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      totalSelected: 0,
      nbSelected: 0,
      dates: [],
      refreshDateList: false,
      nbTransactions: 0,
    };
  }

  componentDidMount() {
    const { dates } = this.props.shop;
    const datesTmp = dates;
    const nbTransactions = dates.map((d) => d.nbTransactions).reduce((acc, val) => acc + val, 0);

    const transactionsSelected = dates.filter((d) => d.transactions).flatMap((d) => d.transactions).filter((t) => t.selected);
    const nbSelected = transactionsSelected.length;
    const totalSelected = transactionsSelected.map((t) => t.value).reduce((acc, value) => acc + value, 0.0).toFixed(2);

    this.setState({
      dates: datesTmp,
      nbTransactions,
      nbSelected,
      totalSelected,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onToggleAllChanged === this.props.onToggleAllChanged && prevProps.reload === this.props.reload) {
      return;
    }

    this.onCheckedAllTransactionsChange({ target: { checked: this.props.onToggleAllChanged } }, true);
  }

  onTransactionsLoaded = (date) => {
    this.setState((prevState) => ({ dates: prevState.dates.map((d) => (d.id === date.id ? date : d)) }));
  }

  onTransactionsChanged = (transactions) => {
    const { dates } = this.state;
    const newDates = dates.map((d) => {
      if (d.transactions) {
        return {
          ...d,
          transactions: d.transactions.map((dt) => {
            const transaction = transactions.find((t) => d.id === t.groupId && dt.id === t.id);

            return transaction || dt;
          }),
        };
      }

      return d;
    });

    const totalSelected = newDates
      .filter((d) => d.transactions)
      .flatMap((d) => d.transactions)
      .filter((t) => t.selected)
      .reduce((previous, { value }) => previous + parseFloat(value), 0);

    const nbSelected = newDates
      .filter((d) => d.transactions)
      .flatMap((d) => d.transactions)
      .filter((t) => t.selected)
      .length;

    this.setState({
      refreshDateList: true,
      dates: newDates,
      nbSelected,
      totalSelected: totalSelected.toFixed(2),
    }, () => this.props.toggleTransactionSelectionEvent(transactions, this.props.shop.id, this.state.dates));
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  }

  displaySubTotal = () => {
    const {
      shop,
      currency,
      disableCheckbox,
    } = this.props;
    const { totalSelected } = this.state;
    const part = disableCheckbox ? '' : `${totalSelected} / `;
    return `${part}${shop.total.toFixed(2)} ${currency}`;
  }

  /**
  * Event fired when date checkbox change
  * @param event
  */
  onCheckedAllTransactionsChange = async (event) => {
    const { checked } = event.target;
    const { dates } = this.state;
    let datesTmp = JSON.parse(JSON.stringify(dates));
    const {
      networkId,
      shop,
      currency,
      selectedDateType,
      dateStart,
      dateEnd,
    } = this.props;

    if (checked && !this._isAllTransactionsAlreadyLoaded(datesTmp)) {
      const monthsToQuery = datesTmp.filter((d) => !d.transactions || d.transactions.length === 0).map((d) => d.yearMonth);
      const transactionsByMonth = await Promise.all(monthsToQuery.map((month) => reconciliationService.getTransactionsData(networkId, shop.id, month, currency, selectedDateType, dateStart, dateEnd)));
      transactionsByMonth.forEach((transactions) => {
        const date = datesTmp.find((m) => m.yearMonth === transactions[0].yearMonth);
        date.transactions = transactions;
      });
    }

    const updatedTransactions = [];
    datesTmp = datesTmp.map((date) => {
      let transactionsSelectionChanged = [];
      if (date.transactions) {
        transactionsSelectionChanged = date.transactions.filter((t) => t.selected !== checked).map((t) => t.id);
        const dateTmp = { ...date, transactions: date.transactions.map((t) => ({ ...t, selected: checked })) };
        updatedTransactions.push(...dateTmp.transactions.filter((t) => transactionsSelectionChanged.includes(t.id)));
        return dateTmp;
      }
      return date;
    });

    this.setState((state) => ({
      refreshDateList: !state.refreshDateList,
      dates: datesTmp,
    }), () => this.onTransactionsChanged(updatedTransactions));
  }

  _isAllTransactionsAlreadyLoaded = (dates) => {
    let allTransactionsAlreadyLoaded = true;
    dates.forEach((date) => {
      if (!date.transactions || date.transactions.length === 0) {
        allTransactionsAlreadyLoaded = false;
      }
    });
    return allTransactionsAlreadyLoaded;
  }

  render() {
    const {
      shop,
      currency,
      networkId,
      disableCheckbox,
    } = this.props;
    const {
      expanded,
      nbSelected,
      dates,
      nbTransactions,
    } = this.state;
    const checked = nbSelected > 0;

    const indeterminate = nbSelected > 0 && nbSelected !== nbTransactions;

    return (
      <Accordion className="group" onChange={this.toggleExpanded}>
        <AccordionSummary className="panel-summary" expandIcon={<ExpandMore />}>
          <div className="panel-name">
            <Typography className="bold">{shop.domain}</Typography>
          </div>
          <div className="panel-checkbox">
            <Typography className="bold">
              {this.displaySubTotal()}
            </Typography>
            {!disableCheckbox && (
              <Checkbox
                className="parent-checkbox top selected-shop"
                checked={checked}
                indeterminate={indeterminate}
                onChange={this.onCheckedAllTransactionsChange}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div>
        </AccordionSummary>

        {expanded && Object.values(dates).map((date) => (
          <DateDetails
            networkId={networkId}
            shopId={shop.id}
            date={date}
            currency={currency}
            selectedDateType={this.props.selectedDateType}
            dateStart={this.props.dateStart}
            dateEnd={this.props.dateEnd}
            key={date.id}
            reload={this.state.refreshDateList}
            onTransactionsChanged={this.onTransactionsChanged}
            onTransactionsLoaded={this.onTransactionsLoaded}
            disableCheckbox={disableCheckbox}
          />
        ))}
      </Accordion>
    );
  }
}

ShopDetails.propTypes = {
  shop: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  networkId: PropTypes.string,
  selectedDateType: PropTypes.string,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  toggleTransactionSelectionEvent: PropTypes.func,
  onToggleAllChanged: PropTypes.bool,
  reload: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
};

export default ShopDetails;
