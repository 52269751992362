// LoginPage.js
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  main: { backgroundImage: 'url(/img/bg_login.jpg) !important' },
});

function CustomLoginPage(props) {
  return (
<Login
  loginForm={<LoginForm />}
  {...props}
/>
  );
}

export default withStyles(styles)(CustomLoginPage);
