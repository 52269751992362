import EmailCampaignIcon from '@material-ui/icons/Email';
import React, { useState } from 'react';
import {
  BooleanInput,
  Button,
  FileField,
  FileInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';

import './Email.css';
import config from '../../config';
import requester from '../../tools/requester';

const types = [
  { id: 'promotional', name: 'promotionnel' },
  { id: 'partners', name: 'partenaires' },
  { id: 'transactional', name: 'transactionnelles ' },
];
const countries = [
  { id: 'fr', name: 'France' },
  { id: 'gb', name: 'Angleterre' },
  { id: 'us', name: 'USA' },
];

function EmailCampaignView() {
  const [selectedType, setSelectedType] = useState(types[0]);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [validatedForm, setValidatedForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csv, setCsv] = useState();
  const [onlyVerifiedEmail, setOnlyVerifiedEmail] = useState(false);
  const [listTitle, setListTitle] = useState('');
  const notify = useNotify();

  const onChangeSelection = (event, elements, setter) => {
    const selectedElTmp = elements.find((exp) => exp.id === event.target.value);
    setter(selectedElTmp);
  };

  const validateValues = (values) => {
    if (!values.title || values?.emailList?.rawFile?.type !== 'text/csv') {
      return setValidatedForm(false);
    }
    return setValidatedForm(true);
  };

  const getEmails = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('emailType', selectedType.id);
    formData.append('country', selectedCountry.id);
    formData.append('onlyVerifiedEmail', true);
    formData.append('file', csv);
    formData.append('title', listTitle);

    requester(`${config.services.backend.endpoint}/marketing/get-emails`, {
      method: 'POST',
      headers: {
        'content-Type': 'multipart/form-data',
      },
      options: {
        data: formData,
      },
    })
      .then((response) => {
        notify(`la liste avec l'id ${response?.json?.data} a été crée`, 'success');
      })
      .catch(() => {
        notify('Erreur lors de la création de la liste', 'error');
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SimpleForm
      toolbar={null}
      validate={validateValues}
      className="emailContainer"
    >

      <TextInput onChange={(event) => setListTitle(event.target.value)} source="title" label="Nom de la liste mailjet" />
      <SelectInput
        label="Type"
        source="selectedType"
        initialValue={selectedType?.id}
        choices={types}
        onChange={(event) => onChangeSelection(event, types, setSelectedType)}
      />
      <SelectInput
        label="Pays"
        source="selectedCountry"
        initialValue={selectedCountry?.id}
        choices={countries}
        onChange={(event) => onChangeSelection(event, countries, setSelectedCountry)}
      />
     <FileInput source="liste d'ids" name="emailList" onChange={setCsv} accept=".csv">
      <FileField source="csv" title="title" />
     </FileInput>

      <BooleanInput initialValue={false} checked={onlyVerifiedEmail} label="Emails vérifiés" source="verifiedEmail" onChange={setOnlyVerifiedEmail} />

      <Button
        disabled={isLoading || !validatedForm}
        variant="contained"
        color="primary"
        size="small"
        onClick={getEmails}
        label="Générer la liste"
      />
    </SimpleForm>
  );
}

export { EmailCampaignIcon, EmailCampaignView };
