import React, { PureComponent } from 'react';
import {
  List, TopToolbar, CreateButton, ExportButton, Datagrid, DateField, TextField, EditButton, Filter, TextInput,
} from 'react-admin';
import BonusPatternListIcon from '@material-ui/icons/CardGiftcard';
import Button from '@material-ui/core/Button';

// Custom stuff
import { IdField } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import { PropTypes, OtherTypes } from '../../tools/types';
import requester from '../../tools/requester';
import config from '../../config';

import './BonusPattern.css';

class BonusPatternList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  /**
   * Component mount hook
   */
  componentDidMount() {
    this.verifyCacheValidity();

    // in case of delete, the request is sent after the component is mounted (4-5s) so we need to fetch that again, just in case
    setTimeout(() => {
      this.verifyCacheValidity();
    }, 5100);
  }

  /**
   * The initial state of the state (🤷🏼‍)
   * Also, this is a cat 🐈
   */
  initialState = () => ({
    cacheAlert: null,
    cacheAlertClass: null,
  });

  /**
   * Fetch last updates from backend
   * Verify if cache is up to date by comparing cache update date and config update date (date date date 🤷)
   */
  verifyCacheValidity = async () => {
    this.setState({ cacheAlertClass: 'loading', cacheAlert: 'Vérification de la validité du cache...' });

    return requester(`${config.services.backend.endpoint}/bonus-patterns/update`, {
      timeout: 29000,
    }).then(({ data }) => {
      const cacheLastUpdated = new Date(data.cacheLastUpdated);
      const configLastUpdated = new Date(data.configLastUpdated);

      if (!data.configLastUpdated) {
        this.setState({ cacheAlertClass: 'alert', cacheAlert: 'Impossible de récupérer la date de dernière modification de la configuration. Le cache pourrait ne pas être à jour.' });
      } else if (!data.cacheLastUpdated || cacheLastUpdated < configLastUpdated) {
        this.setState({ cacheAlertClass: 'alert', cacheAlert: 'Le cache n\'est pas à jour' });
      } else {
        this.setState({ cacheAlertClass: 'success', cacheAlert: 'Le cache est à jour' });
      }
    }).catch((err) => {
      this.setState({ cacheAlertClass: 'alert', cacheAlert: `Le status du cache n'a pas pu être récupéré (${err.message || err})` });
    });
  }

  /**
   * Refresh cache
   */
  refreshCache = async () => {
    this.setState({ cacheAlertClass: 'loading', cacheAlert: 'Mise à jour du cache...' });
    try {
      await requester(`${config.services.backend.endpoint}/bonus-patterns/update`, { method: 'POST' });
      await this.verifyCacheValidity();
      if (!this.cacheAlert) {
        this.setState({ cacheAlertClass: 'success', cacheAlert: 'Le cache a été mis à jour' });
      }
    } catch (err) {
      this.setState({ cacheAlertClass: 'alert', cacheAlert: `Impossible de mettre le cache à jour (${err.message || err})` });
    }
  }

  render() {
    let icon;
    switch (this.state.cacheAlertClass) {
      case 'alert':
        icon = <span role="img" aria-label="alert">🚨</span>;
        break;
      case 'loading':
        icon = <span role="img" aria-label="loading">🔄</span>;
        break;
      case 'success':
        icon = <span role="img" aria-label="success">✅</span>;
        break;
      default:
        icon = null;
        break;
    }
    return (
      <div>
        {this.state.cacheAlert && (
          <blockquote className={this.state.cacheAlertClass}>
            {icon}
            {' '}
            {this.state.cacheAlert}
          </blockquote>
        )}
        <List
          actions={
            <BonusPatternListActions refreshCache={this.refreshCache} permissions={this.props.permissions ? this.props.permissions['bonus-patterns'] : {}} />
          }
          filters={<BonusPatternListFilters />}
          {...this.props}
          perPage={15}
          pagination={<SimplePagination />}
        >
          <Datagrid>
            <IdField label="id" />
            <TextField source="pattern" sortable={false} label="Pattern" />
            <DateField source="createdAt" label="Créé le" showTime locales="fr-FR" sortable={false} />
            <DateField source="updatedAt" label="Mis à jour le" showTime locales="fr-FR" sortable={false} />
            {this.props.permissions && this.props.permissions['bonus-patterns'] && this.props.permissions['bonus-patterns'].write ? <EditButton /> : null}
          </Datagrid>
        </List>
      </div>
    );
  }
}

function BonusPatternListFilters(props) {
  return (
<Filter variant="standard" {...props}>
    <TextInput label="Pattern" source="q" alwaysOn />
</Filter>
  );
}

function BonusPatternListActions({
  resource, exporter, filterValues, currentSort, permissions, refreshCache,
}) {
  return (
<TopToolbar>
      <Button onClick={refreshCache}>Forcer le rafraîchissement</Button>
      {permissions && permissions.write && <CreateButton to="/bonus-patterns/create" />}
      <ExportButton
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
</TopToolbar>
  );
}

BonusPatternList.propTypes = {
  permissions: PropTypes.object,
};

BonusPatternListActions.propTypes = OtherTypes.ExportButton;

export { BonusPatternList, BonusPatternListIcon };
