import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import { PropTypes } from '../../tools/types';
import getCashbackHistoryData from './cashbackService';
import './Cashback.css';

const renderAmount = (record) => `${record.amount} ${record.type === 'FLAT' ? record.currency : '%'}`;

function HistoryComponent(props) {
  const [history, setHistory] = useState([]);

  useEffect(async () => {
    const result = await getCashbackHistoryData(props.id);
    result.shift();
    setHistory(result);
  }, [props.id]);

  return (
    <div>
      <div className="cashback-history-container">
        <h4>Date de création</h4>
        <h4>Montant</h4>
        <h4>Threshold</h4>
      </div>
      {history && history.length > 0 ? history.map((h) => (
        <div className="cashback-history-container">
          <div>{moment(h.historyCreation).format('LLLL')}</div>
          <div>
            {renderAmount({ amount: h.amount, type: h.type, currency: h.currency })}
          </div>
          <div>{h.threshold}</div>
        </div>
      )) : <div className="no-history">pas d&apos;historique</div>}
    </div>
  );
}
HistoryComponent.propTypes = {
  id: PropTypes.string,
};

export default HistoryComponent;
