import { Card, CardContent, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import ExtensionBannerIcon from '@material-ui/icons/AssignmentInd';
import config from '../../config';
import requester from '../../tools/requester';
import ExtensionBannerForm from './extensionBannerForm';

function ExtensionBannerList() {
  const [banners, setBanners] = useState([]);
  const [offset, setOffet] = useState(0);
  const [limit, setLimit] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [bannerToEdit, setBannerToEdit] = useState(null);

  const notify = useNotify();

  const getData = useCallback(() => {
    setLoading(true);
    (
      async () => {
        const response = await requester(`${config.services.backend.endpoint}/extension-banner?_start=${offset}&_end=${offset + limit}`, {
          method: 'GET',
        });

        setBanners(response.data);
        setLoading(false);
      }
    )();
  }, [offset, limit]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onAddClick = () => {
    setIsFormOpen(true);
  };

  const onEditClick = (row, e) => {
    e.stopPropagation();
    setIsFormOpen(true);
    setBannerToEdit(row);
  };

  const onDeleteClick = async ({ id }, e) => {
    e.stopPropagation();

    if (window.confirm('Supprimer la banière ?')) { // eslint-disable-line
      try {
        await requester(`${config.services.backend.endpoint}/extension-banner/${id}`, {
          method: 'DELETE',
        });
        setBanners((prevBanners) => prevBanners.filter((b) => b.id !== id));
      } catch (error) {
        notify(`${error?.response?.data || error.message}`, 'error');
      }
    }
  };

  const onFormClose = (reload) => {
    setIsFormOpen(false);
    setBannerToEdit(null);

    if (reload) {
      getData();
    }
  };

  const getActionButtons = ({ row }) => (
      <>
        <IconButton onClick={(e) => onEditClick(row, e)} aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton onClick={(e) => onDeleteClick(row, e)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </>

  );

  const colums = [
    {
      field: 'name', headerName: 'Nom', sortable: false, flex: 0.7,
    },
    {
      field: 'text', headerName: 'Texte', sortable: false, flex: 1,
    },
    {
      field: 'hoverText', headerName: 'Texte hover', sortable: false, flex: 1,
    },
    {
      field: 'link', headerName: 'Lien', sortable: false, flex: 1,
    },
    {
      field: 'type', headerName: 'Type', sortable: false, flex: 0.5,
    },
    {
      field: 'creationDate',
      headerName: 'Création',
      sortable: false,
      flex: 0.5,
      renderCell: ({ value }) => moment(value).format('DD-MM-YYYY HH:mm'),
    },
    {
      field: 'actions', headerName: 'Actions', renderCell: getActionButtons, flex: 0.5,
    },
  ];

  const onPageChange = ({ page }) => {
    setOffet(page * limit);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setLimit(pageSize);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <IconButton onClick={onAddClick} aria-label="delete">
                <AddIcon />
                Ajouter
              </IconButton>
            </Grid>
            <Grid item>
              <DataGrid
                autoHeight
                loading={isLoading}
                disableColumnMenu
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                columns={colums}
                rows={banners}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ExtensionBannerForm isOpen={isFormOpen} onClose={onFormClose} bannerToEdit={bannerToEdit} />
    </>
  );
}

export { ExtensionBannerList, ExtensionBannerIcon };
