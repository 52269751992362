import React, { useState } from 'react';
import InquiryListIcon from '@material-ui/icons/AssignmentInd';
import IconFileCopy from '@material-ui/icons/FileCopy';

import {
  Button,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  TextInput,
  TextField,
  List,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import {
  IdField, StatusIcon,
} from '../Shared/Layout';
import { ActionsTypes } from '../../tools/types';

import CreateInquiryDialog from './CreateInquiryDialog';

function InquiryListFilters(props) {
  return (
<Filter variant="standard" {...props}>
    <TextInput label="User ID" source="q" alwaysOn fullWidth />
</Filter>
  );
}

function ListActions({ permissions, openCreateDialog }) {
  return (
<TopToolbar>
    {permissions.write && (
      <Button label="Créer une réclamation" onClick={openCreateDialog}>
        <IconFileCopy />
      </Button>
    )}
</TopToolbar>
  );
}
ListActions.propTypes = ActionsTypes.List;

export function InquiryList(props) {
  const { permissions } = usePermissions();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  return (
    <>
      <List
        actions={(
          <ListActions
            permissions={permissions ? permissions.inquiriesImport : {}}
            openCreateDialog={() => setIsCreateDialogOpened(true)}
          />
        )}
        filters={<InquiryListFilters />}
        perPage={10}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <IdField source="id" sortable={false} />
          <DateField source="dateCreated" label="Date de création" showTime locales="fr-FR" />
          <TextField source="status" />
          <TextField source="idOrder" label="Numéro de commande" />
          <DateField source="dateOrder" label="Date de commande" />
          <FunctionField render={(record) => `${record.orderValue} €`} label="Valeur commande" />
          <FunctionField render={(record) => `${record.cashbackUserValue} €`} label="Commission utilisateur" />
          <StatusIcon label="Envoyée à Strackr" source="uploaded" />
          <TextField source="type" />
          <TextField source="error" />
        </Datagrid>
      </List>
      <CreateInquiryDialog
        isDialogOpened={isCreateDialogOpened}
        onDialogClosed={() => setIsCreateDialogOpened(false)}
      />
    </>
  );
}

export { InquiryListIcon };
