import React, { useState, useEffect } from 'react';
import {
  SimpleForm, TextInput, NumberInput, Button, DateInput,
} from 'react-admin';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import moment from 'moment';

import { PropTypes } from '../../../tools/types';

import requester from '../../../tools/requester';
import config from '../../../config';
import ShopSelectInput from '../Common/ShopsSelectInput';

function MEAExtensionCreateEditDialog({ isDisplayDialog, closeDialog, initialValues }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fixedCosts, setFixedCosts] = useState();
  const [comment, setComment] = useState();
  const [shopFrom, setShopFrom] = useState();
  const [shopTo, setShopTo] = useState();
  const [dialogSaveButtonDisabled, setDialogSaveButtonDisabled] = useState(true);

  useEffect(() => {
    setStartDate(initialValues?.startDate);
    setEndDate(initialValues?.startDate);
    setFixedCosts(initialValues?.fixedCosts);
    setComment(initialValues?.comment);

    if (initialValues) {
      setShopFrom({ ...initialValues.shopFrom });
      setShopTo({ ...initialValues.shopTo });
    } else {
      setShopFrom(undefined);
      setShopTo(undefined);
    }
  }, [initialValues]);

  const validateValues = () => {
    const errors = {};

    const missingProperty = !shopFrom || !shopTo || !startDate || !endDate;

    const date1 = moment(startDate);
    const date2 = moment(endDate);
    if (startDate && endDate && date1 > date2) {
      errors.endDate = 'La date de fin doit être supérieure à la date de début';
    }

    setDialogSaveButtonDisabled(Object.keys(errors).length > 0 || missingProperty);
    return errors;
  };

  const handleRemoveClick = async () => {
    await requester(`${config.services.backend.endpoint}/mea-extension/${initialValues.id}`, { method: 'delete' });
    closeDialog();
  };

  const handleChangeShopFrom = (shopId) => {
    setShopFrom(shopId);
    validateValues();
  };

  const handleChangeShopTo = (shopId) => {
    setShopTo(shopId);
    validateValues();
  };

  const handleSaveClick = async () => {
    const data = {
      startDate,
      endDate,
      fixedCosts,
      comment,
      idShopFrom: shopFrom.id,
      idShopTo: shopTo.id,
    };

    if (initialValues) {
      await requester(`${config.services.backend.endpoint}/mea-extension/${initialValues.id}`, { method: 'put', options: { data } });
    } else {
      await requester(`${config.services.backend.endpoint}/mea-extension`, { method: 'post', options: { data } });
    }
    closeDialog();
  };

  return (
    <Dialog
      fullWidth
      open={isDisplayDialog}
      onClose={closeDialog}
      aria-label="Create MEA Extension"
    >
      <DialogTitle>Créer une MEA Extension</DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={null}
          validate={validateValues}
        >
          <ShopSelectInput source="shopFrom" label="Pousser le trafic du site" setSelectedShop={handleChangeShopFrom} initialShop={initialValues?.shopFrom} />
          <ShopSelectInput source="shopTo" label="vers le site" setSelectedShop={handleChangeShopTo} initialShop={initialValues?.shopTo} />

          <DateInput
            source="startDate"
            onChange={(event) => setStartDate(event.target.value)}
            label="Du"
            fullWidth
            initialValue={initialValues?.startDate}
          />
          <DateInput
            source="endDate"
            onChange={(event) => setEndDate(event.target.value)}
            label="Au"
            fullWidth
            initialValue={initialValues?.endDate}
          />
          <NumberInput
            onChange={(event) => setFixedCosts(parseFloat(event.target.value || 0))}
            source="fixedCosts"
            label="Frais fixes"
            fullWidth
            initialValue={initialValues?.fixedCosts}
          />
          <TextInput
            onChange={(event) => setComment(event.target.value)}
            source="comment"
            label="Commentaire"
            fullWidth
            initialValue={initialValues?.comment}
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        {initialValues !== undefined
          // && deletePermission
          && (
            <Button label="Remove" color="default" onClick={handleRemoveClick} style={{ color: 'red', marginRight: 'auto' }}>
              <ActionDelete />
            </Button>
          )}

        <Button
          label={initialValues !== undefined ? 'Update' : 'Save'}
          color="primary"
          disabled={dialogSaveButtonDisabled}
          onClick={handleSaveClick}
        />
        <Button label="ra.action.cancel" color="secondary" onClick={closeDialog}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const shopType = {
  domain: PropTypes.string,
  url: PropTypes.string,
  country: PropTypes.string,
};

MEAExtensionCreateEditDialog.propTypes = {
  isDisplayDialog: PropTypes.boolean,
  closeDialog: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    fixedCosts: PropTypes.number,
    comment: PropTypes.string,
    shopTo: PropTypes.shape(shopType),
    shopFrom: PropTypes.shape(shopType),
  }),
};

export default MEAExtensionCreateEditDialog;
