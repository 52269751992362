import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  DateInput,
  BooleanInput,
  required,
} from 'react-admin';

import config from '../../config';

function ClaimCreate(props) {
  return (
<Create title="Créer une réclamation" {...props}>
    <SimpleForm variant="standard" redirect="show">
      <TextInput disabled source="idTransaction" label="Id de la transaction originale" validate={required()} />
      <TextInput disabled source="idUser" label="Id de l'utilisateur" validate={required()} />
      <TextInput disabled source="shop.domain" label="Shop" validate={required()} />
      <SelectInput
        label="Type de réclamation"
        validate={required()}
        source="type"
        defaultValue="WAIT_NOT_VALID"
        choices={config.claims.type}
      />
      <NumberInput source="amount" label="Comm user (à payer)" />
      <TextInput multiline source="content" label="Commentaires - Détails de la réclamation" />
      <TextInput source="claimEmail" label="Email de contact" validate={required()} />
      <TextInput source="orderEmail" label="Email de la commande" />
      <TextInput source="referenceOrderNumber" label="Numéro de commande" />
      <DateInput source="orderDate" validate={required()} />
      <BooleanInput defaultValue={false} source="attachmentsProvided" label="Facture fournie" />
      {/* <BooleanInput defaultValue={false} source="validate" label="Valider la réclamation" /> */}
    </SimpleForm>
</Create>
  );
}

export default ClaimCreate;
