const MeaSpaces = [
  { id: 'PAGE_THEMATIQUE', name: 'Page Thématique' },
  { id: 'NEWSLETTER', name: 'Newsletter' },
  { id: 'SEARCH_BAR', name: 'Search Bar' },
];

const meaSpacesMap = {};
MeaSpaces.forEach((space) => {
  meaSpacesMap[space.id] = space.name;
});

export function getMeaSpaceNameFromId(id) {
  return meaSpacesMap[id] || 'Espace inconnu';
}

export function getMeaSpaces() {
  return structuredClone(MeaSpaces);
}
