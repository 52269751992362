import requester from '../../tools/requester';
import config from '../../config';

export function computeSubTotal(transaction, refTotalSelected, refNbSelected) {
  const parsedValue = parseFloat(transaction.value);
  const oldSubTotal = parseFloat(refTotalSelected);
  const subTotal = transaction.selected ? oldSubTotal + parsedValue : oldSubTotal - parsedValue;
  return {
    totalSelected: subTotal.toFixed(2),
    nbSelected: transaction.selected ? refNbSelected + 1 : refNbSelected - 1,
  };
}

export async function getTransactionsData(networkId, shopId, month, currency, selectedDateType, dateStart, dateEnd) {
  const request = await requester(`${config.services.backend.endpoint}/network-shop-transactions`, {
    options: {
      params: {
        networkId,
        currency,
        dateType: selectedDateType,
        dateStart,
        dateEnd,
        fromCache: 0,
        filters: {
          yearMonth: month,
          networkId,
          shopId,
        },
      },
    },
  });
  return request.data;
}
