import React, { useState, useEffect } from 'react';
import {
  SimpleForm, TextInput, NumberInput, Button, DateInput, SelectInput,
} from 'react-admin';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import moment from 'moment';

import { PropTypes } from '../../../tools/types';

import requester from '../../../tools/requester';
import config from '../../../config';
import ShopSelectInput from '../Common/ShopsSelectInput';
import { getMeaSpaces } from './meaSpace';

function MEAFraisFixeOnlyCreateEditDialog({ isDisplayDialog, closeDialog, initialValues }) {
  const [meaSpace, setMeaSpace] = useState('PAGE_THEMATIQUE');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [fixedCosts, setFixedCosts] = useState(0);
  const [comment, setComment] = useState();
  const [shop, setShop] = useState();
  const [dialogSaveButtonDisabled, setDialogSaveButtonDisabled] = useState(true);

  useEffect(() => {
    setStartDate(initialValues?.startDate);
    setEndDate(initialValues?.startDate);
    setFixedCosts(initialValues?.fixedCosts || 0);
    setComment(initialValues?.comment);
    setMeaSpace(initialValues?.meaSpace || 'PAGE_THEMATIQUE');

    if (initialValues) {
      setShop({ ...initialValues.shop });
    } else {
      setShop(undefined);
    }
  }, [initialValues]);

  const validateValues = () => {
    const errors = {};
    const missingProperty = !shop || !startDate || !endDate || !meaSpace;

    const date1 = moment(startDate);
    const date2 = moment(endDate);
    if (startDate && endDate && date1 > date2) {
      errors.endDate = 'La date de fin doit être supérieure à la date de début';
    }

    setDialogSaveButtonDisabled(Object.keys(errors).length > 0 || missingProperty);
    return errors;
  };

  const handleRemoveClick = async () => {
    await requester(`${config.services.backend.endpoint}/mea-fraisfixeonly/${initialValues.id}`, { method: 'delete' });
    closeDialog();
  };

  const handleChangeShop = (shopId) => {
    setShop(shopId);
    validateValues();
  };

  const handleChangeMeaSpace = (event) => {
    setMeaSpace(event.target.value);
    validateValues();
  };

  const handleSaveClick = async () => {
    const data = {
      startDate,
      endDate,
      fixedCosts,
      comment,
      meaSpace,
      idShop: shop.id,
    };

    if (initialValues) {
      await requester(`${config.services.backend.endpoint}/mea-fraisfixeonly/${initialValues.id}`, { method: 'put', options: { data } });
    } else {
      await requester(`${config.services.backend.endpoint}/mea-fraisfixeonly`, { method: 'post', options: { data } });
    }
    closeDialog(true);
  };

  return (
    <Dialog
      fullWidth
      open={isDisplayDialog}
      onClose={closeDialog}
      aria-label="Create MEA FraisFixeOnly"
    >
      <DialogTitle>{initialValues ? 'Modifier une MEA Frais Fixe Only' : 'Créer une MEA Frais Fixe Only'}</DialogTitle>
      <DialogContent>
        <SimpleForm
          toolbar={null}
          validate={validateValues}
        >
          <SelectInput
            source="meaSpace"
            choices={getMeaSpaces()}
            initialValue={initialValues?.meaSpace || 'PAGE_THEMATIQUE'}
            alwaysOn
            allowEmpty={false}
            onChange={handleChangeMeaSpace}
          />

          <ShopSelectInput
            source="shop"
            label="Marchand"
            setSelectedShop={handleChangeShop}
            initialShop={initialValues?.shop}
          />

          <DateInput
            source="startDate"
            onChange={(event) => setStartDate(event.target.value)}
            label="Du"
            fullWidth
            initialValue={initialValues?.startDate}
          />
          <DateInput
            source="endDate"
            onChange={(event) => setEndDate(event.target.value)}
            label="Au"
            fullWidth
            initialValue={initialValues?.endDate}
          />
          <NumberInput
            onChange={(event) => setFixedCosts(parseFloat(event.target.value || 0))}
            source="fixedCosts"
            label="Frais fixes"
            fullWidth
            initialValue={initialValues?.fixedCosts || 0}
          />
          <TextInput
            onChange={(event) => setComment(event.target.value)}
            source="comment"
            label="Commentaire"
            fullWidth
            initialValue={initialValues?.comment}
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        {initialValues !== undefined
          // && deletePermission
          && (
            <Button label="Remove" color="default" onClick={handleRemoveClick} style={{ color: 'red', marginRight: 'auto' }}>
              <ActionDelete />
            </Button>
          )}

        <Button
          label={initialValues !== undefined ? 'Update' : 'Save'}
          color="primary"
          disabled={dialogSaveButtonDisabled}
          onClick={handleSaveClick}
        />
        <Button label="ra.action.cancel" color="secondary" onClick={closeDialog}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const shopType = {
  domain: PropTypes.string,
  url: PropTypes.string,
  country: PropTypes.string,
};

MEAFraisFixeOnlyCreateEditDialog.propTypes = {
  isDisplayDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    fixedCosts: PropTypes.number,
    meaSpace: PropTypes.string,
    comment: PropTypes.string,
    shop: PropTypes.shape(shopType),
  }),
};

export default MEAFraisFixeOnlyCreateEditDialog;
