import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormControl, InputLabel, MenuItem, Select, TextField, Button, Box, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { useNotify } from 'react-admin';
import { PropTypes } from '../../tools/types';
import requester from '../../tools/requester';
import config from '../../config';
import { convertFileToBase64 } from '../../helpers';
import { transformEbay } from './Ebay';
import { transformAffilae } from './Affilae';

const AFFILAE_ID = '614a5441-0fae-4c17-b764-95700d988bfd';
const EBAY_ID = '1939c843-1c01-470c-92dd-06f837390e28';

const resolveFile = async ({ networkId, billFile, secondaryFile }) => {
  if (networkId === AFFILAE_ID) {
    return transformAffilae({ billFile, conversionsFile: secondaryFile });
  }

  if (networkId === EBAY_ID) {
    return transformEbay({ billFile, transactionsFile: secondaryFile });
  }

  return billFile[0];
};

function RemoveFile({ removeFile, fileName }) {
  return (
<Box display="flex" py={1} alignItems="center">
      <span>{fileName}</span>
      <IconButton type="button" onClick={removeFile}>
        <DeleteIcon />
      </IconButton>
</Box>
  );
}

RemoveFile.propTypes = {
  removeFile: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
};

function ImportBillForm({
  closeParentModal,
}) {
  const [networks, setNetworks] = useState([]);
  const [billFileName, setBillFileName] = useState();
  const [complementaryFileName, setComplementaryFileName] = useState();
  const [networkId, setNetworkId] = useState('');
  const showComplementaryFile = networkId === AFFILAE_ID || networkId === EBAY_ID;
  const notify = useNotify();

  const {
    handleSubmit, register, setValue, formState,
  } = useForm({
    mode: 'all',
    reValidateMode: 'all',
  });

  useEffect(async () => {
    setNetworks((await requester(`${config.services.backend.endpoint}/networks/billImport`, {
      timeout: 29000,
    })).data);
  }, []);

  const onImport = async ({
    billFile, secondaryFile, ...data
  }) => {
    const params = [];

    Object.entries(data).forEach(([key, value]) => {
      params.push(`${key}=${value}`);
    });

    closeParentModal();
    notify('Import démarré', 'success');

    const file = await resolveFile({ networkId, billFile, secondaryFile });

    try {
      await requester(`${config.services.backend.endpoint}/bills/import?${params.join('&')}`, {
        method: 'POST',
        options: {
          data: {
            billData: await convertFileToBase64(file),
          },
        },
      });
    } catch (e) {
      notify(`Erreur lors de l'import: ${e.response.data}`, 'error');
      return;
    }

    notify('Import terminé, rafraichir la liste pour voir la facture', 'success');
  };

  const handleNetworkChange = (e) => {
    const selectedValue = e.target.value;
    setNetworkId(selectedValue);
  };

  const onBillFileUploaded = ({ target }) => {
    if (target?.files?.[0]) {
      setBillFileName(target?.files?.[0]?.name);
    }
  };

  const removeBillFile = () => {
    setValue('billFile', '');
    setBillFileName(null);
  };

  const onSecondaryFileUploaded = ({ target }) => {
    if (target?.files?.[0]) {
      setComplementaryFileName(target?.files?.[0]?.name);
    }
  };

  const removeSecondaryFile = () => {
    setValue('secondaryFile', '');
    setComplementaryFileName(null);
  };

  const getError = (field) => {
    const { errors } = formState;

    if (errors[field]) {
      return {
        error: true,
      };
    }

    return null;
  };

  const getComplementaryFileButtonLabel = () => {
    if (networkId === AFFILAE_ID) {
      return 'Import des conversions';
    }

    if (networkId === EBAY_ID) {
      return 'Import des transactions';
    }

    return '';
  };

  return (
    <form onSubmit={handleSubmit(onImport)}>
      <Box py={1}>
        <FormControl fullWidth {...getError('networkId')}>
          <InputLabel>Plateforme</InputLabel>
          <Select {...register('networkId', { required: true })} onChange={handleNetworkChange}>
            {networks.map((network) => (
              <MenuItem key={network.id} value={network.id}>{network.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box py={1}>
        <TextField
          label="Référence de la facture"
          fullWidth
          {...register('reference', { required: true })}
          {...getError('reference')}
        />
      </Box>
      <Box py={1}>
        <TextField
          label="Date de facturation"
          type="date"
          defaultValue={moment().format('YYYY-MM-DD')}
          fullWidth
          {...register('billDate', { required: true })}
          {...getError('billDate')}
        />
      </Box>
      <Box py={1}>
        <TextField
          label="Montant total de la facture"
          fullWidth
          {...register('valuePlatform', {
            required: true,
            validate: (value) => !Number.isNaN(parseFloat(value)),
          })}
          {...getError('valuePlatform')}
        />
      </Box>
      <Box py={1}>
        <FormControl fullWidth {...getError('currency')}>
          <InputLabel>Devise</InputLabel>
          <Select defaultValue="EUR" {...register('currency', { required: true })}>
            {config.currencies.map((currency) => (
              <MenuItem key={currency.id} value={currency.id}>{currency.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box py={1} display="flex" flexDirection="column">
        <FormControl error={getError('billFile')}>
          <label htmlFor="billFile">
            <input
              type="file"
              id="billFile"
              style={{ display: 'none' }}
              {...register('billFile', {
                required: true,
                onChange: onBillFileUploaded,
              })}
            />
            <Button
              component="span"
              disabled={billFileName}
              color="primary"
              variant="contained"
            >
              Importer la facture
            </Button>
          </label>
        </FormControl>
        {billFileName && <RemoveFile removeFile={removeBillFile} fileName={billFileName} />}
      </Box>

      {showComplementaryFile && (
        <Box py={1} display="flex" flexDirection="column">
          <FormControl error={getError('billFile')}>
            <label htmlFor="secondaryFile">
              <input
                type="file"
                id="secondaryFile"
                style={{ display: 'none' }}
                {...register('secondaryFile', {
                  required: true,
                  onChange: onSecondaryFileUploaded,
                })}
              />
              <Button
                component="span"
                disabled={complementaryFileName}
                color="primary"
                variant="contained"
              >
                {getComplementaryFileButtonLabel()}
              </Button>
            </label>
          </FormControl>
          {complementaryFileName && <RemoveFile removeFile={removeSecondaryFile} fileName={complementaryFileName} />}
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" py={1}>
        <Button color="secondary" type="button" onClick={closeParentModal}>Annuler</Button>
        <Button color="secondary" type="submit" disabled={!formState.isValid}>Importer</Button>
      </Box>
    </form>
  );
}

ImportBillForm.propTypes = {
  closeParentModal: PropTypes.func.isRequired,
};

export default ImportBillForm;
