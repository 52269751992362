import { createContext, useContext } from 'react';
import requester from '../../../tools/requester';
import config from '../../../config';

const backendUrl = config.services.backend.endpoint;
const exclusiveBannersUrl = `${backendUrl}/mea-exclusive-banners`;

export const BannerContext = createContext();
export const useBannerContext = () => useContext(BannerContext);

export const bannerContextActions = {
  fetchData: async (monthYear, countryId) => {
    const result = await requester(`${exclusiveBannersUrl}?date=${monthYear}&country=${countryId}`, {});

    return result.data;
  },
  fetchShops: async ({ countryIso2a, input = '' } = {}) => {
    const baseEndpoint = `${backendUrl}/shops?_end=10&_start=0&country=${countryIso2a}`;
    let searchParam = '';
    if (input.length > 2) {
      searchParam = `&q=${input}`;
    }

    return requester(
      `${baseEndpoint}${searchParam}`,
      { method: 'GET' },
    );
  },
  createBanner: async (bannerData) => requester(exclusiveBannersUrl, {
    method: 'POST',
    options: {
      data: bannerData,
    },
  }),
  updateBanner: async (bannerData) => requester(`${exclusiveBannersUrl}/${bannerData.id}`, {
    method: 'PUT',
    options: {
      data: bannerData,
    },
  }),
  deleteBanner: async (bannerId) => requester(`${exclusiveBannersUrl}/${bannerId}`, {
    method: 'DELETE',
  }),
};
