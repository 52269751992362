import React from 'react';
import {
  Edit,
  TextInput,
  DateInput,
  BooleanInput,
  required,
  SimpleForm,
  Toolbar,
  SaveButton,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { ActionsTypes } from '../../tools/types';

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton />
</Toolbar>
  );
}

function InquiryImportDomainActions({ basePath }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
</TopToolbar>
  );
}

InquiryImportDomainActions.propTypes = ActionsTypes.Edit;

function InquiryImportDomainEdit(props) {
  const transform = (data) => ({
    ...data,
    validityDate: new Date(data.validityDate).toISOString(),
    comment: data.comment ?? '',
    id: undefined,
  });

  return (
    <Edit undoable={false} transform={transform} actions={<InquiryImportDomainActions />} {...props}>
      <SimpleForm variant="standard" label="Résumé" toolbar={<CustomToolbar />}>
        <TextInput source="domain" label="Domaine" validate={required()} />
        <BooleanInput source="isEnabled" label="Activé" validate={required()} />
        <DateInput
          validate={required()}
          source="validityDate"
          label="Date de validité"
        />
        <TextInput multiline source="comment" label="Commentaire" />
      </SimpleForm>
    </Edit>
  );
}
export default InquiryImportDomainEdit;
