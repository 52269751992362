import PropTypes from 'prop-types';
import { Component } from 'react';

import authRoutes from './routes';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (nextProps.logged !== prevState.connected) {
      nextState.connected = nextProps.logged;
    }
    return nextState;
  }

  render() {
    return authRoutes;
  }
}

App.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  logged: PropTypes.bool.isRequired,
};

export default App;
