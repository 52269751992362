import React from 'react';
import {
  Edit,
  TopToolbar,
  ShowButton,
  ListButton,
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
  DateInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  NumberInput,
  required,
  usePermissions,
} from 'react-admin';

import { ActionsTypes, PropTypes } from '../../tools/types';
import { LayoutTitle } from '../Shared/Layout';
import config from '../../config';

const transformatedStatus = (id) => {
  const conf = config.claims.status;
  if (!id || !conf) { return; }
  // eslint-disable-next-line consistent-return
  return (conf.find((p) => id === p.id)).name;
};

function UserAmount({ record }) {
  return record.status === 'RESOLVED'
    ? <TextInput disabled source="amount" locales="fr-FR" label="Comm user (à payer)" />
    : <NumberInput source="amount" locales="fr-FR" label="Comm user (à payer)" />;
}

function ValidateClaim({ record }) {
  return <BooleanInput source="validate" label="Réclamation validée" options={{ disabled: record.status === 'RESOLVED' }} />;
}

function ClaimEdit(props) {
  const { permissions } = usePermissions();

  return (
  <Edit undoable={false} actions={<ClaimActions permissions={permissions ? permissions.claims : {}} />} title={<LayoutTitle source="name" />} {...props}>
    <TabbedForm toolbar={<CustomToolbar permissions={permissions ? permissions.claims : {}} />} redirect="show">
      <FormTab variant="standard" label="Résumé">
        <TextInput disabled source="id" label="id de la réclamation" />
        <TextInput disabled source="ticketNumber" label="Numéro de réclamation" />
        <TextInput disabled source="shop.id" label="Id du shop" />
        <TextInput disabled source="shop.domain" label="Domaine" />
        <TextInput disabled source="status" format={transformatedStatus} label="Statut de la réclamation" />
        <SelectInput
          label="Type de la réclamation"
          source="type"
          choices={config.claims.type}
        />
        <TextInput disabled source="user.email" label="Email du compte de l'utilisateur" />

        <TextInput validate={required()} source="claimEmail" label="Email de contact" />
        <TextInput source="orderEmail" label="Email utilisée lors de la commande" />
        <DateInput validate={required()} source="orderDate" label="Date de la commande" />

        <UserAmount />

        <TextInput multiline source="content" label="Commentaires - Détails de la réclamation" />
        <BooleanInput source="attachmentsProvided" label="Facture fournie" />

        <ValidateClaim />
      </FormTab>
    </TabbedForm>
  </Edit>
  );
}

function ClaimActions({
  basePath,
  data,
  permissions,
}) {
  return (
<TopToolbar>
      <ListButton basePath={basePath} />
      {permissions.write && <ShowButton basePath={basePath} record={data} />}
</TopToolbar>
  );
}

function CustomToolbar(props) {
  return (
<Toolbar {...props}>
    <SaveButton {...props} />
</Toolbar>
  );
}

ClaimActions.propTypes = {
  ...ActionsTypes.Edit,
  permissions: PropTypes.object,
};

UserAmount.propTypes = {
  record: PropTypes.object,
};

ValidateClaim.propTypes = {
  record: PropTypes.object,
};

export default ClaimEdit;
