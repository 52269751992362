import React from 'react';
import ReactDOM from 'react-dom';

import registerServiceWorker from './registerServiceWorker';

import './index.css';
import App from './App';
import TokenManager from './tools/tokenManager';

// *
// Init front
async function init() {
  const connected = await TokenManager.checkAccessToken();

  // Render
  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <App logged={connected} />,
    document.getElementById('root'),
  );
}

init();

registerServiceWorker();
