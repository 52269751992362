import React, { useState, useEffect } from 'react';
import {
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import requester from '../../../tools/requester';
import config from '../../../config';
import { PropTypes } from '../../../tools/types';

function ShopSelectInput({
  label,
  setSelectedShop,
  initialShop,
  source,
}) {
  const [shops, setShops] = useState([]);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    const result = await requester(`${config.services.backend.endpoint}/shops?_end=10&_start=0`, { method: 'GET' });
    setShops(result.data);
    setIsLoading(false);
  };

  useEffect(loadData, []);

  const searchShops = async (event, newInputValue) => {
    if ((event?.type === 'change' && newInputValue?.length > 2)
      || (event?.type === 'click' && newInputValue === '')) {
      setIsLoading(true);
      const result = await requester(`${config.services.backend.endpoint}/shops?_end=10&_start=0&q=${newInputValue}`, { method: 'GET' });
      setShops(result.data);
      setIsLoading(false);
    }
  };

  const optionRenderer = (a) => a && `${a.domain.replace('*.', '')} (${a.url}) - ${a.country.iso2a}`;

  return (
    <Autocomplete
      source={source}
      id="autocomplete-shop"
      open={openAutocomplete}
      defaultValue={initialShop}
      style={{ marginBottom: '20px' }}
      onOpen={() => { setOpenAutocomplete(true); }}
      onClose={() => { setOpenAutocomplete(false); }}
      getOptionLabel={optionRenderer}
      getOptionSelected={(option, value) => option.shop?.id === value.shop?.id}
      options={shops}
      loading={isLoading}
      onInputChange={searchShops}
      onChange={(event, selectedShop) => { setSelectedShop(selectedShop); }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

ShopSelectInput.propTypes = {
  label: PropTypes.string,
  setSelectedShop: PropTypes.func,
  initialShop: PropTypes.shape({
    domain: PropTypes.string,
    url: PropTypes.string,
    country: PropTypes.string,
  }),
  source: PropTypes.string,
};

export default ShopSelectInput;
