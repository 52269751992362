import React from 'react';
import get from 'lodash/get';

import {
  Show, TopToolbar, TabbedShowLayout, ListButton, Link, Tab, DateField, EmailField, FunctionField, ReferenceManyField, Datagrid, Pagination, TextField,
} from 'react-admin';

import Chip from '@material-ui/core/Chip';
import Error from '@material-ui/icons/ErrorOutline';
import Checked from '@material-ui/icons/CheckBoxOutlined';
import UnChecked from '@material-ui/icons/CheckBoxOutlineBlank';

// Custom
import { SectionTitle } from '../Shared/Layout';

import './Transaction.css';
import { OtherTypes, ActionsTypes, PropTypes } from '../../tools/types';

function HistoriesPagination(props) {
  return <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} />;
}

/**
 * TransactionShow
 */
function TransactionShow(props) {
  return (
<Show actions={<TransactionsAction />} title={<TransactionTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Résumé">
        <ShopLink label="Shop" />
        <TextField source="shop.country.iso2a" label="Pays" />
        <DateField source="history.created" label="Date du dernier statut" showTime locales="fr-FR" />

        <FunctionField render={(record) => `${record.history.orderValue} ${record.currency}`} label="Valeur comande" />
        <FunctionField render={(record) => `${record.history.value} ${record.currency}`} label="Commission" />
        <FunctionField render={(record) => `${record.history.userValue} ${record.currency}`} label="Commission utilisateur" />

        <TextField source="subId" label="SubId de la transaction (valeur {HASHID})" />
        <UserLink label="Utilisateur" />

        <PaymentLink label="Information virement user" />

        <SectionTitle label="Status" />

        <FunctionField
          render={
            (record) => {
              if (!record.history.validationDate) return '—';

              return <DateField source="history.validationDate" record={record} addLabel={false} showTime locales="fr-FR" />;
            }
          }
          label="Date de validation"
        />

        <FunctionField
          render={
            (record) => {
              if (record.history.declineSource === null) return '—';

              return <DateField source="history.created" record={record} addLabel={false} showTime locales="fr-FR" />;
            }
          }
          label="Date de refus"
        />

        <DeclinedField label="Décliné par" />

        <StatusField label="Seen" source="history.seen" className="status-block" />
        <StatusField label="Approved" source="history.approved" className="status-block" />
        <StatusField label="PaidByNetwork" source="history.paidByNetwork" className="status-block" />
        <StatusField label="PaymentAsked" source="history.paymentAsked" className="status-block" />
        <StatusField label="PaidToUser" source="history.paidToUser" className="status-block" />
      </Tab>
      <Tab label="Historique" path="histories">
        <ReferenceManyField
          reference="transactions"
          target="idTransaction"
          pagination={<HistoriesPagination />}
          addLabel={false}
        >
          <Datagrid>
            <DateField
              sortable={false}
              label="Date de modification"
              source="historyCreation"
              showTime
              locales="fr-FR"
            />
            <StatusField
              sortable={false}
              label="Approuvé"
              source="transaction.approved"
              className="status-block"
            />
            <StatusField
              sortable={false}
              label="Payé par la plateforme"
              source="transaction.paidByNetwork"
              className="status-block"
            />
            <StatusField
              sortable={false}
              label="Demande de paiment"
              source="transaction.paymentAsked"
              className="status-block"
            />
            <StatusField
              sortable={false}
              label="Payé à l'utilisateur"
              source="transaction.paidToUser"
              className="status-block"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
</Show>
  );
}

TransactionShow.propTypes = ActionsTypes.Show;

function TransactionTitle({ record }) {
  return <span>{record.email}</span>;
}

TransactionTitle.propTypes = OtherTypes.TitleForRecord;

function TransactionsAction({ basePath }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
</TopToolbar>
  );
}

TransactionsAction.propTypes = ActionsTypes.Show;

function ShopLink({ record }) {
  return <Link to={`/shops/${record.shop.id}/show`}>{record.shop.domain}</Link>;
}

ShopLink.propTypes = {
  record: PropTypes.object,
};

ShopLink.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

function UserLink({ record }) {
  return (record.user) ? (
  <div>
    <p>
      <Link to={`/users/${record.user.id}/show`}>
        {record.user.id}
        {' '}
-
        {' '}
        {record.user.firstname || ''}
        {' '}
        {record.user.lastname || ''}
      </Link>
    </p>
    <small><EmailField source="email" record={record.user} /></small>
  </div>
  ) : '';
}

UserLink.propTypes = {
  record: PropTypes.object,
};

UserLink.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

function StatusField({ record, source }) {
  const value = get(record, source);
  return value ? <Checked /> : <UnChecked />;
}

StatusField.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

StatusField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

function DeclinedField({ record }) {
  const { history } = record;
  if (!history.declineSource) {
    return <span />;
  }
  return <Chip avatar={<Error />} label={`Décliné par ${history.declineSource}`} />;
}

DeclinedField.propTypes = {
  record: PropTypes.object,
};

function PaymentLink({ record }) {
  return (
<div>
    {!record.bill && 'N.A'}
    <div>{record.bill && <Link to={`/bills/${record.bill.id}/show`} title={record.bill.reference || ''}>{`Facture id: ${record.bill.id}`}</Link>}</div>
    <div>{record.payment && `Paiement id: ${record.payment.id}`}</div>
    <div>{record.payment && `Date du paiement: ${new Date(record.payment.created).toLocaleString('fr-FR')}`}</div>
</div>
  );
}

PaymentLink.propTypes = {
  record: PropTypes.object,
};

PaymentLink.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

export default TransactionShow;
