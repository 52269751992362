import React from 'react';
import {
  Create, SimpleForm, TextInput, BooleanInput, DateInput, SelectInput, NumberInput, required,
} from 'react-admin';

import { getCookie } from '../../tools/cookies';
import config from '../../config';
import { PropTypes } from '../../tools/types';

const redirect = (basePath, id, data) => `/shops/${data.shopId}/show/vouchers`;

function VoucherCreate(props) {
  const { location } = props;
  const shopId = (new URLSearchParams(location.search)).get('shopId');

  const choices = config.vouchers.type;
  const initialValues = {
    shopId,
    idUser: getCookie('uid'),
    descFirstBuy: false,
    memberOnly: false,
    exclusive: true,
    isRecommended: false,
    type: choices[0].id,
  };

  return (
    <Create {...props}>
      <SimpleForm variant="standard" initialValues={initialValues} redirect={redirect}>
        <TextInput source="code" label="Code" validate={required()} />
        <TextInput source="title" label="Titre" />
        <TextInput multiline source="description" label="Conditions" />
        <TextInput multiline source="descScope" label="Scope du coupon" />
        <TextInput multiline source="descExclusions" label="Liste d'exclusion" />
        <BooleanInput source="descFirstBuy" label="Afficher la description pour le premier achat uniquement" fullWidth />
        <BooleanInput source="memberOnly" label="Réservé aux membres" />
        <DateInput source="validFrom" label="Valide à partir du" />
        <DateInput source="validTo" label="Valide jusqu'au" />
        <TextInput disabled source="shopId" label="Boutique associée" />
        <SelectInput source="type" label="Type de coupon" choices={choices} />
        <BooleanInput source="exclusive" label="Le coupon est exclusif" />
        <BooleanInput source="isRecommended" label="Le coupon est recommandé" fullWidth />
        <NumberInput source="amount" label="Montant du coupon" validate={required()} />
        <SelectInput source="currency" label="Devise" validate={required()} choices={config.currencies} />
        <NumberInput source="minimum" label="Minimum d'achat" />
        <TextInput source="name" label="Nom du site source" />
      </SimpleForm>
    </Create>
  );
}

VoucherCreate.propTypes = {
  location: PropTypes.object.isRequired,
};

export default VoucherCreate;
