import React, { useState } from 'react';
import InquiriesImportIcon from '@material-ui/icons/Update';
import IconFileCopy from '@material-ui/icons/FileCopy';

import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import {
  IdField,
} from '../Shared/Layout';
import { ActionsTypes } from '../../tools/types';
import CreateInquiryImportDialog from './CreateInquiryImportDialog';

function ListActions({ permissions, openCreateDialog }) {
  return (
<TopToolbar>
    {permissions.write && (
      <Button label="Créer un job d'import" onClick={openCreateDialog}>
        <IconFileCopy />
      </Button>
    )}
</TopToolbar>
  );
}
ListActions.propTypes = ActionsTypes.List;

const jobResultRow = (record) => {
  if (record.totalInquiries === 0) return <span>Aucune réclamations</span>;
  if (record.totalImported + record.totalErrors !== record.totalInquiries) return <span>Import en cours</span>;

  if (record.totalErrors === 0) {
    return (
      <span>
        {record.totalInquiries}
        &nbsp;réclamations
      </span>
    );
  }

  return (
    <span>
      {record.totalInquiries}
      &nbsp;réclamations dont
      &nbsp;
      {record.totalErrors}
      &nbsp; en erreur
    </span>
  );
};

function InquiriesImportView(props) {
  const { permissions } = usePermissions();
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  return (
    <>
      <List
        actions={(
          <ListActions
            permissions={permissions ? permissions.inquiriesImport : {}}
            openCreateDialog={() => setIsCreateDialogOpened(true)}
          />
        )}
        perPage={25}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <IdField source="id" sortable={false} />
          <DateField source="date" showTime locales="fr-FR" label="Crée le" sortable={false} />
          <DateField source="dateOrder" locales="fr-FR" label="Date des commandes" sortable={false} />
          <FunctionField label="Résultat de l'import BigQuery" render={jobResultRow} />
        </Datagrid>
      </List>
      <CreateInquiryImportDialog
        isDialogOpened={isCreateDialogOpened}
        onDialogClosed={() => setIsCreateDialogOpened(false)}
      />
    </>
  );
}

export { InquiriesImportView, InquiriesImportIcon };
