import React, { useEffect, useState } from 'react';

import {
  SelectInput,
  DateInput,
  SimpleForm,
  Button,
  HttpError,
} from 'react-admin';

import { parse } from 'json2csv';
import ExportViewIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import requester from '../../tools/requester';
import config from '../../config';

const exports = [
  { id: 'totalJackpotsPaid', name: 'Total des cagnottes versées' },
  { id: 'totalJackpotsExpired', name: 'Total des cagnottes périmées' },
  { id: 'totalJackpotsRemovedUsers', name: 'Total des cagnottes d\'utilisateurs supprimés' },
  { id: 'invoicesDetail', name: 'Détail des factures' },
  { id: 'conversionTime', name: 'Délai de conversion d’une transaction - Création/Facturation' },
  { id: 'igraalBenchmark', name: 'Benchmark iGraal' },
];

function ExportView() {
  const [selectedExport, changeSelectedExport] = useState(exports[0]);
  const [exportButtonDisabled, changeExportButtonStateDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [requireDates, setRequireDates] = useState(true);

  const onChangeSelectedExport = (event) => {
    const selectedExportTmp = exports.find((exp) => exp.id === event.target.value);
    changeSelectedExport(selectedExportTmp);
  };

  const onChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const onChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const validateValues = (values) => {
    const errors = {};

    if (!requireDates) {
      changeExportButtonStateDisabled(false);
      return {};
    }

    const {
      startDate: startDateValue,
      endDate: endDateValue,
    } = values;

    const date1 = moment(startDateValue);
    const date2 = moment(endDateValue);
    changeExportButtonStateDisabled(
      Object.keys(errors).length > 0
      || (!startDateValue || !endDateValue),
    );
    if (startDateValue && endDateValue && date1 > date2) {
      errors.endDate = 'La date de fin doit être supérieure à la date de début';
    }

    return errors;
  };

  const onCreateExport = () => {
    setIsLoading(true);
    requester(`${config.services.backend.endpoint}/export`, {
      options: {
        params: {
          startDate,
          endDate,
          exportType: selectedExport.id,
        },
      },
    }).then((response) => {
      const prefix = 'data:text/csv;charset=utf-8,';

      let content = [];

      if (response?.data?.length) {
        content = parse(response.data);
      }

      const a = document.createElement('a');
      a.setAttribute('href', `${prefix}${encodeURIComponent(content)}`);
      a.setAttribute('download', `export_${selectedExport.name.replaceAll(' ', '-').replaceAll('\'', '')}_${startDate}_${endDate}.csv`);

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }).catch((err) => {
      throw new HttpError(
        err?.response?.data,
        err?.response?.status,
      );
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setRequireDates(['igraalBenchmark'].includes(selectedExport.id) === false);
  }, [selectedExport]);

  return (
    <SimpleForm
      toolbar={null}
      validate={validateValues}
    >
      <SelectInput
        label="Type"
        source="selectedExport"
        initialValue={selectedExport?.id}
        choices={exports}
        onChange={onChangeSelectedExport}
      />
      {requireDates && (
        <div>
          <DateInput
            source="startDate"
            onChange={onChangeStartDate}
            label="Du"
            fullWidth
          />
          <DateInput
            source="endDate"
            onChange={onChangeEndDate}
            label="Au"
            fullWidth
          />
        </div>
      )}
      <Button
        disabled={exportButtonDisabled || isLoading}
        variant="contained"
        color="primary"
        size="small"
        onClick={onCreateExport}
        label="Export"
      />
    </SimpleForm>
  );
}

export { ExportView, ExportViewIcon };
