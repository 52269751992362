import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';

function NotFound() {
  return (
<Card>
    <Typography>Not found</Typography>
    <CardContent>
      <h1>404: Page not found</h1>
    </CardContent>
</Card>
  );
}

export default NotFound;
