import React from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  DateInput,
  TopToolbar,
  ListButton,
} from 'react-admin';

import { ActionsTypes } from '../../tools/types';

function InquiryImportDomainActions({ basePath }) {
  return (
<TopToolbar>
    <ListButton basePath={basePath} />
</TopToolbar>
  );
}

InquiryImportDomainActions.propTypes = ActionsTypes.Edit;

const redirect = () => '/inquiry-import-domains';

function InquiryImportDomainCreate(props) {
  const transform = (data) => ({
    ...data,
    validityDate: new Date(data.validityDate).toISOString(),
    comment: data.comment ?? '',
  });

  return (
  <Create label="Create" transform={transform} actions={<InquiryImportDomainActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="domain" label="Domaine" validate={required()} />
      <BooleanInput source="isEnabled" label="Activé" defaultValue={false} validate={required()} />
      <DateInput
        validate={required()}
        source="validityDate"
        label="Date de début de validité"
      />
      <TextInput multiline source="comment" label="Commentaire" defaultValue="" />
    </SimpleForm>
  </Create>
  );
}

export default InquiryImportDomainCreate;
