import React from 'react';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import { getCountries } from '../../tools/country';
import { PropTypes } from '../../tools/types';
import CustomFlag from '../Shared/Layout/CustomFlag';

/**
 * shopList
 */
function ShopCreate(props) {
  const validateForm = (values) => {
    const errors = {};

    if (values.space) {
      if (values.space.default && values.space.pattern) {
        errors.space = {
          pattern: ['L\'espace par défaut ne doit pas avoir de pattern'],
        };
      }

      if (!values.space.default && !values.space.pattern) {
        errors.space = {
          pattern: ['L\'espace doit obligatoirement avoir un pattern'],
        };
      }
    }

    return errors;
  };

  const transform = (data) => ({
    ...data,
    space: {
      ...data.space,
      pattern: data.space.default ? null : data.space.pattern,
    },
  });

  return (
    <Create label="Create" title={<PostTitle />} transform={transform} {...props}>
      <SimpleForm variant="standard" validate={validateForm}>
        <TextInput source="domain" validate={required()} />
        <SelectInput
          validate={required()}
          allowEmpty={false}
          label="Pays"
          source="country"
          defaultValue="FR"
          choices={getCountries()}
          translateChoice={false}
          optionText={(choice) => (
            <CustomFlag isoCode={choice.id} />
          )}
        />
        {/* eslint-disable-next-line react/jsx-boolean-value */}
        <BooleanInput source="space.default" label="Espace par défaut" defaultValue={true} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <TextInput
              source="space.pattern"
              disabled={formData.space.default}
              validate={(value, allValues) => !allValues.space.default && !value && 'L\'espace doit obligatoirement avoir un pattern'}
              format={(inputValue) => (formData.space.default ? null : inputValue)}
              label="Pattern"
              defaultValue={null}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <TextInput source="url" validate={required()} />
        <SelectInput
          validate={required()}
          source="voucherAccess"
          defaultValue="OFF"
          choices={[
            { id: 'ACTIVE', name: 'ACTIVE' },
            { id: 'RESTRICTIVE', name: 'RESTRICTIVE' },
            { id: 'OFF', name: 'OFF' },
          ]}
        />
        <SelectInput
          validate={required()}
          source="voucherBehavior"
          defaultValue="INLINE"
          choices={[
            { id: 'REFRESH', name: 'REFRESH' },
            { id: 'INLINE', name: 'INLINE' },
            { id: 'TAB', name: 'TAB' },
          ]}
        />
        {/* eslint-disable-next-line react/jsx-boolean-value */}
        <BooleanInput validate={required()} defaultValue={true} source="cashbackActivated" label="Cashback activé" />
        <BooleanInput source="multipleActivePrograms" label="2 programmes actifs cashback & voucher" />
        <TextInput source="cashbackActivatedComment" multiline label="Commentaire" />
      </SimpleForm>
    </Create>
  );
}

function PostTitle({ record }) {
  return record && <span>{record.domain}</span>;
}

PostTitle.propTypes = {
  record: PropTypes.object,
};

export default ShopCreate;
