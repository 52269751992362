import momentTZ from 'moment-timezone';

export const getAllDaysInInterval = (startDate, endDate) => {
  const start = momentTZ.tz(startDate, 'Europe/Paris');
  const end = momentTZ.tz(endDate, 'Europe/Paris');
  const dates = [];

  while (start.isSameOrBefore(end, 'day')) {
    dates.push(start.clone().format('YYYY-MM-DD'));
    start.add(1, 'days');
  }

  return dates;
};

export const bannerTypeIsAlreadyUsed = (reservedDaysByBannerType) => ({
  id, bannerType, startDate, endDate,
}) => {
  if (!reservedDaysByBannerType.has(bannerType)) {
    return false;
  }

  const reservedDays = reservedDaysByBannerType.get(bannerType);

  const startDateInParis = momentTZ.tz(startDate, 'Europe/Paris');
  const endDateInParis = momentTZ.tz(endDate, 'Europe/Paris');

  const daysOfInterval = getAllDaysInInterval(startDateInParis, endDateInParis).map((d) => momentTZ.tz(d, 'Europe/Paris').format('YYYY-MM-DD'));

  return reservedDays.some((reservedDay) => daysOfInterval.includes(reservedDay.day) && reservedDay.id !== id);
};
