import React from 'react';
import { PropTypes } from '../../tools/types';

function UserName({ record }) {
  return (
<span>
    {`${record.firstname || ''} ${record.lastname || ''}`}
</span>
  );
}

UserName.propTypes = {
  record: PropTypes.object,
};

UserName.defaultProps = {
  addLabel: true, // eslint-disable-line react/default-props-match-prop-types
};

export default UserName;
