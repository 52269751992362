import React from 'react';
import { isEmpty } from 'lodash';
import {
  List, TopToolbar, ExportButton, Datagrid, DateField, EmailField, ShowButton, Filter, TextInput,
} from 'react-admin';
import UserListIcon from '@material-ui/icons/Mood';

// Custom stuff
import { IdField } from '../Shared/Layout';
import SimplePagination from '../Shared/Pagination/SimplePagination';
import { ActionsTypes } from '../../tools/types';
import UserName from './Shared';

/**
 * UserList
 */
function UserList(props) {
  return (
<List
  actions={<UserListActions />}
  filters={<UserListFilters />}
  perPage={15}
  pagination={<SimplePagination />}
  {...props}
>
    <Datagrid>
      <IdField label="id" />
      <UserName label="name" />
      <EmailField source="email" />
      <DateField source="created" label="Créé le" showTime locales="fr-FR" sortable={false} />
      <ShowButton />
    </Datagrid>
</List>
  );
}

function UserListFilters({ setFilters, ...props }) {
  const filter = (query) => {
    // Set the filter if the query is empty or if there is at least 4 chars
    if (isEmpty(query) || query.q.length === 0 || query.q.length >= 4) {
      setFilters(query);
    }
  };
  return (
    <Filter variant="standard" setFilters={filter} {...props}>
      <TextInput label="Utilisateur" source="q" alwaysOn />
    </Filter>
  );
}

function UserListActions({
  resource, exporter, filterValues, currentSort,
}) {
  return (
<TopToolbar>
      <ExportButton
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
</TopToolbar>
  );
}

UserListActions.propTypes = ActionsTypes.UserList;
UserListFilters.propTypes = Filter.List;

export { UserList, UserListIcon };
